import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_CABLE_INTERNET_PROVIDERS',
  request: (id) => request.get(`/residents/cable-internet-tasks/${id}/cable-internet-providers`)
    .then(r => r.data.data.cable_internet_providers),
  selector: (state, id) => state?.cableInternetProviders?.[id],
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      cableInternetProviders: {
        ...state.cableInternetProviders,
        [id]: response,
      },
    }),
  },
};

export const {
  reducer,
  action: getProviders,
  useResource: useProviders,
  usePending: useProvidersPending,
} = generate.redux(creator);

export const initialState = {
  cableInternetProviders: {},
};
