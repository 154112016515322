import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CompleteCard, FieldList, LoaderOverlay } from '@moved/ui';
import { moveOutDetails } from '@moved/product';

import { Snippet } from '../../../../snippets';
import { Screen, CompleteScreenActions } from '../../shared';

import CSS from './styles/Complete.module.scss';

export const Complete = ({ screen, taskDetails, taskDefinition, moveId, origin, goToScreen, onClose }) => {
  const dispatch = useDispatch();
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);

  const options = taskDefinition.selectors.useMoveOutOptionsList(building.id);
  const optionsPending = taskDefinition.selectors.useMoveOutOptionsListPending();

  const attributes = [
    ...moveOutDetails.getSummaryFields(taskDetails, options),
    {
      value: (
        <div className={CSS.start_over}>
          <button
            type="button"
            className={'btn-gray btn--small'}
            onClick={() => goToScreen(taskDefinition.getStartOverScreen(taskDetails))}
          >
            Change selection
          </button>
        </div>
      )
    },
  ];

  const flavor = (<Snippet tag={'tasks.move-out-details.summary'} />);

  useEffect(() => {
    dispatch(taskDefinition.actions.getOptionsList(building.id));
  },[building, taskDefinition, dispatch]);

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        { optionsPending && (<LoaderOverlay />)}
        <CompleteCard status={taskDetails.status || 'approved'} flavor={flavor}>
          <FieldList list={attributes} />
        </CompleteCard>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  )

};
