import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_CHOICE_TASK',
  request: (id) => request.get(`/residents/choice-tasks/${id}`)
    .then(r => r.data.data.choice_task),
  selector: (state, id) => state?.choiceTasks?.[id],
  reducers: {
    success: (state, { response: choiceTask }) => ({
      ...state,
      choiceTasks: {
        ...state.choiceTasks,
        [choiceTask.id]: choiceTask,
      },
    }),
  }
};

export const {
  reducer,
  action: get,
  useResource: useChoiceTask,
  usePending: useChoiceTaskPending,
} = generate.redux(creator);

export const initialState = {
  choiceTasks: {},
};
