import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Card, Icon } from '@moved/ui';
import { useNotify, format, useModal } from '@moved/services';

import { Snippet } from '../../../../snippets';
import { Screen } from '../../shared';

// temporarily (or maybe permanently) removed -- 2024-09-09
// import { CompareMovingMethods } from './CompareMovingMethods';
import { UhaulSignupModal } from './UhaulSignupModal';

import diyImage from './images/uhaul-truck.jpg';
import professionalMoversImage from './images/professional-movers.jpg';

import CSS from './styles/MovingPlan.module.scss';

export const MovingPlan = ({ screen, nextScreen, taskDefinition }) => {
  const { id, stepId } = useParams();
  const notify = useNotify();
  const modal = useModal();
  const dispatch = useDispatch();
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep(stepId);
  const moverBookingTask = taskDefinition.selectors.useMoverBookingTask(id);
  const pending = taskDefinition.selectors.useUpdateMoverBookingTaskPending();
  const [selected, setSelected] = useState(moverBookingTask?.selected_moving_method);

  const options = [
    {
      name: 'Do it yourself',
      title: (
        <Snippet
          tag='tasks.book-movers.moving-plan.diy.title'
          description='Title for the DIY moving method option'
        >
          DIY: "Doing it yourself"
        </Snippet>
      ),
      description: (
        <Snippet
          tag='tasks.book-movers.moving-plan.diy.description'
          description='Description for the DIY moving method option'
        >
          Hire trucks & trailers, get boxes, specialty kits for apartment moves,
          even moving help/labor to lighten the load and speed things up.
        </Snippet>
      ),
      value: 'diy',
      image: diyImage,
    },
    {
      name: 'Professional movers',
      title: (
        <Snippet
          tag='tasks.book-movers.moving-plan.professional-movers.title'
          description='Title for the professional moving method option'
        >
          Hiring full service movers
        </Snippet>
      ),
      description: (
        <Snippet
          tag='tasks.book-movers.moving-plan.professional-movers.description'
          description='Description for the professional moving method option'
        >
          Expert movers handle your belongings with care, using efficient techniques for safe and timely delivery to your new home.
        </Snippet>
      ),
      value: 'professional-movers',
      image: professionalMoversImage,
    },
  ];

  const submit = () => {
    dispatch(taskDefinition.actions.update(id, { selected_moving_method: selected }))
      .then(r => selected === 'diy' ? dispatch(taskDefinition.actions.complete(id)) : r)
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  const openUhaulModal = () => {
    if(moverBookingTask?.uhaul_account_decision?.opt_in) return submit();
    modal.open(
      <UhaulSignupModal moveStep={activeMoveStep} />,
      { onClose: () => setTimeout(submit, 100) } // slight delay to allow modal to close
    );
  };


  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>

        {/* <CompareMovingMethods /> */}

        <div className='stackHorizontal gap-24 justify-center flex-wrap'>
          { options.map(option => (
            <Card
              className={classNames('stackVertical gap-16',CSS.card,{[CSS.selected]: selected === option.value})}
              onClick={() => setSelected(option.value)}
              key={option.value}
            >
              <img src={option.image} alt={option.name} className={CSS.image} />
              <div className='stackHorizontal gap-24 flex-auto paddingLeft-20 paddingRight-20'>
                <div className='stackVertical'>
                  <div className='headingS contentPrimary'>{option.title}</div>
                  <div className='labelS contentSecondary'>{option.description}</div>
                </div>
                <Icon
                  library='code'
                  symbol={selected === option.value ? 'Radio-on' : 'Radio-off'}
                  className={classNames('self-center',{[CSS.selected]: selected === option.value})}
                />
              </div>
            </Card>
          ))}
        </div>

      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          onClick={selected === 'diy' ? openUhaulModal : submit}
          disabled={pending || !selected}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
}
