import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Form, FormOptionList } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { getMove } from '../../../moves/actions';
import { createTaskList, useTaskListPending } from '../../../personalTasks/actions/createTaskList';
import { createNewTasks, useCreateNewTasksPending } from '../../../personalTasks/actions/createNewTasks';
import { getRecommendedPersonalTaskTypes, useRecommendedPersonalTaskTypesPending } from '../../../personalTasks/actions/getRecommendedPersonalTaskTypes';

import { useWizard, Content, Actions, SideImage } from './OnboardingWizard';

import image from './images/tasks-graphic.png';
import CSS from './StepTasks.module.scss';

export const StepTasks = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { move, next, prev } = useWizard();
  const activeMoveStep = move?.move_steps?.at(0);
  const buildingName = activeMoveStep?.building?.name;
  const [tasks, setTasks] = useState([]);

  const recommendedTasksPending = useRecommendedPersonalTaskTypesPending()
  const createTaskListPending = useTaskListPending();
  const createTasksPending = useCreateNewTasksPending();
  const pending = recommendedTasksPending || createTaskListPending || createTasksPending;

  useEffect(() => {
    dispatch(getRecommendedPersonalTaskTypes(move?.id))
      .then(setTasks)
      .catch(err => notify.error(format.error(err)));
  },[move, notify, dispatch]);

  const submit = ({ personal_tasks=[] }) => {
    if(personal_tasks?.length === 0) return next();
    (move?.personal_task_list_id ?
      dispatch(createNewTasks(move.personal_task_list_id, { task_type_ids: personal_tasks })) :
      dispatch(createTaskList(move.id, { task_type_ids: personal_tasks }))
    )
      .then(() => dispatch(getMove(move.id))) // refresh move to update new task count
      .then(() => next())
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <>
      <Content>

        <div className='stackVertical gap-4'>
          <div className='headingL contentPrimary'>
            <span className='contentSecondary marginRight-12'>3/3</span>
            What do you need to take care of?
          </div>
          <div className='labelM contentSecondary'>
            { buildingName ? (
              `In addition to the details that ${buildingName} needs to collect from you, we can help you with all aspects of your move.`
            ) : (
              `Select some tasks to help us understand how we can help you with your move.`
            )}
          </div>
        </div>

        <Form
          name={'tasks-form'}
          onSubmit={submit}
          className={CSS.scrollable}
        >
          <FormOptionList
            name='personal_tasks'
            allowMultiple
            size='small'
            options={
              tasks.map(task => ({
                value: task.id,
                label: task.display_name,
                icon: task.icon,
              }))
            }
          />
        </Form>

        <Actions>
          <Button
            text='Back'
            icon={{ library: 'navigation', symbol: 'Arrow-left' }}
            onClick={prev}
            color='secondary'
            disabled={pending}
          />
          <Button
            text='Next'
            icon={{ library: 'navigation', symbol: 'Arrow-right' }}
            iconPosition='right'
            form='tasks-form'
            disabled={pending}
          />
        </Actions>

      </Content>

      <SideImage src={image} />

    </>
  );
};
