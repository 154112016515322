import moment from 'moment';
import { get } from 'lodash';

import { deposit as helpers } from '@moved/product';

import { BaseTask } from '../shared/components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class Deposit extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useSecurityDeposit,
        ...selectors,
      }
    });
  }

  static label = 'Security Deposit';
  static slug = 'deposit';
  static icon = 'Dollar';
  static helpers = helpers;

  _flowBuilder(taskData) {
    return get(taskData,'balance') && this.isPastDeadline(taskData) ? [
      this.screens['deposit-offline'],
    ] : [
      this.screens['deposit-payment'],
      this.screens['deposit-summary'],
    ];
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  isPaymentComplete({ payments, lease, balance }) {
    const myPayments = (payments ?? []).filter(payment => !lease || payment.tenant_id === lease.active_tenant.id);
    return balance === 0 || (lease.roommates?.length && myPayments.length);
  }

  getDeadline(taskData) {
    const leaseStart = get(taskData,'lease.start_date');
    const leadTime = get(taskData,'submission_lead_time_in_days');
    return leaseStart && leadTime && moment(leaseStart).subtract(leadTime,'d');
  }

  isPastDeadline(taskData) {
    const deadline = this.getDeadline(taskData);
    return deadline && moment().isAfter(deadline,'day');
  }

  isDeadlineApproaching(taskData) {
    const deadline = this.getDeadline(taskData);
    return deadline && moment().isAfter(deadline.subtract(5,'d'),'day');
  }
  /* END TASK SPECIFIC */

}
