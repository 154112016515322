import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { bookMovers } from '@moved/product';
import { CompleteCard, FieldList } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { Snippet } from '../../../../snippets';
import { Screen, CompleteScreenActions } from '../../shared';

export const ConfirmNonCoreGeo = ({screen, taskDetails, taskDefinition, moveId, origin, goToScreen, onClose }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const updatePending = taskDefinition.selectors.useUpdateMoverBookingPending?.();
  const resetPending = taskDefinition.selectors.useResetMoverBookingTaskPending?.();
  const pending = updatePending || resetPending;

  const _changeSelection = () => {
    // Revert mover_booking data
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, {
      requested_pickup_date: null,
      stops: [],
      contact_email: null,
    }))
      // then reset the task
      .then(r => taskDefinition.actions.reset ? dispatch(taskDefinition.actions.reset(taskDetails.id)) : r)
      // refresh the move because reset affects MBT and MT on the move
      .then(r => dispatch(getMove(moveId)).then(() => r))
      .then(r => goToScreen(taskDefinition.getStartOverScreen(r)))
      .catch(err => notify.error(format.error(err)));
  };

  const fields = [
    ...bookMovers.getSummaryFields(moverBooking),
    {
      value: (
        <div
          className={classNames('btn-gray btn--small',{'loading':pending})}
          disabled={pending}
          onClick={_changeSelection}
        >
          Start over
        </div>
      )
    },
  ];

  const flavor = (
    <Snippet tag={'tasks.book-movers.summary.non-core-geo'}>
      We don't have moving partners in your location quite yet. If your final destination changes, please check back to see if we can help!
    </Snippet>
  );

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <CompleteCard
          icon={{ symbol: 'Marker#1', color: 'orange'}}
          title='Sorry – no supported movers in your area.'
          flavor={flavor}
        >
          <FieldList list={fields} />
        </CompleteCard>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  )
};
