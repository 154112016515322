import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useNotify, format, useRouter } from '@moved/services';
import { Button, Form, FormText, Notebox } from '@moved/ui';

import { createFaqTopic } from '../actions/createFaqTopic';

import { useAssistant } from './AssistantWidget';

const validationSchema = Yup.object().shape({
  label: Yup.string().required('Required'),
  display_name: Yup.string().required('Required'),
});

export const CreateFaqTopicForm = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { activeRouteAddress } = useRouter();
  const { refreshFaqs } = useAssistant();

  const [pending, setPending] = useState(false);

  const createTopic = (values) => {
    setPending(true);
    dispatch(createFaqTopic({
      ...values,
      screen_address: activeRouteAddress,
    }))
      .then(() => refreshFaqs())
      .catch((err) => notify.error(format.error(err)));
  };

  return (
    <>
      <Form
        name='addFaqTopic'
        onSubmit={createTopic}
        className='stackVertical gap-24'
        validation={validationSchema}
      >
        <Notebox
          color='info'
          title='Create a Q&A topic'
          body='This screen has not yet been assigned a topic. Before you can add a new Q&A, you must create one.'
        />
        <h3 className='labelL contentPrimary'>Topic details</h3>
        <FormText
          name='display_name'
          label='Display name'
        />
        <FormText
          name='label'
          label='Short name'
          hint='Only include characters and dashes. (i.e. my-topic-name)'
        />
      </Form>

      <div className='flex-grow stackHorizontal justify-end items-end'>
        <Button
          form='addFaqTopic'
          text='Create topic'
          disabled={pending}
        />
      </div>
    </>
  );
};
