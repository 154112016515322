import React, { useState } from 'react';
import classNames from 'classnames';

import { Header, Footer } from '@moved/product';

import CSS from './styles/LayoutCollapsibleHeader.module.scss';

export const LayoutCollapsibleHeader  = ({ theme, className, navigation, children }) => {
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = e => setScrolled(e.target.scrollTop > 30);
  return (
    <div className='height-full'>
      <Header
        theme={theme}
        tight={scrolled}
        navigation={navigation}
      />
      <div id='layout' className={classNames(CSS.template,className)} onScroll={handleScroll}>
        {children}
        <Footer/>
      </div>
    </div>
  );
}
