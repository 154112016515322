import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ASSISTANT_MARK_FAQ_ANSWER_HELPFUL',
  request: (id, data={}) => request.post(`/residents/faq-answers/${id}/mark-helpful`, data),
};

export const {
  reducer,
  action: markAnswerHelpful,
  usePending: useMarkAnswerHelpfulPending,
} = generate.redux(creator);
