import React from 'react';

import { format, useModal } from '@moved/services';
import { Button, Notebox } from '@moved/ui';

import mapIcon from '../../../../dashboard/components/assets/map-icon.png';
import { useActiveMoveStep } from '../../../../dashboard';

import { KeyPickupInstructionsModal } from './KeyPickupInstructionsModal';

import CSS from './styles/KeyPickupLocationNotebox.module.scss';

export const KeyPickupLocationNotebox = ({ request }) => {
  const modal = useModal();
  const activeMoveStep = useActiveMoveStep();

  const address = request?.building_calendar?.address;
  const instructions = request?.building_calendar?.instructions;

  if (!address && instructions?.blocks?.length === 0) return null;

  const openInstructionsModal = () => modal.open(
    <KeyPickupInstructionsModal instructions={instructions}/>
  );

  const mapUrl = `https://www.google.com/maps/place/?q=place_id:${address?.google_place_id ?? activeMoveStep?.lease?.address?.google_place_id}`;

  return (
    <Notebox
      color='transparent'
      body={(
        <div className='stackHorizontal gap-24 width-full items-start'>
          <a href={mapUrl} target='_blank' rel='noopener noreferrer'>
            <div className={CSS.locationMap} style={{backgroundImage:`url(${mapIcon})`}} />
          </a>
          <div className='stackVertical gap-4 self-center'>
            <div className='labelXS contentSecondary'>
              Pick Up Location
            </div>
            <div className='labelM'>
              <a href={mapUrl} target='_blank' rel='noopener noreferrer'>
                { address ? format.address(address) : 'Key pick up at your community' }
              </a>
            </div>
          </div>
        </div>
      )}
      actions={ instructions?.blocks?.length > 0 && (
        <Button
          color='secondary'
          onClick={openInstructionsModal}
          text='View pick up instructions'
        />
      )}
    />
  );
};
