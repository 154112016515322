import React from 'react';

import { RouteTree } from '@moved/services';
import { ProxyModeBadge } from '@moved/product';

import { EasterEgg } from './EasterEgg';

import CSS from './styles/App.module.scss';

export const App = () => (
  <div className={CSS.consumer_app}>
    <RouteTree />
    <ProxyModeBadge />
    <div id='customer-assistance'/>
    <EasterEgg />
  </div>
);
