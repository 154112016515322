import React from 'react';
import classNames from 'classnames';

import { Icon, Notebox } from '@moved/ui';
import { format, helpers } from '@moved/services';

import CSS from './styles/OtherVehicles.module.scss';

const VehicleSummary = ({ vehicle }) => {
  const statusMap = { 'pending-approval': 'In review' };
  return (
    <div className={CSS.panel}>
      <div className={CSS.splash}>
        <Icon
          symbol={format.capitalize(vehicle.vehicle_type)}
          library={'vehicles'}
          size={'30px'}
        />
      </div>
      <div className={CSS.grow}>
        <div className={CSS.make}>
          <p>{vehicle.make}</p>
          <span>Make</span>
        </div>
        <div className={CSS.model}>
          <p>{vehicle.model}</p>
          <span>Model</span>
        </div>
        <div className={CSS.color}>
          <p>{vehicle.color}</p>
          <span>Color</span>
        </div>
        <div className={CSS.submitted_by}>
          <p>{format.fullname(vehicle.submitted_by)}</p>
          <span>Provided by</span>
        </div>
      </div>

      <div className={CSS.status}>
        <div className={classNames(CSS.pill,CSS[(vehicle?.status ?? '').toLowerCase()])}>
          {statusMap[vehicle.status] ?? format.capitalize(helpers.convertNamingConvention(vehicle.status,'kebab','space'))}
        </div>
      </div>
    </div>
  );
};

export const OtherVehicles = ({ taskDetails }) => {
  if((taskDetails?.other_vehicles_on_lease ?? []).length < 1) return null;
  return (
    <Notebox
      color='info'
      icon={{ symbol: 'Warning-1-circle', library: 'code' }}
      title='Registered vehicle(s) associated with your lease'
      body={(
        <div className='stackVertical gap-12 width-full paddingTop-4'>
          { taskDetails?.other_vehicles_on_lease?.map(vehicle => (
            <VehicleSummary key={vehicle.id} vehicle={vehicle}/>
          ))}
        </div>
      )}
    />
  );
};
