import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import classNames from 'classnames';

import { Transitions } from '@moved/ui';
import { useOnScroll } from '@moved/services';

import { useMove } from '../../moves/actions/selectors';
import { VideoBG } from './VideoBG';

import CSS from './styles/SplashHeader.module.scss';

export const SplashHeader = ({ background, video, className, children }) => {
  const { moveId } = useParams();
  const move = useMove(moveId);
  const hasMultipleMoveSteps = get(move,'move_steps',[]).length > 1;
  const [scrollPosition, setScrollPosition] = useState(0);

  const { ref } = useOnScroll((event) => setScrollPosition(event.target.scrollTop));

  return (
    <section className={classNames(CSS.header,className)} ref={ref}>
      <div className={classNames(CSS.splashWrapper,'go-full-page-hook')}>
        <Transitions transition='crossFade' pageKey={background || video} className={CSS.transition}>
          <div className={CSS.splash} style={{
            backgroundImage: background ? `url(${background})`: 'none',
            transform: `translateY(${Math.min(scrollPosition/2,225)}px)`,
            filter: `blur(${Math.min(scrollPosition/40,10)}px)`,
          }}>
            { video && <VideoBG video={video} /> }
          </div>
        </Transitions>
      </div>
      <div className={CSS.contentWrapper}>
        <div className={classNames(CSS.content,{[CSS.navigation]:hasMultipleMoveSteps && !video})}>
          {children}
        </div>
      </div>
    </section>
  );
};
