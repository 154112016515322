import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, OptionList, LoaderOverlay } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { ResidentContentRenderer } from '../../../../common';
import { Screen } from '../../shared';

import { getQuestionDetails, useQuestionDetails } from '../actions/getQuestionDetails';
import { createRequest } from '../actions/createRequest';
import { updateRequest } from '../actions/updateRequest';
import { submit } from '../actions/submit';

export const Question = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  const choiceTask = taskDefinition.selectors.useTaskable(id);
  const activeRequest = taskDefinition.helpers.getActiveRequest(choiceTask);
  const questionId = choiceTask?.settings?.choice_task_question?.id;
  const question = useQuestionDetails(questionId);
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);

  // selected is always an array, even for single select
  const [selected, setSelected] = useState(activeRequest?.selected_options?.map(option => option.id) ?? []);

  useEffect(() => {
    if(!questionId || !loading) return;
    dispatch(getQuestionDetails(questionId))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setLoading(false));
  }, [questionId, loading, dispatch, notify]);

  const submitChoice = async () => {
    if(pending || !selected) return;
    setPending(true);
    const request = activeRequest ?? await dispatch(createRequest(choiceTask.id))
      .then((updatedTask) => taskDefinition.helpers.getActiveRequest(updatedTask));
    dispatch(updateRequest(request.id, { selected_option_ids: selected }))
      .then(() => dispatch(submit(request.id)))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)))
      .finally(() => setPending(false));
  };

  return (
    <Screen
      taskDefinition={taskDefinition}
      screen={{
        title: question?.title,
        subtitle: question?.subtitle,
        ...screen
      }}
    >
      <Screen.Title />
      <Screen.Content>

        <div className='stackVertical gap-12'>
          { loading || !question ? (
            <LoaderOverlay />
          ) : (
            <OptionList
              name='selectedOption'
              allowMultiple={question?.allow_multi_select}
              value={question?.allow_multi_select ? selected : selected?.[0]}
              isControlled={true}
              options={question?.options?.map(option => ({
                value: option.id,
                label: option.title,
                description: option.subtitle,
                icon: option.icon,
                extendedContent: (option?.description?.blocks?.length > 0 && <ResidentContentRenderer content={option?.description} />)
              }))}
              onChange={({ selectedOption }) => setSelected(question?.allow_multi_select ? selectedOption : [selectedOption])}
            />
          )}
        </div>

      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          onClick={submitChoice}
          disabled={pending || !selected.length}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
}
