import { Button } from '@moved/ui';

import { useAssistant } from './AssistantWidget';
import { PanelHeader } from './PanelHeader';
import { CreateFaqForm } from './CreateFaqForm';
import { CreateFaqTopicForm } from './CreateFaqTopicForm';

export const CreateFaqPanel = ({ animationClass }) => {
  const {
    topic,
    availablePanels,
    switchPanel,
  } = useAssistant();

  return (
    <div className='stackVertical gap-24 height-full'>
      <PanelHeader
        title='Add Q&A'
        animationClass={animationClass}
        action={(
          <Button
            onClick={() => switchPanel(availablePanels.includes('faq') ? 'faq' : 'contact')}
            icon={{ library: 'navigation', symbol: 'Chevron-left' }}
            color='secondary'
            size='small'
          />
        )}
      />
      <div className='height-full width-full stackVertical gap-8 scrollable'>
        { topic ? (
          <CreateFaqForm />
        ) : (
          <CreateFaqTopicForm />
        )}
      </div>
    </div>
  );
};
