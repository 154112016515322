import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';

import CSS from './MenuIcon.module.scss';

export const MenuIcon = ({ size='36px', onChange, className, isOpen, isControlled }) => {
  const [isActive, setIsActive] = useOptionalControl(isOpen, isControlled);
  const toggleActive = (newVal) => {
    setIsActive(newVal);
    onChange?.(newVal);
  };
  return (
    <div
      className={classNames(
        CSS.icon,
        CSS[`size-${parseInt(size)}`],
        {[CSS.active]:isActive},
        className,
      )}
      onClick={() => toggleActive(!isActive)}
      style={{ height: size, width: size }}
    >
      <div className={CSS.line} />
      <div className={CSS.line} />
      <div className={CSS.line} />
    </div>
  );
};

MenuIcon.propTypes = {
  /** Size in pixels to render the icon box. Due to resizing limitations only a set list is accepted */
  size: PropTypes.oneOf(['16px','18px','22px','28px','32px','36px','48px','54px']),
  /** Callback function when the toggle state changes (receives boolean isActive value) */
  onChange: PropTypes.func,
  /** Class name to add to the icon */
  className: PropTypes.string,
  /** Controlled state for the icon */
  isOpen: PropTypes.bool,
  /** Whether the toggle is controlled or not */
  isControlled: PropTypes.bool,
}
