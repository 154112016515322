import { useEffect } from 'react';
import { useScrollParent } from './useScrollParent';

export const useOnScroll = (onScroll) => {
  const { ref, scrollParent } = useScrollParent();
  useEffect(() => {
    if(!scrollParent) return;
    scrollParent?.addEventListener('scroll',onScroll);
    return () => scrollParent?.removeEventListener('scroll',onScroll);
  }, [scrollParent, onScroll]);
  return { ref };
};
