import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_SUBMIT_CHOICE_TASK_REQUEST',
  request: (id) => request.post(`/residents/choice-requests/${id}/submit`, {})
    .then(r => r.data.data.choice_task),
  reducers: {
    success: (state, { response: choiceTask }) => ({
      ...state,
      choiceTasks: {
        ...state.choiceTasks,
        [choiceTask.id]: choiceTask,
      },
    }),
  }
};

export const {
  reducer,
  action: submit,
  usePending: useSubmitPending,
} = generate.redux(creator);

export const initialState = {
  choiceTasks: {},
}
