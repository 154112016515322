import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isFunction } from 'lodash';

import { DynamicForm, AtomSpinner, Button } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { Screen } from './Screen';

export const DynamicFormScreen = ({ screen, nextScreen, taskDefinition }) => {
  const { id, moveId } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  // state
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);
  const move = taskDefinition.selectors.useMove(moveId);

  const context = { building, move, activeMoveStep };

  // redux
  const taskDetails = taskDefinition.selectors.useTaskable(id);

  const additionalData = { ...context };
  // use additionalData keys to drive which selectors to load
  Object.keys(screen.additionalData || {}).forEach(key => {
    const selectorName = screen?.additionalData?.[key].selector;
    if(!selectorName) return;
    const params = screen?.additionalData?.[key]?.getParams?.(context) ?? [];
    additionalData[key] = taskDefinition.selectors[selectorName](...params);
  });

  useEffect(() => {
    let promises = [];
    // use additionalData keys to drive which actions to dispatch
    Object.keys(screen.additionalData || {}).forEach(key => {
      const actionName = screen?.additionalData?.[key].action;
      if(!actionName) return;
      const params = screen?.additionalData?.[key]?.getParams?.(context) ?? [];
      const action = taskDefinition.actions[actionName];
      action && promises.push(dispatch(action(...params)));
    });
    Promise.all(promises).then(() => setLoading(false));
  },[]); // eslint-disable-line


  const _handleSubmit = formData => {
    setPending(true);
    dispatch(taskDefinition.actions.update(id, formData))
      .then(nextScreen)
      .catch(err => {
        setPending(false);
        notify.error(format.error(err));
      });
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        { (screen.fields && !loading) ? (
          <DynamicForm
            id={`${screen.slug}-form`}
            fields={screen.fields(taskDetails,additionalData)}
            validation={isFunction(screen.validation) ? screen.validation(taskDetails,additionalData) : screen.validation }
            formStyle={screen.formStyle || 'underline'}
            onSubmit={_handleSubmit}
            autocomplete={screen.autocomplete}
            disabled={pending}
          />
        ) : (
          <AtomSpinner />
        )}
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form={`${screen.slug}-form`}
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
}
