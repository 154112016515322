import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useNotify, format } from '@moved/services';
import { Button, Form, FormTextarea, FormToggle } from '@moved/ui';

import { updateFaqAnswer } from '../actions/updateFaqAnswer';

import { useAssistant } from './AssistantWidget';

const validationSchema = Yup.object().shape({
  answer: Yup.string().required('Required'),
  route_to_property: Yup.boolean(),
});

export const EditFaqForm = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const {
    activeQuestion,
    switchPanel,
    refreshFaqs,
  } = useAssistant();

  const [pending, setPending] = useState(false);

  const update = ({ question, ...values }) => {
    setPending(true);
    dispatch(updateFaqAnswer(activeQuestion?.answer?.id,{
      ...values,
      faq_question_id: activeQuestion?.id,
    }))
      .then(() => {
        refreshFaqs();
        notify.success('Answer updated successfully');
        switchPanel('faq');
      })
      .catch((err) => notify.error(format.error(err)))
      .finally(() => setPending(false));
  };

  return (
    <>
      <Form
        name='updateFaqAnswer'
        onSubmit={update}
        className='stackVertical gap-24'
        validation={validationSchema}
      >
        <FormTextarea
          name='question'
          label='Question'
          value={activeQuestion?.question}
          autoResize
          readOnly
        />
        <FormTextarea
          name='answer'
          label='Answer'
          value={activeQuestion?.answer?.answer}
          autoResize
        />
        <FormToggle
          name='route_to_property'
          label='Route to property'
          value={activeQuestion?.answer?.route_to_property}
        />
      </Form>

      <div className='flex-grow stackHorizontal justify-end items-end'>
        <Button
          form='updateFaqAnswer'
          text='Update'
          disabled={pending}
        />
      </div>

    </>
  );
};
