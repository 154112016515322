import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { format } from '@moved/services';
import { CompleteCard, Icon } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { Screen, CompleteScreenActions } from '../../shared';

import CSS from './styles/Payment.module.scss';

export const DepositSummary = ({ screen, taskDetails, taskDefinition, moveId, origin, taskBaseRoute, onClose }) => {
  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <CompleteCard
          status={'approved'}
          detailsTitle={null}
          flavor={
            taskDetails.balance ? (
              <Snippet tag={'tasks.deposit.summary.partial'}>Your payment has been accepted.</Snippet>
            ) : (
              <Snippet tag={'tasks.deposit.summary.full'}>Your security deposit has been paid in full!</Snippet>
            )
          }
        >
          <div className={CSS.attributes}>
            <div className={CSS.section}>
              <div className={CSS.line_item}>
                <h3 className={classNames(CSS.line_label, CSS.black)}>Total Amount</h3>
                <h3 className={classNames(CSS.line_value, CSS.black)}>{format.currency(taskDetails.total)}</h3>
              </div>
            </div>
            <div className={classNames('stackVertical gap-8', CSS.section)}>
              <div className={CSS.line_item}>
                <div className={classNames(CSS.line_label, CSS.black)}>Payments</div>
              </div>
              { taskDetails.payments.map((payment) => (
                <div className={CSS.line_item} key={payment.id}>
                  <span className={classNames(CSS.line_label, CSS.black)}>
                    <Icon size='20px' symbol='Check' className={CSS.line_icon} />
                    { (payment.firstname && payment.lastname) ? `${payment.firstname} ${payment.lastname}` : 'Name unspecified' }
                    <span className={CSS.line_subtext}>{format.date(payment.created_at,'dateShort')}</span>
                  </span>
                  <span className={classNames(CSS.line_value, CSS.black)}>{format.currency(payment.amount)}</span>
                </div>
              ))}
            </div>
            <div className={CSS.section}>
              <div className={CSS.line_item}>
                <h3 className={classNames(CSS.line_label, CSS.black)}>Remaining Balance</h3>
                <h3 className={classNames(CSS.line_value, CSS.black)}>{format.currency(taskDetails.balance)}</h3>
              </div>
            </div>
            { taskDetails.balance > 0 && (
              <div className={CSS.section}>
                <div className={CSS.line_item}>
                  <Link
                    to={`${taskBaseRoute}${taskDefinition.getStartOverRoute(taskDetails)}`}
                    id={`${taskDefinition.slug}_${screen.slug}_payment_button`}
                    className={'btn-gray btn--full btn--small'}
                  >
                    Make another payment
                  </Link>
                </div>
              </div>
            )}
          </div>
        </CompleteCard>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  )
};
