import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ASSISTANT_CREATE_FAQ_TOPIC',
  request: (data={}) => request.post(`/residents/faq-topics`, data, { asSuperUser: true })
    .then(r => r.data.data.faq_topic),
};

export const {
  reducer,
  action: createFaqTopic,
  usePending: useCreateFaqTopicPending,
} = generate.redux(creator);
