import React, { useState, useEffect, createContext, useContext } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { AtomSpinner } from '@moved/ui';
import { useNotify, useRouter, format, useUser } from '@moved/services';

import { useBuilding } from '../../common/actions/selectors';
import { useActiveMoveStep } from '../../dashboard';
import { getFaqs, useFaqs, useFaqsPending } from '../actions/getFaqs';

import { AssistantLauncher } from './AssistantLauncher';
import { ContactPanel } from './ContactPanel';
import { FaqsPanel } from './FaqsPanel';
import { CreateFaqPanel } from './CreateFaqPanel';
import { EditFaqPanel } from './EditFaqPanel';
import { OverrideFaqPanel } from './OverrideFaqPanel';

import CSS from './styles/AssistantWidget.module.scss';

// transition values based on CSS for DRYness
const transitionTimeout = {
  enter: parseInt(CSS.enterDuration) + parseInt(CSS.enterDelay),
  exit: parseInt(CSS.exitDuration) + parseInt(CSS.exitDelay),
};

// TODO: where should this live or should this logic be distributed to the
// routes and then a route service provides this information?
const routesWithHiddenAssistant = [
  'tasks.utility-setup.public-grid',
];

const panelComponentMap = {
  contact: ContactPanel,
  faq: FaqsPanel,
  create: CreateFaqPanel,
  edit: EditFaqPanel,
  override: OverrideFaqPanel,
};

const AssistantContext = createContext();
export const useAssistant = () => useContext(AssistantContext);

export const AssistantWidget = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { isProxy } = useUser();
  const { activeRouteAddress } = useRouter();

  const activeMoveStep = useActiveMoveStep();
  const building = useBuilding(activeMoveStep?.building?.slug);
  const buildingContactDisabled = building && !building.settings.contact_form_enabled;

  const [open, setOpen] = useState(false);
  const [activePanel, setActivePanel] = useState('faq');
  const [activeQuestion, setActiveQuestion] = useState();

  const { questions, topic } = useFaqs(activeMoveStep?.id, activeRouteAddress) ?? {};
  const pending = useFaqsPending();

  const refreshFaqs = () => dispatch(getFaqs(activeMoveStep.id, activeRouteAddress));

  useEffect(() => {
    setOpen(false);
    if(!activeMoveStep?.id || !activeRouteAddress) return setActivePanel('contact');
    dispatch(getFaqs(activeMoveStep.id, activeRouteAddress))
      .then(({ pagination }) => pagination?.total_items <= 0 && setActivePanel('contact'))
      .catch((err) => notify.error(format.error(err)));
  },[activeRouteAddress, activeMoveStep?.id, dispatch, notify]);

  const availablePanels = [
    questions?.length > 0 && 'faq',
    !buildingContactDisabled && 'contact',
    isProxy && 'create',
    isProxy && 'edit',
    isProxy && 'override',
  ].filter(Boolean);

  const switchPanel = (newPanel, question) => {
    setActiveQuestion(question);
    setActivePanel(newPanel);
  };

  const toggleAssistant = () => {
    if(open) return setOpen(false);
    setOpen(true);
    if(activePanel !== availablePanels[0]) switchPanel(availablePanels[0]);
  };

  // place to insert the Assistant up the tree for consistent layout
  const assistantPortal = document.getElementById('customer-assistance');

  if(
    !assistantPortal || // must have rendered the application root
    routesWithHiddenAssistant.includes(activeRouteAddress) || // if the active path is in the hidden list
    availablePanels.length === 0 // if there are no available panels
  ) return null;

  const ActivePanelComponent = panelComponentMap[activePanel];

  const assistantContext = {
    building,
    topic,
    questions,
    activePanel,
    activeQuestion,
    availablePanels,
    switchPanel,
    toggleAssistant,
    refreshFaqs,
  };

  const openClasses = classNames(CSS.open,{ [CSS.contactOnly]: activePanel === 'contact' && availablePanels.length === 1 });

  return createPortal((
    <div className={CSS.container}>

      <AssistantLauncher isOpen={open} onClick={toggleAssistant} />

      <CSSTransition
        in={open}
        timeout={transitionTimeout}
        mountOnEnter={true}
        unmountOnExit={true}
        classNames={{
          enterActive: openClasses,
          enterDone: openClasses,
        }}
      >
        <div className={CSS.contents}>
          <AssistantContext.Provider value={assistantContext}>
            { pending ? (
              <div className='height-full stackVertical justify-center items-center'>
                <AtomSpinner />
              </div>
            ) : (
              ActivePanelComponent && <ActivePanelComponent animationClass={CSS.scaleIn}/>
            )}
          </AssistantContext.Provider>
        </div>
      </CSSTransition>
    </div>
  ), assistantPortal);
};

export const withAssistant = (Wrapped) => (props) => (<><Wrapped {...props}/><AssistantWidget/></>);
