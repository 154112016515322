import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import { format, useMobile, helpers, useNotify } from '@moved/services';
import { Icon } from '@moved/ui';

import { ResidentTagRenderer } from '../../../../common';
import { Screen } from '../../shared';
import { KeyPickupInstructionsLink } from './KeyPickupInstructionsLink';

import CSS from './styles/KeyPickup.module.scss';

const OptionCard = ({option, action, pending}) => {
  const isMobile = useMobile();
  const { content } = option;
  const address = get(option, 'address');
  const instructions = get(option,'instructions');
  const [selected, setSelected] = useState();

  const onSelect = (data) => {
    setSelected(true);
    action(data);
  };

  return (
    <div className={CSS.card_wrapper}>
      <div className={CSS.card_item_wrapper}>
        <div className={CSS.card_item}>
          { content.banner_text && <div className={CSS.ribbon}><span className={CSS.ribbon_text}>{content.banner_text}</span></div> }
          <div className={CSS.card_content}>
            <div className={CSS.content_top}>
              { content.icon_symbol &&
              <div className={CSS.card_icon}>
                <Icon symbol={get(content,'icon_symbol')} library={get(content,'icon_library')} size={isMobile ? '40px' : '48px'} />
              </div>
              }
              <div className={CSS.title_wrapper}>
                { content.title && <div className={CSS.card_title}>{content.title}</div> }
                { content.subtitle && <div className={CSS.card_subtitle}>{content.subtitle}</div> }
              </div>
              <div className={CSS.card_action}>
                <div className={CSS.button_wrapper}>
                  <button
                    type='button'
                    id={`pickup_option_${option.value}`}
                    className={classNames('btn-primary', CSS.card_button, {'loading': pending && selected, 'btn-gray': !option.recommended})}
                    style={content.cta_color ?
                      {
                        backgroundColor: content.cta_color,
                        color: helpers.isHexDark(content.cta_color) ? '#fff' : null
                      } : null
                    }
                    value={option.value}
                    onClick={onSelect}
                    disabled={pending}
                  >Select</button>
                </div>
              </div>
            </div>

            <div className={CSS.content_bottom}>
              <div className={CSS.card_description}>
                { content.description_html && (
                  <ResidentTagRenderer content={content.description_html} />
                )}

                { (address != null || (get(instructions, 'blocks.length', 0) > 0)) &&
                  <span className={CSS.location_info}>
                    { address && (
                      <>
                        <Icon className={'location_icon'} library={'map'} symbol={'Marker#1'} size='20px'/>
                        <span className='flex-auto'>{'Located at ' + format.address(address)}</span>
                      </>
                    )}
                    <KeyPickupInstructionsLink instructions={instructions}/>
                  </span>
                }

              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  )
};

export const KeySelectCalendarType = ({screen, nextScreen, taskDefinition}) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  const keyPickupTask = taskDefinition.selectors.useTaskable(id);
  const createPending = taskDefinition.selectors.useCreateRequestPending();
  const updatePending = taskDefinition.selectors.useUpdateRequestPending();
  const calOptions = taskDefinition.selectors.useKeyCalendar();
  const calendarsPending = taskDefinition.selectors.useKeyCalendarPending();

  useEffect(() => {
    dispatch(taskDefinition.actions.getCalendarOptions(id))
      .catch(() => notify.error('Error loading calendar data'))
  // eslint-disable-next-line
  },[]); // only run on mount

  const handleSelect = data => {
    if (createPending || updatePending || calendarsPending) return;

    const draftRequest = get(keyPickupTask,'requests[0].status') === 'draft' ? keyPickupTask.requests[0] : null;

    if(draftRequest) {
      dispatch(taskDefinition.actions.updateRequest(draftRequest.id, { 'building_calendar_id': data }))
        .then(nextScreen)
        .catch(error => notify.error( get(error, 'response.data.message') ));
    } else {
      dispatch(taskDefinition.actions.createRequest(id))
        .then((resp) => dispatch(taskDefinition.actions.updateRequest(resp.requests[0].id, {
          'tenant_key_ids': [ keyPickupTask.current_tenant_key.id],
          'building_calendar_id': data
        })))
        .then(nextScreen)
        .catch(error => notify.error( get(error, 'response.data.message') ));
    }
  }

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className='stackVertical gap-16'>
          { calOptions.map(option => (
            <OptionCard
              key={`option_${option.id}`}
              option={option}
              pending={createPending || updatePending}
              action={() => handleSelect(option.id)}
            />
          ))}
        </div>
      </Screen.Content>
    </Screen>
  )
};
