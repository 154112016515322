import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get, merge } from 'lodash';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { useNotify, format } from '@moved/services';
import { Button, BouncingDots, Notebox } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import { useSnippet } from '../../../../snippets/hooks/useSnippet';

import { Screen } from '../../shared';

import CSS from './styles/Instructions.module.scss';

export const Instructions = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  const pending = taskDefinition.selectors.useSubmitHappycoInspectionTaskPending();
  const taskDetails = taskDefinition.selectors.useTaskable(id);
  const moveStep = taskDefinition.selectors.useActiveMoveStep();

  const { content, title, subtitle, cta_text, side_column_image_url } = get(taskDetails,'instruction_content') || {};
  const inspection = taskDefinition.helpers.getActiveRequest(taskDetails);

  const submit = () => {
    // if already submitted, just navigate forward
    if(taskDetails.submitted_at) return nextScreen();
    // otherwise submit and navigate forward
    dispatch(taskDefinition.actions.submit(id))
      .then(nextScreen)
      .catch(error => notify.error(format.error(error)));
  };

  const linkToHappyco = () => {
    dispatch(taskDefinition.actions.click(get(inspection,'id')))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => notify.error(format.error(err)));
  };

  const leaseDate = moveStep.type === 'move-in' ? get(moveStep,'lease.start_date') : get(moveStep,'lease.end_date');
  const isBeforeLeaseDate = leaseDate ? DateTime.fromISO(leaseDate) > DateTime.now() : false;

  const startInspectionSnippet = useSnippet('tasks.happyco-inspection.instructions.cta_text', 'HappyCo linkout button text');

  return (
    <Screen taskDefinition={taskDefinition} screen={merge(screen,{ title, subtitle })}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <Notebox
            title='Please note'
            body={`If there are multiple roommates listed on the lease, either ensure that all are present to complete the inspection, or refrain from selecting "${cta_text || 'Confirm completion'}" so that all roommates have the opportunity to provide their feedback.`}
            color='blue'
            icon={{ library: 'code', symbol: 'Info-circle' }}
          />
        )}
      >
        <div className={CSS.content_wrapper}>

          <div className={CSS.instructions}>

            { inspection ? (
              <>
                <ResidentContentRenderer content={content} tags={{
                  inspection_due_date: get(inspection,'due_date') ?
                    format.date(get(inspection,'due_date'),'date') :
                    'TBD',
                }} />
                <Button
                  color='primary'
                  size='large'
                  className={classNames('marginTop-28',CSS.happycoButton)}
                  onClick={linkToHappyco}
                  icon={{ symbol: 'Up-right', library: 'navigation' }}
                  iconPosition='right'
                  text={startInspectionSnippet ?? 'Start inspection'}
                />
              </>
            ) : (
              <div className='stackVertical gap-28 marginTop-28 marginBottom-28'>
                <div className='headingL contentPrimary'>
                  Inspection invitation pending<BouncingDots/>
                </div>
                { isBeforeLeaseDate ? (
                  <div className='labelL contentSecondary'>
                    Your inspection invitation link will be made available here
                    on the day of your {moveStep.type}. Please check back
                    here on that day to complete this task.
                  </div>
                ) : (
                  <>
                    <div className='labelL contentSecondary'>
                      Moved receives inspection invitation links automatically for
                      future tenants of participating properties. Unfortunately it
                      looks like yours is not yet available.
                    </div>
                    <div className='labelL contentPrimary'>
                      Check back in 24 hours.
                    </div>
                    <div className='labelL contentSecondary'>
                      If you have been waiting and think maybe your link got lost,
                      check in with your property to arrange an inspection.
                    </div>
                  </>
                )}
              </div>
            )}

          </div>

          { side_column_image_url && (
            <div className={CSS.side_column} style={{ backgroundImage: `url(${side_column_image_url})`}} />
          )}

        </div>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text={cta_text || 'Confirm completion'}
          size='large'
          onClick={submit}
          disabled={!inspection || pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>


  );
}
