import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_SUBMIT_CABLE_INTERNET_TASK',
  request: (id) => request.post(`/residents/cable-internet-tasks/${id}/submit`,{})
    .then(r => r?.data?.data?.cable_internet_task),
  reducers: {
    success: (state, { response: cableInternetTask }) => ({
      ...state,
      cableInternetTasks: {
        ...state.cableInternetTasks,
        [cableInternetTask.id]: cableInternetTask,
      },
    }),
  }
};

export const {
  reducer,
  action: submit,
  usePending: useSubmitCableInternetTaskPending,
} = generate.redux(creator);
