import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { DynamicForm, AtomSpinner, Button } from '@moved/ui';
import { useNotify, fieldService, format } from '@moved/services';

import { OtherPets } from './';
import { Screen } from '../../shared';

export const HavePets = ({ screen, nextScreen, taskDefinition, taskDetails }) => {
  // HOOKS
  const { id } = useParams();
  const dispatch = useDispatch();
  const notify = useNotify();

  // REDUX
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const requestPending = taskDefinition.selectors.useCreatePetRequestPending();
  const updatePending = taskDefinition.selectors.useUpdatePetRegistrationPending();
  const loading = taskDefinition.selectors.usePetRegistrationPending();

  const pending = requestPending || updatePending;

  const fields = [
    {
      type: 'itemList',
      name: 'has_pets',
      label: 'Do you have any pets?',
      hiddenLabel: true,
      required: 'You must tell us if you have any pets',
      options: [
        {
          label: 'I have one or more animals to register',
          value: true,
          icon: { symbol: 'Check', library: 'navigation' },
        },
        {
          label: (<>No, I don&rsquo;t own any pets or service animals</>),
          value: false,
          icon: { symbol: 'Close', library: 'navigation' },
        },
      ]
    }
  ];
  fieldService.hydrate(fields,taskDetails);


  const handleSubmit = formData => {
    if(pending) return false;

    // Create pet helper
    const createPet = (request, resp) => {
      return request?.pets?.length === 0
        ? dispatch(taskDefinition.actions.createPet(request.id, {}))
        : new Promise((resolve) => resolve(resp));
    };

    return dispatch(taskDefinition.actions.update(id, formData))
      .then((resp) => {
        if (formData.has_pets === false) return resp;

        if (!activeRequest) {
          return dispatch(taskDefinition.actions.createRequest(id))
            .then(resp => {
              // create pet on newly created request
              const request = taskDefinition.helpers.getActiveRequest(resp);
              return createPet(request);
            })
            .then(() => dispatch(taskDefinition.actions.update(id, formData)))
            .then(nextScreen);
        }
        // normal screen submit
        else {
          return createPet(activeRequest)
            .then(nextScreen)
        }
      })
      .catch(error => notify.error(format.error(error)));
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <OtherPets taskDetails={taskDetails} />
        )}
      >
        { (!loading) ? (
          <DynamicForm
            id='pets-form'
            fields={fields}
            formStyle={'underline'}
            onSubmit={handleSubmit}
            disabled={pending}
          />
        ) : (
          <AtomSpinner />
        )}
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='pets-form'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
