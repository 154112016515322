import React from 'react';

import { CompleteCard } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { Screen, CompleteScreenActions } from '../../shared';
import { PaymentSummaryCards } from './PaymentSummaryCards';

import CSS from './styles/Payment.module.scss';

export const DepositOffline = ({ screen, taskDetails, taskDefinition, moveId, origin, onClose  }) => {
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className='stackHorizontal gap-40 stackVertical--mobile justify-center'>
          <div className={CSS.wrapper}>
            <CompleteCard title='Notice regarding payment' flavor={`${building.name} requires at least ${taskDetails?.submission_lead_time_in_days} days to process online payments`} detailsTitle={null}>
              <Snippet tag={'tasks.deposit.payment.offline_instructions'}>
                <p>
                  In order to ensure your security deposit is processed by your lease start date, you will need to contact the building site staff directly to facilitate payment prior to accessing your unit and any loading docks or elevators for your move in.
                </p>
              </Snippet>
            </CompleteCard>
          </div>
          <PaymentSummaryCards deposit={taskDetails} />
        </div>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  );
};
