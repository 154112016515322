import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { DynamicForm, AtomSpinner, Notebox, Button } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { Screen } from '../../shared';

const dateFormat = 'YYYY-MM-DD';

export const MoveDate = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const isTomorrowSelected = moment(selectedDate).isSame(moment().add(1,'d'),'day');
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const soldOutDates = taskDefinition.selectors.useSoldOutDates(moveId) || [];
  const soldOutDatesPending = taskDefinition.selectors.useSoldOutDatesPending();
  const isPreviousDateUnavailable = moverBooking?.requested_pickup_date && !taskDefinition.canBookDate(moverBooking?.requested_pickup_date);

  useEffect(() => {
    dispatch(taskDefinition.actions.getSoldOutDates(moveId))
      .then(() => setSelectedDate(getInitialValue()));
  },[]); // eslint-disable-line

  const isSoldOut = (day) => {
    return soldOutDates
      .filter(date => date.type === 'sold-out')
      .map(date => date.date)
      .includes(moment(day).format(dateFormat));
  };

  const isUnavailable = (day) => {
    return isSoldOut(day) || !taskDefinition.canBookDate(day);
  };

  const getInitialValue = () => {
    const suggestedDates = [
      moverBooking?.requested_pickup_date,
      ...(moverBooking?.suggested_pickup_dates ?? []).map(date => date.date),
      ...Array.from({length:14},(_,i) => moment().startOf('day').add(i,'d').format(dateFormat)),
    ].filter(v => v);
    return suggestedDates.find(date => !isUnavailable(date));
  };

  const fields = [
    {
      type: 'date',
      name: 'requested_pickup_date',
      value: getInitialValue(),
      required: 'Date is required.',
      onChange: (date) => setSelectedDate(date.format(dateFormat)),
      validate: (date) => {
        if(isUnavailable(date)) return `${moment(date).format('MMMM Do')} is no longer available for booking`;
      },
      calProps: {
        boxStyle: true,
        disablePast: true,
        isDayDisabled: isUnavailable,
      }
    },
  ];

  const submit = (data) => {
    setPending(true);
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, data))
      .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
      .then(nextScreen)
      .catch(err => {
        setPending(false);
        notify.error(format.error(err));
      });
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <>
            { isPreviousDateUnavailable && (
              <Notebox
                title='Availability Notice'
                body={(
                  <p>{ moment(moverBooking?.requested_pickup_date).format('MMMM Do') } is no longer available. Select a new date to continue.</p>
                )}
                color='orange'
                icon={{ library:'code', symbol:'Warning-2' }}
              />
            )}
            { soldOutDates.length > 0 && (
              <Notebox
                title='Availability Notice'
                body='Due to high demand, some dates are sold out and no longer available.'
                color='orange'
                icon={{ library:'code', symbol:'Warning-2' }}
              />
            )}
            { isTomorrowSelected && (
              <Notebox
                title='Availability Notice'
                body={(
                  <p>To book a move for tomorrow, we require booking <strong>before 2pm</strong> in order to provide adequate time to secure a moving company.</p>
                )}
                color='orange'
                icon={{ library:'code', symbol:'Warning-2' }}
              />
            )}
          </>
        )}
      >
        { soldOutDatesPending ? (
          <AtomSpinner />
        ) : (
          <DynamicForm
            id='date-form'
            fields={fields}
            formStyle={'underline'}
            onSubmit={submit}
            autocomplete={screen.autocomplete}
            disabled={pending}
          />
        )}
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='date-form'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
}
