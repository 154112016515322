import {
  OrientationScreens,
  Complete,
} from './components';

export const screens = {

  orientation: {
    component: OrientationScreens,
    maxWidth: 'none',
  },

  'summary': {
    slug: 'complete',
    label: "Summary",
    component: Complete,
    backRestricted: (data) => data?.requests?.[0]?.status === 'rejected',
    isCompleted: (taskable, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(taskable);
      return activeRequest?.status === 'approved';
    },
  },

};
