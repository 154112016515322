import React from 'react';
import classNames from 'classnames';

import { format } from '@moved/services';

import { Snippet } from '../../snippets/components/Snippet';

import mapIcon from './assets/map-icon.png';
import CSS from './styles/LeaseData.module.scss';

export const LeaseData = ({ activeMoveStep={} }) => {
  const { lease } = activeMoveStep;
  return (
    <div className={CSS.container}>
      <div className={CSS.lease_data}>
        <div className={CSS.address_block}>
          <a
            href={`https://www.google.com/maps/place/?q=place_id:${lease?.address?.google_place_id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className={CSS.lease_map} style={{backgroundImage:`url(${mapIcon})`}}></div>
          </a>
          <div className={CSS.lease_address}>
            <label className={CSS.label}>
              <Snippet
                tag='dashboard.lease-data.address.label'
                description='Label for the lease address'
              >
                Address
              </Snippet>
            </label>
            <div className={CSS.display_value}>{ format.address(lease?.address,{unit:false}) }</div>
          </div>
        </div>
        { lease?.address?.unit && (
          <div className={classNames(CSS.lease_attribute,CSS.unit)}>
            <label className={CSS.label}>
              <Snippet
                tag='dashboard.lease-data.unit.label'
                description='Label for the lease unit'
              >
                Unit
              </Snippet>
            </label>
            <div className={CSS.display_value}>{ lease.address.unit }</div>
          </div>
        )}
        { activeMoveStep?.date && (
          <div className={classNames(CSS.lease_attribute,CSS.date)}>
            <label className={CSS.label}>
              <Snippet
                tag={`dashboard.lease-data.${activeMoveStep?.type}.date.label`}
                description='Label for the lease date'
              >
                { activeMoveStep?.display_name }
              </Snippet>
            </label>
            <div className={CSS.display_value}>{ format.date(activeMoveStep.date) }</div>
          </div>
        )}
        { lease?.external_lease_number && (
          <div className={classNames(CSS.lease_attribute,CSS.number)}>
            <label className={CSS.label}>
              <Snippet
                tag='dashboard.lease-data.number.label'
                description='Label for the lease number'
              >
                Lease number
              </Snippet>
            </label>
            <div className={CSS.display_value}>{ lease?.external_lease_number }</div>
          </div>
        )}
      </div>
    </div>
  );
};
