import { Notebox } from '@moved/ui';

import CSS from './styles/InsuranceUpsellNotebox.module.scss';

export const InsuranceUpsellNotebox = () => (
  <Notebox
    color='helpful'
    icon={{ symbol: 'Sale#2', library: 'shopping', className: CSS.icon }}
    title='Discounts available on pet insurance!'
    body={(
      <>
        Lemonade, top-rated insurance, provides a hassle-free digital experience
        with lightning fast claims payment.<br/>
        <a className={CSS.link} href='https://movd.me/lpet-exp-feb2025' rel='noopener noreferrer' target='_blank'>
          Sign up in seconds
        </a>
      </>
    )}
  />
);
