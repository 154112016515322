import React from 'react';
import classNames from 'classnames';

import { format } from '@moved/services';
import { ScrollMenu, ScrollMenuItem } from '@moved/ui';

import CSS from './Picker.module.scss';

export const DayPicker = ({ days=[], onSelect, className, scrollToLastDate = false }) => {
  const activeDayIndex = (days.findIndex(day => day.isSelected));
  const activeDate = (days[activeDayIndex] || {}).availability;
  const scrollMountItem = (activeDayIndex >= 0)
    ? (scrollToLastDate
      ? days[activeDayIndex+1] || days[activeDayIndex]
      : days[activeDayIndex-1] || days[activeDayIndex]
    )
    : { id: false};
  return (
    <div className={classNames(CSS.holder,CSS.day_picker,className)}>
      <div className={CSS.header}>
        <span>
          Pick a date
        </span>
        { (activeDate && activeDate.date) && (
          <span className={CSS.chosen_header}>{format.date(activeDate.date)}</span>
        )}
      </div>
      { !(Array.isArray(days) && days.length) ? (
        <p><em>We apologize, there are no available dates at this moment.</em></p>
      ) : (
        <ScrollMenu scrollOnMount={scrollMountItem.id} separatorClassName={CSS.separator}>
          { days.map((day, index) => (
            <ScrollMenuItem
              key={day.id}
              itemId={day.id}
              onSelect={() => onSelect(day)}
              isDisabled={day.isDisabled}
              isSelected={day.isSelected}
              className={CSS.item_wrapper}
            >
              { day.content }
            </ScrollMenuItem>
          ))}
        </ScrollMenu>
      )}
    </div>
  )
}
