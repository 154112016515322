import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useNotify, format } from '@moved/services';
import { Button, Form, FormTextarea, FormRadioList, FormToggle } from '@moved/ui';

import { createFaq } from '../actions/createFaq';

import { useAssistant } from './AssistantWidget';

const validationSchema = Yup.object().shape({
  question: Yup.string().required('Required'),
  answer: Yup.string().required('Required'),
  scope: Yup.string().required('Required'),
  route_to_property: Yup.boolean(),
});

export const CreateFaqForm = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const {
    building,
    topic,
    switchPanel,
    refreshFaqs,
  } = useAssistant();

  const [pending, setPending] = useState(false);

  const create = ({ scope, ...values }) => {
    setPending(true);
    dispatch(createFaq({
      ...values,
      faq_topic_id: topic?.id,
      partner_id: ['building','partner'].includes(scope) ? building?.partner?.id : null,
      building_id: scope === 'building' ? building?.id : null,
    }))
      .then(() => {
        refreshFaqs();
        notify.success('Q&A added successfully');
        switchPanel('faq');
      })
      .catch((err) => notify.error(format.error(err)))
      .finally(() => setPending(false));
  };

  return (
    <>
      <Form
        name='addFaq'
        onSubmit={create}
        className='stackVertical gap-24'
        validation={validationSchema}
      >
        <FormTextarea
          name='question'
          label='Question'
          autoResize
        />
        <FormTextarea
          name='answer'
          label='Answer'
          autoResize
        />
        <FormToggle
          name='route_to_property'
          label='Route to property'
          value={false}
        />
        <div className='stackVertical gap-12'>
          <div className='labelL contentPrimary'>Apply Q&A to</div>
          <FormRadioList
            name='scope'
            value='global'
            options={[
              { label: 'Global', description: 'All properties', value: 'global' },
              { label: 'Client', description: building?.partner?.name, value: 'partner' },
              { label: 'Property', description: building?.name, value: 'building' },
            ]}
          />
        </div>
      </Form>

      <div className='flex-grow stackHorizontal justify-end items-end'>
        <Button
          form='addFaq'
          text='Add Q&A'
          disabled={pending}
        />
      </div>

    </>
  );
};
