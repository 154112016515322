import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useUser, format, useModal, useNotify } from '@moved/services';
import { CreditCardLogo, CompleteCard, Accordion, Notebox, FieldList, Button } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { getMove } from '../../../../moves/actions';
import { Screen, CompleteScreenActions } from '../../shared';

import { BookBreakdown } from './BookBreakdown';
import { SummaryFields } from './SummaryFields';
import { ResolvePaymentIssueModal } from './ResolvePaymentIssueModal';

import CSS from './styles/ConfirmBooked.module.scss';

export const ConfirmBooked = ({ screen, taskDetails, taskDefinition, moveId, origin, goToScreen, onClose }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const { user } = useUser();
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const bookedServiceOrder = moverBooking?.booked_service_order;
  const orderStatus = bookedServiceOrder?.order_status;
  const paymentStatus = bookedServiceOrder?.payment_status;
  const [pending, setPending] = useState(false);
  let needsUpdatedCard = false;

  const resolvePaymentIssue = () => {
    modal.open(<ResolvePaymentIssueModal order={bookedServiceOrder}/>);
  };

  let statusCardDetails = {};

  switch(orderStatus) {
    case 'cancelled':
      statusCardDetails = {
        status: 'cancelled',
        icon: { symbol: 'Sad', color: 'red'},
        title: 'Order canceled',
        flavor: (
          <Snippet tag={'tasks.book-movers.summary.moved.cancelled'}>
            Your previously placed order for professional movers has been canceled.<br/>
            All holds have been released on your card minus applicable cancellation fees.
          </Snippet>
        ),
      };
      break;
    case 'completed':
    default:
      switch(paymentStatus) {
        case 'pre_authorization_failed':
          needsUpdatedCard = true;
          statusCardDetails = {
            color: 'red',
            icon: { symbol: 'Warning#2', color: 'red'},
            title: 'Update payment method',
            flavor: (
              <Snippet tag={'tasks.book-movers.summary.moved.pre-auth-failed'}>
                Your card was declined when attempting to authorize your payment.<br/>
                You will need to update your card on file to ensure your booking can proceed.
              </Snippet>
            ),
          };
          break;
        case 'payment_issue':
          needsUpdatedCard = true;
          statusCardDetails = {
            color: 'red',
            icon: { symbol: 'Warning#2', color: 'red'},
            title: 'Update payment method',
            flavor: (
              <Snippet tag={'tasks.book-movers.summary.moved.payment-issue'}>
                When attempting to capture the final payment your card was declined.<br/>
                Please update your card below to complete your transaction.
              </Snippet>
            ),
          };
          break;
        case 'paid':
          statusCardDetails = {
            color: 'green',
            icon: { symbol: 'Done', color: 'green'},
            title: 'Your move is complete!',
            flavor: (
              <Snippet tag={'tasks.book-movers.summary.moved'}>
                We sent a receipt of your move details to {user.email}.<br/>
                If you're moving again you can
                {' '}<Link to='/moves'>start a new move</Link>{' '}
                anytime.
              </Snippet>
            ),
          };
          break;
        case 'no_payment_method':
        case 'valid_payment_method':
        case 'authorized':
        default:
          statusCardDetails = {
            color: 'green',
            icon: { symbol: 'Sending', color: 'green'},
            title: 'Great, your order has been placed!',
            flavor: (
              <Snippet tag={'tasks.book-movers.summary.moved'}>
                We sent a confirmation of your move details to {user.email}.<br/>
                You will be introduced to your movers soon!
              </Snippet>
            ),
          };
          break;
      }
      break;
  }

  const changeSelection = () => {
    if(pending) return;
    setPending(true);
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, {
      requested_pickup_date: null,
      stops: [],
      contact_email: null,
    }))
      // then reset the task (only if not direct app)
      .then(r => taskDefinition.actions.reset ? dispatch(taskDefinition.actions.reset(taskDetails.id)) : r)
      // refresh the move because reset affects MBT and MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then(r => goToScreen(taskDefinition.getStartOverScreen(r)))
      .catch(err => notify.error(format.error(err)));
  };

  const transactionFields = [
    {
      icon: { symbol: 'Truck', library: 'home' },
      label: 'Moving company',
      value: bookedServiceOrder?.vendor?.name,
    },
    {
      icon: { symbol: 'Check', library: 'navigation' },
      label: 'Confirmation number',
      value: `#${bookedServiceOrder?.confirmation_number}`,
    },
    {
      icon: { symbol: 'Date', library: 'code' },
      label: 'Booking date',
      value: format.date(bookedServiceOrder?.purchase_date_time,'dateTime'),
    },
    {
      icon: needsUpdatedCard ? { symbol: 'Warning#2', color: 'red' } : { symbol: 'Credit-card', library: 'shopping' },
      label: needsUpdatedCard ? (<span className='red'>Payment method</span>) : 'Payment method',
      value: (
        <>
          <CreditCardLogo symbol={bookedServiceOrder?.payment_information?.data?.card_type} />
          <span className={'ml-10'}>
            {format.capitalize(bookedServiceOrder?.payment_information?.data?.card_type)} ending in {bookedServiceOrder?.payment_information?.data?.card_last4}
          </span>
          { needsUpdatedCard && (
            <span className='ml-15 btn-primary btn--small' onClick={resolvePaymentIssue}>Update</span>
          )}
        </>
      ),
    },
    orderStatus === 'cancelled' && {
      value: (
        <Button
          text='Change selections'
          size='small'
          color='secondary'
          disabled={pending}
          onClick={changeSelection}
        />
      )
    }
  ].filter(v=>v&&v.value);

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className={CSS.content}>
          <div className={CSS.confirmation_content}>

            <CompleteCard {...statusCardDetails} detailsTitle={null}>
              { !['completed','cancelled'].includes(orderStatus) && (
                <Notebox
                  icon={{ symbol:'Info-circle', library:'code' }}
                  title='Please note'
                  body={(<>
                    Once introduced to your movers, you'll need to confirm a
                    few details, <strong>including the detailed inventory list</strong>,
                    best contact phone number, and a few other items.
                  </>)}
                />
              )}
              <FieldList list={transactionFields} />
            </CompleteCard>

            <div className={CSS.summary}>
              <Accordion
                label='Move summary'
                content={(
                  <div className={CSS.summary_fields}>
                    <SummaryFields moverBooking={moverBooking} />
                  </div>
                )}
              />
            </div>

          </div>

          <BookBreakdown
            moverBooking={moverBooking}
            discounts={bookedServiceOrder?.discounts}
            showCancellationPolicy={true}
            showInsuranceDisclaimer={true}
          />

        </div>

      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  );
}
