import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ASSISTANT_GET_FAQS',
  request: (moveStepId, address) => request.get(`/residents/move-steps/${moveStepId}/faq-questions`, { params: { screen_address: address } })
    .then(r => r.data),
  selector: (state, ...params) => state.faqs[generate.uniqueKey(...params)],
  reducers: {
    success: (state, { response, params }) => ({
      ...state,
      faqs: {
        ...state.faqs,
        [generate.uniqueKey(...params)]: {
          questions: response.data.faq_questions,
          topic: response.data.faq_topic,
          pagination: response.pagination,
        },
      },
    }),
    failure: (state, { params }) => ({
      ...state,
      faqs: {
        ...state.faqs,
        [generate.uniqueKey(...params)]: null,
      },
    }),
  }

};

export const {
  reducer,
  action: getFaqs,
  useResource: useFaqs,
  usePending: useFaqsPending,
} = generate.redux(creator);

export const initialState = {
  faqs: {},
};
