import { withPersonalTaskList } from './contexts/PersonalTaskListContextProvider';
import { Dashboard } from './components/Dashboard';
import { TaskDetails } from './components/TaskDetails';
import { withAssistant } from '../assistant';

const routes = [
  {
    path: '/personal-tasks',
    name: 'PersonalTasksDashboard',
    component: withPersonalTaskList(withAssistant(Dashboard)),
    viewKey: () => 'personal-tasks-dashboard',
    address: 'personal-tasks',
    transition: 'crossFade',
    children: [
      {
        path: '/:taskId(\\d+)',
        name: 'PersonalTaskDetails',
        component: TaskDetails,
        viewKey: () => 'personal-tasks-dashboard',
        exact: true,
      },
    ],
  },
];

export default routes;
