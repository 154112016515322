import { withAssistant } from '../assistant';
import { withMoveStep } from '../dashboard/contexts/MoveStepContext';
import { BuildingMoveTaskContextProvider } from './contexts/BuildingMoveTaskContext';
import { BuildingMoveTaskFlow } from './components/BuildingMoveTaskFlow';
import { TaskRedirect } from './components/TaskRedirect';
import { TaskSummary } from './components/TaskSummary';

import { taskDefinitions } from './types';

const taskTypes = Object.keys(taskDefinitions).map(type => (new taskDefinitions[type]()));

const routes = [
  {
    path: `/steps/:stepId(\\d+)/tasks/:taskId(\\d+)`,
    name: 'moveTask',
    address: 'tasks',
    viewKey: ({taskId}) => `task-${taskId}`,
    transition: 'scale',
    transitions: {
      in: 'scale',
      out: 'scaleOut',
    },
    component: withMoveStep(withAssistant(BuildingMoveTaskContextProvider)),
    children: [
      {
        path: `/`,
        name: 'TaskRedirect',
        component: TaskRedirect,
        exact: true,
      },
      ...taskTypes.map(({slug, screens, routes=[]}) => ({
        path: `/:taskType(${slug})`,
        name: 'taskType',
        address: slug,
        viewKey: ({taskType}) => `task-${taskType}`,
        children: [
          {
            path: `/`,
            name: 'TaskRedirect',
            component: TaskRedirect,
            exact: true,
          },
          {
            path: `/summary`,
            name: 'TaskSummary',
            component: TaskSummary,
            exact: true,
          },
          {
            path: `/:id(\\d+)`,
            name: 'taskDetails',
            viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
            children: [
              {
                path: `/`,
                name: 'taskFlowRedirect',
                component: BuildingMoveTaskFlow,
                viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
                exact: true,
              },
              // extend with custom routes per task type (defaults to [])
              ...routes,
              // add the default screen routes pre-powered by the static screens for each task type
              {
                path: `/:screenSlug(${Object.values(screens).filter(screen => !screen.requiresContext).map(screen => screen.slug).filter((v,i,arr) => v && arr.indexOf(v) === i).join('|')})`,
                name: 'taskFlowScreen',
                address: '{screenSlug}',
                component: BuildingMoveTaskFlow,
                viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
                exact: true,
              },
              // TODO: this variation works to expose every screen slug as a route, but it then breaks the transition from screen to screen
              // ...Object.values(screens).filter(screen => !screen.requiresContext && screen.slug).map(screen => ({
              //   path: `/:screenSlug(${screen.slug})`,
              //   name: 'taskFlowScreen',
              //   address: screen.slug,
              //   component: BuildingMoveTaskFlow,
              //   viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
              //   exact: true,
              // })),
            ]
          },
        ]
      }))
    ],
  },

];

export default routes;
