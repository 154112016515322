import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CREATE_CHOICE_TASK',
  request: (taskId) => request.post(`/residents/building-move-tasks/${taskId}/choice-tasks`,{})
    .then(r => r.data.data.choice_task),
  reducers: {
    success: (state, { response: choiceTask }) => ({
      ...state,
      choiceTasks: {
        ...state.choiceTasks,
        [choiceTask.id]: choiceTask,
      },
    }),
  }
};

export const {
  reducer,
  action: create,
  usePending: useCreateChoiceTaskPending,
} = generate.redux(creator);

export const initialState = {
  choiceTasks: {},
}
