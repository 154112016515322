import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_BOOK_MOVERS_GET_UHAUL_AD_LINK',
  request: (moveId, adId, data={}) => request.post(`/residents/moves/${moveId}/uhaul-product-ads/${adId}/link`,data).then(r => r?.data?.data?.uhaul_product_ad_link?.url),
};

export const {
  reducer,
  action: getUhaulAdLink,
  usePending: useUhaulAdLinkPending,
} = generate.redux(creator);
