import React from 'react';
import { useDispatch } from 'react-redux';

import { DynamicForm, Button } from '@moved/ui';
import { useNotify, useUser, format } from '@moved/services';

import { Screen } from '../../shared';
import { QuoteTicket } from '../../book-movers/components/QuoteTicket';

import CSS from './styles/LongDistance.module.scss';

// Render quotes screen
export const LongDistance = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const notify = useNotify();
  const { user } = useUser();
  const dispatch = useDispatch();
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const updateTaskPending = taskDefinition.selectors.useUpdateMoverBookingTaskPending();
  const completeTaskPending = taskDefinition.selectors.useCompleteMoverBookingTaskPending();
  const pending = updateTaskPending || completeTaskPending;

  //Submit form function
  const submit = ({ request_quotes, contact_email }) => {
    if(pending) return;
    const request = request_quotes ?
      dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, { contact_email }))
        .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
        .then(r => dispatch(taskDefinition.actions.longDistanceEmail(moverBooking.id)).then(() => r))  :
      dispatch(taskDefinition.actions.complete(taskDetails.id));
    request
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  const fields = [
    {
      name: 'request_quotes',
      type: 'itemList',
      value: true,
      required: 'You must make a selection',
      className: CSS.item_list,
      onChange: (option, setFieldValue) => {
        // restore value of email if it was removed
        if(option.value === true) setFieldValue('contact_email', moverBooking?.contact_email ?? user?.email);
      },
      options: [
        {
          label: 'Yes, contact me with quotes',
          value: true,
          icon: { symbol: 'Mail', library: 'communication' },
          large: true,
        },
        {
          label: 'No thanks',
          value: false,
          icon: { symbol: 'Stop', library: 'code' },
          large: true,
        },
      ],
    },
    {
      label: 'Confirm your email address is correct.',
      type: 'title',
      custom: {
        hidden: {
          compare: { field: 'request_quotes' },
          boolean: 'not',
          value: true,
        },
      },
    },
    {
      name: 'contact_email',
      label: 'Email address',
      value: moverBooking?.contact_email ?? user?.email,
      type: 'email',
      required: true,
      className: CSS.preference_field,
      custom: {
        hidden: {
          compare: { field: 'request_quotes' },
          boolean: 'not',
          value: true,
        },
      },
    },
  ];

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>

        <div className='stackHorizontal gap-40 stackVertical--mobile items-start'>

          <div className={CSS.form}>
            <h5 className={CSS.title}>Choose an option</h5>
            <DynamicForm id='long-distance-form'
              fields={fields}
              formStyle={'underline'}
              onSubmit={submit}
              disabled={pending}
            />
          </div>

          <QuoteTicket taskDefinition={taskDefinition} moveId={moveId} />

        </div>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='long-distance-form'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
}
