import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ASSISTANT_DELETE_FAQ_ANSWER',
  request: (id,data={}) => request.delete(`/residents/faq-answers/${id}`, { asSuperUser: true, data })
    .then(r => r?.data),
};

export const {
  reducer,
  action: deleteFaqAnswer,
  usePending: useDeleteFaqAnswerPending,
} = generate.redux(creator);
