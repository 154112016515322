import React from 'react';
import { useDispatch } from 'react-redux';

import { globals } from '@moved/services';
import { useUser, useNotify, format } from '@moved/services';
import { Notebox } from '@moved/ui';
import { AuthenticationFormWrapper, AuthenticationForm } from '@moved/product';

import { registerDirect } from '../actions';
import { useRegisterDirectPending } from '../actions/selectors';

export const DirectSignupForm = ({ onComplete, partner, ...props }) => {
  const dispatch = useDispatch();

  const { updateUser } = useUser();
  const notify = useNotify();

  const pending = useRegisterDirectPending();

  const authenticationFields = [
    { label: 'First name', type: 'text', name: 'firstname', value: '', required: true, half: true },
    { label: 'Last name', type: 'text', name: 'lastname', value: '', required: true, half: true },
    { label: 'Email', type: 'email', name: 'email', value: '', required: true },
    { label: 'Create password', type: 'password', name: 'password', value: '', required: 'Password is required' },
    partner && { name: 'partner_guid', type:'hidden', value: partner?.guid },
  ].filter(v=>v);

  const authenticate = ({ email, ...data }) => {
    if(pending) return;
    return dispatch(registerDirect({
      email: email.toLowerCase(),
      ...data,
    }))
      .then((response) => {
        const { user, ...tokenData } = response?.login_response;
        updateUser(user, tokenData);
        if(onComplete) onComplete({
          move_id: response?.user?.moves[0]?.id,
          login_response: response?.login_response,
        });
      })
      .catch(err => {
        notify.error(format.error(err));
      });
  };

  return (
    <AuthenticationFormWrapper
      display={'signup'}
      pending={pending}
      {...props}
    >
      { !partner && (
        <Notebox
          body={(
            `Did you just sign a lease and your property sent you here? Chances are you need to register with a special link sent via email. Check your inbox for a message from ${globals?.emails?.residentSupport}, or email us for further instructions!`
          )}
          color='blue'
          icon={{ symbol: 'Info' }}
          className='marginBottom-24'
        />
      )}
      <AuthenticationForm
        fields={authenticationFields}
        authenticate={authenticate}
        pending={pending}
      />
    </AuthenticationFormWrapper>
  );

};
