import * as create from './create';
import * as createRequest from './createRequest';
import * as get from './get';
import * as getQuestionDetails from './getQuestionDetails';
import * as submit from './submit';
import * as updateRequest from './updateRequest';

export const reducers = [
  create.reducer,
  createRequest.reducer,
  get.reducer,
  getQuestionDetails.reducer,
  submit.reducer,
  updateRequest.reducer,
];

export const initialState = {
  ...create.initialState,
  ...createRequest.initialState,
  ...get.initialState,
  ...getQuestionDetails.initialState,
  ...submit.initialState,
  ...updateRequest.initialState,
};
