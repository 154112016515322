import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { DynamicForm, Notebox, Button } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { Screen } from '../../shared';

const EpremiumListener = () => {
  const { setFieldValue, submitForm } = useFormikContext();
  useEffect(() => {
    const ePremiumListenerHandler = (event) => {
      if(event.origin.includes('epremiuminsurance.com')) {
        setFieldValue('policy_number', event.data);
        submitForm();
      }
    };
    window.addEventListener('message', ePremiumListenerHandler);
    return () => window.removeEventListener('message', ePremiumListenerHandler);
  }, [setFieldValue, submitForm]);
  return null;
};

export const VerifyEpremium = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const taskDetails = taskDefinition.selectors.useRenterInsurance(id);
  const pending = taskDefinition.selectors.useUpdateRenterInsuranceRequestPending();

  const selectedPurchaseOption = taskDetails?.selected_purchase_option ?? {};
  const { defaultProvider } = taskDefinition.getPurchaseOptionFlowImplications(selectedPurchaseOption);
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);

  const _handleSubmit = (data) => {
    if(pending) return;
    dispatch(taskDefinition.actions.verifyEpremiumPolicy(activeRequest.id, data))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  const handleProviderRedirect = () => {
    return dispatch(taskDefinition.actions.getProviderLink(id, defaultProvider?.id))
      .then(link => window.open(decodeURIComponent(link)));
  };

  const fields = [
    {
      label: 'Policy number',
      type: 'text',
      name: 'policy_number',
      value: activeRequest?.policy_number,
      required: true,
    },
    {
      type: 'content',
      name: 'epremium_listener',
      content: (<EpremiumListener />),
    },
  ];

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <Notebox
            style={{maxWidth:'660px'}}
            title='Still need to purchase?'
            body={`Shop for an ePremium policy and satisfy renters insurance requirements`}
            color="blue"
            icon={{ library: 'code', symbol: 'Info-circle' }}
            actions={(
              <Button text={defaultProvider?.cta ?? 'Enroll now'} onClick={handleProviderRedirect} />
            )}
          />
        )}
      >
        <DynamicForm
          id='verify-form'
          fields={fields}
          formStyle={'underline'}
          onSubmit={_handleSubmit}
          disabled={pending}
        />
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='verify-form'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>

  );
}
