import React from 'react';

import { useMobile } from '@moved/services';

import { Sidebar } from './Sidebar';
import { MobileHeader } from './MobileHeader';

import CSS from './styles/LayoutSidebar.module.scss';

export const LayoutSidebar  = ({ children }) => {
  const isMobile = useMobile();

  return (
    <div className='stackHorizontal height-full stackVertical--mobile'>
      { isMobile ? (<MobileHeader />) : (<Sidebar />)}
      <div className={CSS.content}>
        { children }
      </div>
    </div>
  );
};
