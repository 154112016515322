import classNames from 'classnames';

import { Icon } from '@moved/ui';

import CSS from './styles/AssistantLauncher.module.scss';

export const AssistantLauncher = ({ isOpen, onClick }) => (
    <div
      className={classNames(CSS.launcher,{ [CSS.open]: isOpen })}
      onClick={onClick}
    >
      <Icon
        symbol='Question'
        library='code'
        color='white'
        className={CSS.openIcon}
      />
      <Icon
        symbol='Close'
        library='navigation'
        color='black'
        className={CSS.closeIcon}
      />
    </div>
);
