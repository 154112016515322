import React from 'react';
import { useHistory } from 'react-router-dom'

import { CompleteCard, FieldList, Button } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import {
  Screen,
  CompleteScreenActions
} from '../../shared';

export const Complete = ({ screen, taskDefinition, taskDetails, origin, taskBaseRoute, moveId, onClose }) => {
  const history = useHistory();
  const attributes = [
    ...taskDefinition.helpers.getSummaryFields(taskDetails),
    {
      value: (
        <Button
          text='Change selection'
          color='secondary'
          size='small'
          onClick={() => history.push(`${taskBaseRoute}${taskDefinition.getStartOverRoute(taskDetails)}`)}
        />
      ),
    },
  ];

  const flavor = (<Snippet tag={'tasks.addon-interests.summary'}></Snippet>);

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <CompleteCard status={taskDetails.status || 'approved'} flavor={flavor}>
          <FieldList list={attributes}/>
        </CompleteCard>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  );

};
