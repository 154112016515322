import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';

import { useNotify, useUser, format } from '@moved/services';
import { DynamicForm, EmptyContent, Button } from '@moved/ui';

import { Screen } from '../../shared';
import { ProviderName, ProviderDescription, ProviderLinks } from './ProviderDetails';

export const SelectProvider = ({ screen, nextScreen, taskDefinition }) => {
  const { user } = useUser();
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const utilitySetup = taskDefinition.selectors.useUtilitySetup(id);
  const pending = taskDefinition.selectors.useUtilitySetupPending();

  const { utility_providers: providers } = utilitySetup;

  const activeRequest = taskDefinition.helpers.getActiveRequest(utilitySetup);
  const providerID = activeRequest?.utility_provider?.id;

  const activeRequestIsMyRequest = activeRequest?.submitted_by?.user_id === user.id;

  const fields = [
    {
      name: 'utility_provider_id',
      type: 'itemList',
      options: providers.map(provider => ({
        value: provider.id,
        label: <ProviderName provider={provider} />,
        sublabel: <ProviderDescription provider={provider} />,
        rightLabel: <ProviderLinks provider={provider} />,
      })),
      value: providerID ? providerID : (providers.length === 1 ? providers[0].id : null),
      required: `You must choose one of these ${utilitySetup.utility_category.toLowerCase()} providers`,
    },
  ];

  const _handleNext = data => {
    if(pending) return false;
    // Create request if none exists or draft request belongs to roommate
    const createOrUpdateRequest = (!activeRequest || !activeRequestIsMyRequest) ?
      dispatch(taskDefinition.actions.createRequest(id,data)) :
      dispatch(taskDefinition.actions.updateRequest(activeRequest.id, data));
    createOrUpdateRequest
      .then(() => nextScreen())
      .catch(error => notify.error(format.error(error)));
  };

  let localScreen = cloneDeep(screen);

  localScreen.title = `Set up your ${utilitySetup?.utility_category?.toLowerCase()} provider`

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        { providers.length > 0 ? (
          <DynamicForm id='utility-setup-form'
            fields={fields}
            formStyle={'underline'}
            onSubmit={_handleNext}
            disabled={pending ? true : false}
          />
        ) : (
          <EmptyContent message={(
            <>
              <b>No providers have been configured for this property.</b><br />
              Use the contact form in the bottom right corner to let us know.
            </>
          )}/>
        )}
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='utility-setup-form'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  )
};
