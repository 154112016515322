import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { CompleteCard, FieldList } from '@moved/ui';
import { movingPlan, bookMovers } from '@moved/product';

import { getMove } from '../../../../moves/actions';
import { Snippet } from '../../../../snippets';
import {
  Screen,
  CompleteScreenActions
} from '../../shared';

export const ConfirmUserProvided = ({ screen, taskDetails, taskDefinition, moveId, origin, goToScreen, onClose }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const moverBookingTask = taskDetails;
  const moverBooking = taskDefinition.getMoverBooking(moverBookingTask);
  const resetPending = taskDefinition.selectors.useResetMoverBookingTaskPending();

  const selectedMovingCompanyAds = moverBookingTask?.selected_moving_company_ads ?? [];

  const changeSelection = () => {
    dispatch(taskDefinition.actions.reset(moverBookingTask.id))
      // refresh the move because reset affects MBT and MT on the move
      .then(r => dispatch(getMove(moveId)).then(() => r))
      .then(r => goToScreen(taskDefinition.getStartOverScreen(r)))
      .catch(err => notify.error(format.error(err)));
  };

  const fields = [
    ...movingPlan.getSummaryFields(moverBookingTask),
    ...bookMovers.getSummaryFields(moverBooking),
    {
      value: (
        <div
          className={classNames('btn-gray btn--small',{'loading':resetPending})}
          disabled={resetPending}
          onClick={changeSelection}
        >
          Change selection
        </div>
      )
    },
  ];

  const flavor = selectedMovingCompanyAds.length ?
    <Snippet tag={'tasks.book-movers.summary.user-provided'}>You will be introduced to any companies you selected via email.</Snippet> :
    null;

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <CompleteCard status={'approved'} flavor={flavor}>
          <FieldList list={fields} />
        </CompleteCard>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  )
};
