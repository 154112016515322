import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams,useHistory } from "react-router-dom";
import classNames from 'classnames';

import { Icon, PopOver, ConfirmationModal, AtomSpinner, Button } from '@moved/ui';
import { useNotify, format, useModal } from '@moved/services';

import { Screen } from '../../shared';
import { OtherPets } from './OtherPets';
import { InsuranceUpsellNotebox } from './InsuranceUpsellNotebox';
import CSS from './styles/PetsSummary.module.scss';

const PetCard = ({ pet, activeRequest, taskDefinition, taskBaseRoute }) => {
  const history = useHistory();
  const { id } = useParams();
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();

  // Remove pet details handler
  const _handleDeletePetDetails = (pet) => {
    dispatch(taskDefinition.actions.cancelPetDetails(activeRequest.id, pet.id))
      .then(() => notify.default(`Details for ${pet.name} have been removed.`))
      .catch(error => notify.error(format.error(error)));
  }

  // remove pet details confirmation modal
  const _openRemoveModal = (petDetails, pets) => modal.open(
    <ConfirmationModal
      title={`Cancel registration for ${petDetails.name}`}
      copy={(
        <>
          Are you sure you wish to delete all details for <strong>{petDetails.name}</strong> and withdraw {petDetails.gender ? (petDetails.gender === 'female'? 'her': 'his' ) : 'their'} registration?
          This action cannot be undone.
        </>
      )}
      confirmText='Confirm'
      cancelText='Never mind'
      onConfirm={() => _handleDeletePetDetails(pet, pets)}
    />
  );

  return (
    <div className={CSS.card}>
      <div className={CSS.popover}>
        <PopOver
          id={`more_${pet.id}`}
          customClass={CSS.pet_popover}
          className={classNames(CSS.icon,CSS.icon_more)}
          hideArrow="true"
          placement="bottom-end"
          trigger="click"
          delayHide={400}
          tooltip={(
            <div id={'popover_more_options'}>
              <div
                className={CSS.popover_action}
                onClick={e => {
                  e.preventDefault();
                  history.push(`${taskBaseRoute}/${id}/type/${pet.id}`);
                }}
              >
                <Icon symbol={'Edit'} library={'general'} size={'20px'} className={CSS.popover_icon} />
                <span className={CSS.popover_link}>Edit</span>
              </div>
              <div className={classNames(CSS.popover_action, CSS.delete)} onClick={e => _openRemoveModal(pet, activeRequest.pets)}>
                <Icon symbol={'Close'} library={'navigation'} size={'20px'} className={CSS.popover_icon} />
                <span className={CSS.popover_link}>Cancel registration</span>
              </div>
            </div>
          )}
          stopPropagation
        >
          {() => (
            <Icon symbol='Other#2' library='general' className={CSS.more_icon} />
          )}
        </PopOver>
      </div>
      <div className={CSS.circle} />
      <h3 className={CSS.name}>{pet.name}</h3>
      <p className={CSS.breed}>{pet.breed}</p>
      {pet.photo_url
        ? (<div className={CSS.photo} style={{backgroundImage:`url(${pet.photo_url})`}} />)
        : (
          <div className={CSS.pet_icon}>
            <div className={CSS.spacer} />
            <Icon
              symbol={format.capitalize(pet.pet_type)}
              library={'animals'}
              size={'65%'}
            />
          </div>
        )}
    </div>
  );
};


export const PetsSummary = ({ screen, nextScreen, taskBaseRoute, taskDefinition, taskDetails }) => {
  // HOOKS
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const notify = useNotify();

  // REDUX
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const updatePending = taskDefinition.selectors.usePetRegistrationPending();
  const requestPending = taskDefinition.selectors.useUpdatePetRequestPending();
  const loading = taskDefinition.selectors.usePetRegistrationPending();

  // STATE
  const pending = updatePending || requestPending;

  const addPet = () => {
    dispatch(taskDefinition.actions.createPet(activeRequest?.id, {}))
      .then(() => history.push(`${taskBaseRoute}/${id}`))
      .catch(error => notify.error(format.error(error)));
  };

  const declinePets = e => {
    dispatch(taskDefinition.actions.update(id, { 'has_pets': false }))
      .then(nextScreen)
      .catch(error => notify.error(format.error(error)));
  };

  const handleSubmit = e => {
    dispatch(taskDefinition.actions.submitRequest(activeRequest.id))
      .then(nextScreen)
      .catch(error => notify.error(format.error(error)));
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <>
            <OtherPets taskDetails={taskDetails} />
            <InsuranceUpsellNotebox/>
          </>
        )}
      >
        {loading ? (
          <AtomSpinner />
        ) : (
          <div className={CSS.card_holder}>
            {(activeRequest?.pets ?? []).map(pet => (
              <PetCard
                key={pet.id}
                pet={pet}
                taskDefinition={taskDefinition}
                taskBaseRoute={taskBaseRoute}
                activeRequest={activeRequest}
              />
            ))}
            <div className={CSS.add} onClick={addPet}>
              <div className={CSS.add_button}>
                <Icon symbol={'Plus'} library={'navigation'} size={'24px'} className={CSS.add_icon} />
              </div>
              <div className={CSS.add_text}>Add another pet or service animal</div>
            </div>
          </div>
        )}
      </Screen.Content>
      <Screen.Actions>
        { activeRequest.pets.length > 0 ? (
          <Button
            text='Submit'
            size='large'
            onClick={handleSubmit}
            disabled={pending}
            className='width-full'
          />
        ) : (
          <Button
            text='No additional pets'
            size='large'
            color='secondary'
            onClick={declinePets}
            disabled={pending}
            className='width-full'
          />
        )}
      </Screen.Actions>
    </Screen>
  );
};
