import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { format } from '@moved/services';

import CSS from './DayPickerItem.module.scss';

const dayStrings = {
  lastDay : '[Yesterday]',
  sameDay : '[Today]',
  nextDay : '[Tomorrow]',
  lastWeek : 'dddd',
  nextWeek : 'dddd',
  sameElse : 'dddd'
};

export const DayPickerItem = ({ day, className }) => {
  return (
    <div className={classNames(CSS.wrapper,className)}>
      <div className={CSS.day}>
        <span className={CSS.day_title}>
          { moment(day.date).calendar(dayStrings) }
        </span>
        <span className={CSS.day_number}>
          { format.date(day.date,'DD') }
        </span>
        { day.is_available === false && (
          <div className={CSS.day_unavailable}>Unavailable</div>
        ) }
      </div>
      <span className={CSS.day_month}>
        { format.date(day.date, 'MMM') }
      </span>
    </div>
  );
};
