import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { DynamicForm, AtomSpinner, Button } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { Screen } from '../../shared';
import { OtherPets } from './OtherPets';
import { InsuranceUpsellNotebox } from './InsuranceUpsellNotebox';

export const PetType = ({ screen, nextScreen, taskDefinition, taskDetails }) => {
  const { screenContext } = useParams();
  const dispatch = useDispatch();
  const notify = useNotify();
  const petTypes = taskDefinition.selectors.usePetTypes();

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const activePet = taskDefinition.getActivePet(taskDetails, screenContext);
  const createPending = taskDefinition.selectors.useCreatePetPending();
  const petPending = taskDefinition.selectors.useUpdatePetPending();
  const loading = taskDefinition.selectors.usePetRegistrationPending();
  const typesPending = taskDefinition.selectors.usePetTypesPending();

  const pending = createPending || petPending;

  useEffect(() => {
    dispatch(taskDefinition.actions.getPetTypes())
      .catch(error => notify.error(format.error(error)));
  },[taskDefinition, dispatch, notify]);

  const fields = [
    {
      type: 'text',
      name: 'name',
      label: 'Pet name',
      required: true,
      value: activePet?.name,
    },
    {
      type: 'itemList',
      name: 'pet_type_id',
      label: 'Which type of pet would you like to register?',
      hiddenLabel: true,
      required: 'Pet type is required.',
      value: (petTypes ?? []).find(type => type.name === activePet?.pet_type)?.id,
      isTwoCol: true,
      options: (petTypes ?? []).map(type => ({
        label: type.name,
        value: type.id,
        icon: { symbol: format.capitalize(type.icon), library: 'animals'},
      })),
    },
  ];

  const handleSubmit = formData => {
    if(pending) return;
    if(!activePet) {
      dispatch(taskDefinition.actions.createPet(activeRequest.id, formData))
        .then(nextScreen)
        .catch(err => {
          notify.error();
        });
    }

    dispatch(taskDefinition.actions.updatePet(activeRequest.id, activePet.id, formData))
      .then(nextScreen)
      .catch(err => {
        notify.error();
      });

  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <>
            <OtherPets taskDetails={taskDetails} />
            <InsuranceUpsellNotebox />
          </>
        )}
      >
        { (!loading && !typesPending) ? (
          <DynamicForm
            id='pet-type'
            fields={fields}
            formStyle={'underline'}
            onSubmit={handleSubmit}
            disabled={pending}
          />
        ) : (
          <AtomSpinner />
        )}
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='pet-type'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
