import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { DynamicForm, Button } from '@moved/ui';

import { Screen } from '../../shared';

import { useResidentTags } from '../../../../common/hooks/useResidentTags';

import CSS from './styles/Utilities.module.scss';

const Provider = ({provider}) => {
  const residentTags = useResidentTags();
  return (
    <div className={CSS.provider_container}>
      <div className={CSS.provider_content}>
        <div className={CSS.provider_logo_container}>
          {provider.image ? (
            <img src={provider.image} alt={provider.name} className={CSS.provider_logo} />
          ) : (
            <h3>{provider.name}</h3>
          )}
        </div>
        <div className={CSS.provider_description}>
          {provider.description}
        </div>
      </div>
      <div className={CSS.provider_links}>
        { provider.url && (
          <a
            className={classNames('btn-primary', 'btn-white', CSS.provider_cta)}
            href={format.url(format.interpolate(provider.url,residentTags)).href}
            target='_blank'
            rel='noopener noreferrer'
          >
            {format.simpleHostname(provider.url)}
          </a>
        )}
        { provider.phone && (
          <a
            className={classNames('btn-primary', 'btn-white', CSS.provider_cta)}
            href={`tel:${provider.phone}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {format.phone(provider.phone)}
          </a>
        )}
        { provider.sales_contact_email && (
          <a
            className={classNames('btn-primary', 'btn-white', CSS.provider_cta)}
            href={`mailto:${provider.sales_contact_email}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {provider.sales_contact_name ? provider.sales_contact_name : provider.sales_contact_email}
          </a>
        )}
      </div>
    </div>
  )
};

export const UtilitiesProviders = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const utility = taskDefinition.selectors.useTaskable(id);
  const pending = taskDefinition.selectors.useCompletePending();
  // state
  const [acceptConsent, setAcceptConsent] = useState(utility.accept_consent);

  const fields = [
    {
      name: 'accept_consent',
      label: 'I agree I can provide proof of utilities upon request.',
      type: 'checkbox',
      value: acceptConsent,
      required: 'Must accept the communication agreement to continue',
    },
  ];

  const onChange = (values) => (values.accept_consent) ? setAcceptConsent(true) : setAcceptConsent(false);

  const handleSubmit = (data) => {
    dispatch(taskDefinition.actions.complete(id, data))
      .then(() => nextScreen())
      .catch(error => notify.error(format.error(error)));
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        { (utility?.sections ?? []).map((section, index) => (
          <div key={index} className={CSS.section}>
            <h3 className={CSS.section_title}>{section.title}</h3>
            {
              section.providers && (
                section.providers.map((provider, index) => (
                  <Provider key={index} provider={provider} />
                ))
              )
            }
          </div>
        ))}
        <DynamicForm id='utilities-form'
          className={CSS.form}
          fields={fields}
          formStyle='underline'
          onChange={onChange}
          onSubmit={handleSubmit}
          disabled={!acceptConsent}
        />
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='I agree'
          size='large'
          form='utilities-form'
          disabled={!acceptConsent || pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  )
};
