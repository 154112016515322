import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { useNotify, format } from '@moved/services';
import { Button, Card } from '@moved/ui';

import { useSnippet } from '../../../../snippets';
import { Screen } from '../../shared';

import CSS from './styles/InsuranceProviders.module.scss';

const ProviderBlock = ({ provider, taskDefinition }) => {
  const { content = {} } = provider;
  const dispatch = useDispatch();
  const notify = useNotify();
  const { id } = useParams();
  const [pending, setPending] = useState();

  const selectProvider = () => {
    if(pending) return false;
    setPending(true);
    dispatch(taskDefinition.actions.getProviderLink(id, provider?.id))
      .then(link => {
        window.open(decodeURIComponent(link));
        setPending(false);
      })
      .catch(err => {
        setPending(false);
        notify.error(format.error(err));
      });
  };

  return (
    <Card className={CSS.card}>
      <div className={CSS.title}>
        <img  className={CSS.logo} src={content.logo} alt={provider.name} />
        <span>{content.title}</span>
      </div>
      <div dangerouslySetInnerHTML={{__html:content.html}} />
      { provider.cta !== 'NO_CTA' && (
        <Button
          onClick={selectProvider}
          text={provider.cta ? provider.cta : 'Get a Quote'}
          customColor={content.accent_color}
          color={content.accent_color ? 'custom' : 'secondary'}
          className={CSS.cta}
        />
      )}
    </Card>
  );
};

export const InsuranceProviders = ({ screen, nextScreen, taskDefinition }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { id } = useParams();
  // redux
  const taskDetails = taskDefinition.selectors.useTaskable(id);
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const selectedPurchaseOption = taskDetails?.selected_purchase_option;
  const providers = selectedPurchaseOption?.providers ?? [];
  const ctaText = useSnippet('tasks.insurance.providers.cta_text') ?? 'Next';


  const handleNext = () => {
    const {
      verificationMethod,
      defaultProvider,
    } = taskDefinition.getPurchaseOptionFlowImplications(selectedPurchaseOption);
    if(verificationMethod === 'no-verification') {
      const providerName = defaultProvider?.name ?? selectedPurchaseOption?.display_name;
      return dispatch(taskDefinition.actions.updateRequest(activeRequest.id, { insurance_provider: providerName }))
        .then(() => dispatch(taskDefinition.actions.submitRequest(activeRequest.id)))
        .then(nextScreen)
        .catch(err => notify.error(format.error(err)));
    }
    return nextScreen();
  }

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className='stackVertical gap-20'>
          { providers.map((provider) => (
            <ProviderBlock key={provider.id} provider={provider} taskDefinition={taskDefinition}/>
          ))}
        </div>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text={ctaText}
          size='large'
          onClick={handleNext}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
