import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import * as Yup from 'yup';

import { DynamicForm, AtomSpinner, Button } from '@moved/ui';
import { useNotify, useUser, format, globals } from '@moved/services';

import { Screen } from '../../shared';
import { OtherVehicles } from './OtherVehicles';

export const VehicleDetails = ({ screen, nextScreen, taskDefinition, taskDetails }) => {
  const { id, screenContext } = useParams();
  const { user } = useUser();
  const dispatch = useDispatch();
  const notify = useNotify();

  const taskable = taskDefinition.selectors.useTaskable(id);
  const vehicleTypes = taskDefinition.selectors.useVehicleTypes();
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskable);
  const activeVehicle = taskDefinition.getActiveVehicle(taskable, screenContext);
  const createPending = taskDefinition.selectors.useCreateVehiclePending();
  const vehiclePending = taskDefinition.selectors.useUpdateVehiclePending();
  const loading = taskDefinition.selectors.useVehicleRegistrationPending();
  const typesPending = taskDefinition.selectors.useVehicleTypesPending();
  const vehicles = activeRequest?.vehicles ?? [];

  const pending = createPending || vehiclePending;

  useEffect(() => {
    dispatch(taskDefinition.actions.getVehicleTypes())
      .catch(error => notify.error(format.error(error)));
  },[taskDefinition]); // eslint-disable-line

  const fields = [
    {
      type: 'itemList',
      name: 'vehicle_type_id',
      label: 'Which type of vehicle would you like to register?',
      hiddenLabel: true,
      isSquare: true,
      required: 'Vehicle type is required.',
      value: (vehicleTypes ?? []).find(type => type?.name === activeVehicle?.vehicle_type)?.id,
      isTwoCol: true,
      options: vehicleTypes.map(type => ({
        label: type.name,
        value: type.id,
        icon: { symbol: format.capitalize(type.icon), library: 'vehicles'},
      })),
    },
    {
      type: 'text',
      name: 'make',
      label: 'Make',
      required: true,
      half: true,
      value: activeVehicle?.make,
    },
    {
      type: 'text',
      name: 'model',
      label: 'Model',
      required: true,
      half: true,
      value: activeVehicle?.model,
    },
    {
      type: 'text',
      name: 'year',
      label: 'Year',
      half: true,
      maxlength: 4,
      flavor: 'YYYY',
      value: activeVehicle?.year,
    },
    {
      type: 'text',
      name: 'color',
      label: 'Color',
      required: true,
      half: true,
      value: activeVehicle?.color,
    },
    {
      type: 'text',
      name: 'license_plate_number',
      label: 'License plate number',
      required: true,
      half: true,
      maxlength: 8,
      value: activeVehicle?.license_plate_number,
    },
    {
      type: 'select',
      name: 'state',
      label: 'State',
      required: true,
      half: true,
      value: activeVehicle?.state,
      options: globals.stateOptions,
    },
    {
      type: 'select',
      name: 'owner_tenant_id',
      label: 'Owner',
      required: true,
      options:  (taskable?.tenants_on_lease ?? []).map(tenant => ({
        label: format.fullname(tenant),
        value: tenant.id,
      })),
      value: activeVehicle?.owner?.id ?? (taskable?.tenants_on_lease ?? []).find(tenant => tenant?.user_id === user.id)?.id
    },
  ];

  const validation = Yup.object().shape({
    year: Yup.date('Must be a valid year.').required('Year is required.').min('1900', 'Must be a valid year.').max(new Date().getFullYear(), 'Cannot be later than the current year.'),
  });

  const _handleSubmit = formData => {
    if(pending) return false;

    // Create vehicle helper
    const createOrUpdateVehicle = request => {
      return activeVehicle ?
        dispatch(taskDefinition.actions.updateVehicle(request.id, activeVehicle.id, formData)) :
        dispatch(taskDefinition.actions.createVehicle(request.id, formData));
    };

    // Create request if none exists or use existing
    (activeRequest?.status === 'draft' ?
      new Promise(resolve => resolve(activeRequest)) :
      dispatch(taskDefinition.actions.createRequest(id))
        .then(resp => taskDefinition.helpers.getActiveRequest(resp))
    )
      .then(resp => createOrUpdateVehicle(resp))
      .then(() => dispatch(taskDefinition.actions.update(id, { has_vehicles: true, })))
      .then(nextScreen)
      .catch(error => notify.error(format.error(error)));
  };

  const handleNoVehicles = () => {
    if(pending) return false;
    return dispatch(taskDefinition.actions.update(id, { has_vehicles: false, }))
  }

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <OtherVehicles taskDetails={taskDetails} />
        )}
      >
        { (!loading && !typesPending) ? (
          <DynamicForm
            id={`details-${screen.context}-form`}
            fields={fields}
            formStyle={'underline'}
            onSubmit={_handleSubmit}
            disabled={pending}
            validation={validation}
          />
        ) : (
          <AtomSpinner />
        )}
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form={`details-${screen.context}-form`}
          disabled={pending}
          className='width-full'
        />
        {vehicles.length < 1 && (
          <Button
            text='Nothing to register'
            size='large'
            color='secondary'
            disabled={pending}
            onClick={handleNoVehicles}
          />
        )}
      </Screen.Actions>
    </Screen>
  );
};
