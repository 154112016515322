import React from 'react';
import { useDispatch } from 'react-redux';

import { format, useModal, useNotify } from '@moved/services';
import { Button, CompleteCard, ConfirmationModal, Accordion, FieldList, Notebox } from '@moved/ui';

import {
  PastRequest,
  Screen,
  CompleteScreenActions,
} from '../../shared';
import { SummaryScreen } from '../../shared/components/SummaryScreen';
import { OtherPets } from './OtherPets';
import { InsuranceUpsellNotebox } from './InsuranceUpsellNotebox';

export const Confirmation = ({ taskDefinition, taskDetails, moveId, origin, screen, goToScreen, onClose }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const requiresApproval = taskDetails?.settings?.requires_approval;
  const requestHistory = taskDefinition.helpers.getRequestHistory(taskDetails);
  const createPending = taskDefinition.selectors.useCreatePetRequestPending()
  const resetPending = taskDefinition.selectors.useResetPetRegistrationPending();

  // Reset handler functions
  const confirmReset = () => {
    if(activeRequest?.status === 'rejected') return handleReset();
    else modal.open(
      <ConfirmationModal
        title='Resubmit updated pet details'
        copy={'Are you sure? This will replace your existing pet details submission.'}
        confirmText='Continue'
        cancelText='Never mind'
        onConfirm={handleReset}
      />
    );
  };
  const handleReset = () => {
    return dispatch(taskDefinition.actions.reset(taskDetails.id))
      .then((resp) => goToScreen(taskDefinition.getStartOverScreen(resp)))
      .catch((error) => notify.error(format.error(error)));
  };

  const resetCopy = 'Submit new pet details';

  return (
    taskDetails.has_pets ? (
      <SummaryScreen
        origin={origin}
        screen={screen}
        moveId={moveId}
        onClose={onClose}
        noteboxes={(
          <>
            <OtherPets taskDetails={taskDetails} />
            <InsuranceUpsellNotebox />
          </>
        )}
        extraAttributes={[
          !(activeRequest?.status === 'approved' && requiresApproval) && {
            value: (
              <Button
                size='small'
                color='secondary'
                text='Submit a new pet registration'
                onClick={confirmReset}
                disabled={createPending}
              />
            )
          },
        ].filter(Boolean)}
      />
    ) : (
      // FUTURE: this should eventually be an override (does not exist yet so must be manually defined)
      <Screen taskDefinition={taskDefinition} screen={screen}>
        <Screen.Title />
        <OtherPets taskDetails={taskDetails} />
        <Screen.Content
          noteboxes={(
            <OtherPets taskDetails={taskDetails} />
          )}
        >
          <div className='stackVertical gap-48'>
            <CompleteCard
              status={'approved'}
              flavor={(
                <>
                  You've indicated that you have no pets or service animals to
                  register. If applicable, other members of your household may
                  have registered their own animals.
                </>
              )}
            >
              <FieldList list={[
                {
                  label: 'Do you have pets?',
                  icon: { symbol: 'Dog', library: 'animals' },
                  value: 'No'
                },
                {
                  value: (
                    <Button
                      text={resetCopy}
                      color='secondary'
                      size='small'
                      onClick={confirmReset}
                      disabled={resetPending}
                    />
                  )
                }
              ]} />
              <Notebox
                className={'mt-30'}
                title={'Note'}
                body={<>
                  Your property requires details for all animals living in your apartment.
                  If you need to register an animal later, please click "<strong>{resetCopy}</strong>" above and submit the required information.
                </>}
                color={'orange'}
                icon={{
                  symbol: 'Info-circle',
                  library: 'code',
                }}
              />
            </CompleteCard>

            {requestHistory.length > 0 && (
              <Accordion
                label={(<h5 className='m-0'>History</h5>)}
                content={requestHistory.map(request => (
                  <PastRequest
                    key={request.id}
                    request={request}
                  />
                ))
                }
              />
            )}
          </div>
        </Screen.Content>
        <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
      </Screen>
    )
  );

};
