import * as blockFaqQuestion from './blockFaqQuestion';
import * as createFaq from './createFaq';
import * as createFaqAnswer from './createFaqAnswer';
import * as createFaqTopic from './createFaqTopic';
import * as deleteFaqAnswer from './deleteFaqAnswer';
import * as getFaqs from './getFaqs';
import * as markAnswerHelpful from './markAnswerHelpful';
import * as sendMessage from './sendMessage';
import * as updateFaqAnswer from './updateFaqAnswer';

export const reducers = [
  blockFaqQuestion.reducer,
  createFaq.reducer,
  createFaqAnswer.reducer,
  createFaqTopic.reducer,
  deleteFaqAnswer.reducer,
  getFaqs.reducer,
  markAnswerHelpful.reducer,
  sendMessage.reducer,
  updateFaqAnswer.reducer,
];

export const initialState = {
  ...getFaqs.initialState,
};
