import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Greeting } from '@moved/ui';
import { useUser, useMobile } from '@moved/services';
import { Footer } from '@moved/product';

import { SplashHeader } from '../../common';
import { useMove } from '../../moves/actions/selectors';

import { getMarketplaceAds } from '../actions';
import { useMarketplaceAds } from '../actions/selectors';

import { SponsoredAds } from './SponsoredAds';
import { MarketplaceAds } from './MarketplaceAds';

import CSS from './styles/Marketplace.module.scss';

export const Marketplace = () => {
  const dispatch = useDispatch();
  const { moveId } = useParams();
  const { user } = useUser();
  const isMobile = useMobile();
  const move = useMove(moveId);
  const moveStep = move?.move_steps?.at(0);
  // we want the partner, but it's not available, so use the building as a proxy
  const moveStepBuilding = moveStep && moveStep?.building;

  const marketplaceAds = useMarketplaceAds(moveId) || [];
  const movedAds = marketplaceAds.find(group => group?.sponsor_partner.name === 'Moved');
  const partnerAds = marketplaceAds.find(group => group?.sponsor_partner.name !== 'Moved');

  useEffect(() => {
    dispatch(getMarketplaceAds(moveId));
  },[moveId, dispatch]);

  return (
    <div className={CSS.parallaxScroller}>
      <Helmet>
        <title>{`Welcome to Moved`}</title>
      </Helmet>

      <SplashHeader
        background={isMobile && `${process.env.REACT_APP_S3_ASSET_URL}/partners/default/splash-landscape.jpg`}
        video={!isMobile && `${process.env.REACT_APP_S3_ASSET_URL}/partners/default/splash-landscape.mp4`}
      >
        { moveStep ? (
          <div className='stackVertical gap-10'>
            { moveStepBuilding && (
              <div className={CSS.buildingLogo}>
                <img src={moveStepBuilding.logo_url} alt={moveStepBuilding.name} />
              </div>
            )}
            <div className='displayXL displayM--mobile'>Moving Offers</div>
          </div>
        ) : (
          <Greeting user={user} className={CSS.greeting}/>
        )}
      </SplashHeader>

      <section className={CSS.dashboard}>
        <div className='container'>

          <SponsoredAds adCollection={partnerAds} move={move} />

          <MarketplaceAds adCollection={movedAds} move={move} />

        </div>
      </section>

      <Footer />
    </div>
  );
}
