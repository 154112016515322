import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { useNotify } from '@moved/services';
import { StyledForm, FieldToggle, FieldCheckbox, Button } from '@moved/ui';

import { Screen } from '../../shared';

import indiCSS from './styles/InsuredIndividuals.module.scss';

export const InsuredIndividuals = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const taskDetails = taskDefinition.selectors.useRenterInsurance(id);
  const pending = taskDefinition.selectors.useUpdateRenterInsuranceRequestPending();

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails) || {};
  const tenants = activeRequest?.tenants;

  const handleSubmit = ({ tenants }) => {
    dispatch(taskDefinition.actions.updateRequest(activeRequest.id, { tenants }))
      // If this screen is in the flow it is *always* last so it always submits the policy
      .then(() => dispatch(taskDefinition.actions.submitRequest(activeRequest.id)))
      .then(nextScreen)
      .catch(err => notify.error());
  };


  // Runs when any is_primary_policyholder value is updated
  const primaryChange = (value, name, setFieldValue) => {
    if(value === true) {
      // Set all other primary values to false
      tenants.forEach((tenant, idx) => {
        setFieldValue(`tenants[${idx}].is_primary_policyholder`, false, false);
      });
    }
    return setFieldValue(name, true, true);
  }

  // Change handler for whole form
  const handleChange = (values, { setFieldValue }) => {
    const tenants = values?.tenants ?? [];
    tenants.forEach((tenant, index) => {
      // If tenant is the primary, coverage cannot be turned off
      if(tenant.is_primary_policyholder)
        return setFieldValue(`tenants[${index}].is_covered`, true, false);
      return;
    });
  }

  // These initial values ensure that the current user is defaulted to both
  // covered and primary as long as someone else isn't prepopulated as the primary.
  // Either way there will always be one default selected primary so we no longer need to track
  // isPrimayChosen as a stateful value.
  const getInitialValues = () => {
    const isPrimaryChosen = tenants.find(tenant => tenant.is_primary_policyholder);
    return { tenants: tenants.map(tenant => ({
      id: tenant.id,
      is_covered: tenant.is_covered || tenant.id === taskDetails.tenant_id,
      is_primary_policyholder: tenant.is_primary_policyholder ||
        (!isPrimaryChosen && tenant.id === taskDetails.tenant_id),
    }))};
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <StyledForm
          id='individuals-form'
          onSubmit={handleSubmit}
          onChange={handleChange}
          disabled={pending}
          initialValues={getInitialValues()}
        >
        {(formik) => (
          <div className={indiCSS.tenant_list}>
            <div className={indiCSS.tenant_head}>
              <div className={indiCSS.field}>
                Resident
              </div>
              <div className={indiCSS.field}>
                Covered by this plan
              </div>
              <div className={indiCSS.field}>
                Primary policy holder
              </div>
            </div>
            { tenants.map((tenant, index) => (
              <div key={tenant.id} className={indiCSS.tenant_row}>
                <div className={indiCSS.field}>
                  {tenant.firstname} {tenant.lastname}
                </div>
                <div className={indiCSS.field}>
                  <div className={indiCSS.input_row}>
                  <FieldToggle
                    input={{
                      name: `tenants[${index}].is_covered`,
                      disabled: tenant.id === taskDetails.tenant_id, // this prevents current tenant from un-selecting being covered
                    }}
                  />
                  </div>
                </div>
                <div className={indiCSS.field}>
                  <FieldCheckbox
                    input={{
                      name: `tenants[${index}].is_primary_policyholder`,
                      onChange: (value, name) => primaryChange(value, name, formik.setFieldValue),
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        </StyledForm>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='individuals-form'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
