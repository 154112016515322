import { useState, createContext, useContext } from 'react';
import classNames from 'classnames';

import { Card, Modal } from '@moved/ui';

import { useMove } from '../../../moves/actions/getMove';

import { StepWelcome } from './StepWelcome';
import { StepAddresses } from './StepAddresses';
import { StepDate } from './StepDate';
import { StepResponsibility } from './StepResponsibility';
import { StepTasks } from './StepTasks';
import { StepComplete } from './StepComplete';

import CSS from './OnboardingWizard.module.scss';

const WizardContext = createContext();
export const useWizard = () => useContext(WizardContext);

export const OnboardingWizard = ({ move }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const next = () => setCurrentStep(step => step + 1);
  const prev = () => setCurrentStep(step => step - 1);

  const moveDetails = useMove(move?.id);
  const activeMoveStep = moveDetails?.move_steps?.at(0);
  const isMultiTenant = (activeMoveStep?.lease?.total_occupants ?? 1) > 1;

  const wizardSteps = [
    {
      name: 'welcome',
      content: <StepWelcome/>,
      hideOnProgress: true,
    },
    {
      name: 'addresses',
      content: <StepAddresses/>,
    },
    {
      name: 'date',
      content: <StepDate/>,
    },
    // this step is hidden until further notice (but not removed, in case we want it back)
    false && isMultiTenant && {
      name: 'responsibility',
      content: <StepResponsibility/>,
    },
    {
      name: 'tasks',
      content: <StepTasks/>,
    },
    {
      name: 'complete',
      content: <StepComplete/>,
      hideOnProgress: true,
    },
  ].filter(Boolean);

  const activeStep = wizardSteps[currentStep];

  const wizardApi = {
    move: moveDetails,
    steps: wizardSteps,
    activeStep,
    next,
    prev
  };

  return (
    <Modal theme='glass'>
      <Card className={CSS.card}>
        <WizardContext.Provider value={wizardApi}>
          <div className='stackHorizontal width-full'>
            { activeStep.content }
          </div>
        </WizardContext.Provider>
      </Card>
    </Modal>
  );
};

export const Content = ({ className, children }) => (
  <div className={classNames('stackVertical gap-28',CSS.content,className)}>{ children }</div>
);

export const SideImage = ({ src }) => (
  <div className={CSS.sideImage}>
    <img src={src} alt='Step Preview' className='radiusLeft-16' />
  </div>
);

export const Actions = ({ className, children }) => (
  <div className={classNames(CSS.actions,className)}>{ children }</div>
);
