import { withBuilding } from '../common';
import { withMoveSummaries } from '../moves/components';
import { withAssistant } from '../assistant/components';

import { Authentication } from './components/Authentication';
import { ClaimAccount } from './components/ClaimAccount';
import { PasswordReset } from './components/PasswordReset';
import { PasswordSet } from './components/PasswordSet';
import { PasswordChange } from './components/PasswordChange';
import { Profile } from './components/Profile';

const routes = [
  {
    path: '/signup/:buildingSlug/:code',
    name: 'Claim Account',
    component: withBuilding(ClaimAccount),
    allowGuest: true,
    exact: true,
    transitions: {
      in: 'crossFade',
      out: 'crossFade',
    },
  },
  {
    path: '/:view(signup|signin)/:buildingSlug',
    name: 'Authentication',
    component: withBuilding(Authentication),
    allowGuest: true,
    restrict: ['customer','vendor','cx','client'],
    exact: true,
    transitions: {
      in: 'crossFade',
      out: 'crossFade',
    },
  },
  {
    path: '/:view(signup|signin)',
    name: 'Authentication',
    component: Authentication,
    allowGuest: true,
    restrict: ['customer','vendor','cx','client'],
    exact: true,
    transitions: {
      in: 'crossFade',
      out: 'crossFade',
    },
  },
  {
    path: '/password-reset/:token',
    name: 'PasswordReset',
    component: PasswordReset,
    allowGuest: true,
    restrict: ['customer','vendor','cx','client'],
    exact: true,
  },
  {
    path: '/password-set/:token',
    name: 'PasswordSet',
    component: PasswordSet,
    allowGuest: true,
    restrict: ['customer','vendor','cx','client'],
    exact: true,
  },
  {
    path: '/profile',
    name: 'ProfileSection',
    component: ({children}) => children,
    restrict: ['customer','vendor','cx','client'],
    transitions: {
      in: 'rotateRoomTop',
      out: 'rotateRoomBottom',
    },
    viewKey: () => `profile`,
    children: [
      {
        path: '/',
        name: 'Profile',
        component: withAssistant(withMoveSummaries(Profile)),
        restrict: ['customer','vendor','cx','client'],
        address: 'profile',
        exact: true,
        transition: 'slideRight',
        viewKey: () => `profile`,
      },
      {
        path: '/password',
        name: 'Change Password',
        component: withAssistant(PasswordChange),
        transition: 'slideLeft',
        restrict: ['customer','vendor','cx','client'],
        exact: true,
        viewKey: () => `password`,
      }
    ],
  },
];

export default routes;
