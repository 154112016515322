import React from 'react';

import { Icon, Button } from '@moved/ui';
import { useModal } from '@moved/services';

import CSS from './styles/PastDeadlineWarning.module.scss';

export const PastDeadlineWarning = () => {
  const { close } = useModal();
  return (
    <div className='txt-center'>
      <div className='mb-30'>
        <Icon library='code' symbol='Time-schedule' size='125px' className={CSS.icon} />
      </div>
      <h2 className='mb-10'>Oh no. It's too late!</h2>
      <p className='mb-30'>
        Moves must be booked no later than <strong>2pm the day before</strong>
        your requested pickup date. Please select a new date with enough time
        to secure a moving company.
      </p>
      <div className='flex justify-center'>
        <Button
          text='Update move date'
          onClick={close}
        />
      </div>
    </div>
  );
};
