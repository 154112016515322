import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ASSISTANT_CREATE_FAQ_ANSWER',
  request: (id,data={}) => request.post(`/residents/faq-questions/${id}/answers`, data, { asSuperUser: true })
    .then(r => r?.data?.data?.faq_answer),
};

export const {
  reducer,
  action: createFaqAnswer,
  usePending: useCreateFaqAnswerPending,
} = generate.redux(creator);
