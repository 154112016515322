import {
  DepositPayment,
  DepositSummary,
  DepositOffline,
} from './components';

export const screens = {

  'deposit-payment': {
    slug: 'payment',
    component: DepositPayment,
    label: 'Payment info',
    title: 'Pay security deposit',
    subtitle: 'Pay your security deposit and any upfront fees before completing other required tasks.',
    maxWidth: 'none',
    isCompleted: (data, { isPaymentComplete }) => isPaymentComplete(data),
  },

  'deposit-summary': {
    slug: 'summary',
    component: DepositSummary,
    label: 'Summary',
    backRestricted: (data) => data?.balance === 0,
    isCompleted: (data) => data?.balance === 0,
  },

  'deposit-offline': {
    slug: 'payment',
    component: DepositOffline,
    label: 'Payment options',
    title: 'Pay security deposit',
    subtitle: 'The deadline to pay online has passed.',
    maxWidth: 'none',
    isCompleted: () => true,
  },

};
