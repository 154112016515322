import React, { createContext, useContext } from 'react';
import { Link } from "react-router-dom";
import classNames from 'classnames';

import { useMobile, useUser } from '@moved/services';
import { Button } from '@moved/ui';
import { LogoBlock } from '@moved/product';

import { SnippetEditor } from '../../snippets';
import bgImage from './images/map-bg.png';
import CSS from './styles/TaskFlowLayout.module.scss';

const TaskFlowLayoutContext = createContext();
export const useTaskFlowLayout = () => useContext(TaskFlowLayoutContext);

const TaskFlowLayout = ({ taskDefinition, origin, children }) => {
  return (
    <TaskFlowLayoutContext.Provider value={{
      taskDefinition,
      origin,
    }}>
      <div className={CSS.layout}>
        { children }
      </div>
    </TaskFlowLayoutContext.Provider>
  );
};

const Sidebar = ({ children }) => {
  const isMobile = useMobile();
  const { taskDefinition, origin } = useTaskFlowLayout();
  const activeMoveStep = taskDefinition?.selectors?.useActiveMoveStep();
  const building = taskDefinition?.selectors?.useBuilding(activeMoveStep?.building?.slug);
  const buildingName = building?.settings?.display_name ?? building?.name;

  return (
    <div className={CSS.sidebar}>
      <div className={CSS.sidebarContent}>
        { !isMobile && (
          building ? (
            <Link to={origin}>
              <img src={building.logo_url} alt={buildingName} className={CSS.building_logo} />
            </Link>
          ) : (
            <LogoBlock redirect={origin}/>
          )
        )}

        <div className={CSS.navigation}>
          { children }
        </div>

        { !isMobile && building && (
          <LogoBlock redirect={origin} className={CSS.moved_logo}/>
        )}

      </div>
    </div>
  );
};

const Content = ({ children, previousScreen }) => {
  const isMobile = useMobile();
  const { isProxy } = useUser();
  const { origin } = useTaskFlowLayout();

  return (
    <div
      className={CSS.content}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      { children }
      { !isMobile && (
        <>
          <div className={classNames(CSS.actions,CSS.left)}>
            <Button
              onClick={previousScreen}
              icon={{ symbol: 'Arrow-left', library: 'navigation' }}
              color='tertiary'
            />
          </div>
          <div className={classNames(CSS.actions,CSS.right)}>
            { isProxy && (
              <SnippetEditor className='hide-on-screenshot'>
                <Button
                  icon={{ symbol: 'Pen-tool-vector', library: 'design' }}
                  color='tertiary'
                />
              </SnippetEditor>
            )}
            <Link to={origin}>
              <Button
                icon={{ symbol: 'Close', library: 'navigation' }}
                color='tertiary'
              />
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

TaskFlowLayout.Sidebar = Sidebar;
TaskFlowLayout.Content = Content;
export { TaskFlowLayout };
