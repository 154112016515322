import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useNotify, format } from '@moved/services';
import { DynamicForm, Button } from '@moved/ui';

import { getTaskSummary } from '../../../actions';
import { Screen } from '../../shared';

import CSS from './styles/KeyPickup.module.scss';

export const KeySelectAppointment = ({ screen, nextScreen, taskDefinition, taskDetails }) => {
  const { taskId, id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const updatePending = taskDefinition.selectors.useUpdatePending();
  const createPending = taskDefinition.selectors.useCreateRequestPending();
  const pending = updatePending || createPending;
  const { roommate_appointments = [] } = taskDetails ?? {};

  // Handle case where user wants to schedule their own appointment
  const handleSelfResponsibility = () => {
    // if no draft request post to request a new draft otherwise, update task to self
    const draftRequest = taskDetails?.requests?.[0]?.status === 'draft' ? taskDetails.requests[0] : null;

    if(draftRequest) {
      // Update task
      dispatch(taskDefinition.actions.update(id, { responsibility: 'self' }))
        .then(nextScreen)
        .catch(error => notify.error(format.error(error)));
    } else {
      // Create request
      dispatch(taskDefinition.actions.createRequest(id))
        .then((resp) => dispatch(taskDefinition.actions.updateRequest(resp.requests[0].id, {
          'tenant_key_ids': [ taskDetails.current_tenant_key.id],
          ...(taskDetails.building_calendar_options.length === 1
              ? {building_calendar_id: taskDetails.building_calendar_options[0].id }
              : null
          )
        })))
        .then(nextScreen)
        .catch(error => notify.error(format.error(error)));
    }

  }

  // Submit form, update key pickup task
  const handleSubmit = data => {
    if (pending) return;

    if(data.current_tenant_key.key_pickup_appointment_id === 'self_schedule') {
      return handleSelfResponsibility();
    }

    return dispatch(taskDefinition.actions.update(id, data))
      // update to the artifacts list based on this request, must refresh the summary
      .then((resp) => dispatch(getTaskSummary(taskId)).then(() => resp)) // sneaky forward the previous response
      .then(nextScreen)
        .catch(error => notify.error(format.error(error)));
  }

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <DynamicForm
          id='appointment-form'
          fields={[
            {
              name: 'current_tenant_key.key_pickup_appointment_id',
              type: 'itemList',
              value: taskDetails?.responsibility === 'self' ? 'self_schedule' : '',
              required: 'You must select an appointment or schedule your own',
              isMulti: false,
              isTwoCol: false,
              className: CSS.roommates_form,
              options: roommate_appointments.map(appt => {
                return {
                  label: format.fullname(appt?.tenant),
                  sublabel: `Picking up keys for ${(appt?.covered_tenants ?? []).map(tenant => tenant.firstname).join(', ')}`,
                  rightLabel: (
                    <div className={CSS.appt_info}>
                      <span className={CSS.appt_date}>{format.date(appt.pickup_date)}</span>
                      <span className={CSS.appt_time}>{format.date(appt.pickup_time,'time',null,['h:ma'])}</span>
                    </div>
                  ),
                  customIcon: (
                    <div className={CSS.oval}>
                      <span>{format.initials(appt?.tenant)}</span>
                    </div>
                  ),
                  value: appt.id,
                }
              }).concat([
                {
                  label: `I will pick up my own keys`,
                  icon: { symbol: 'Time', library: 'code' },
                  value: 'self_schedule',
                }
              ])
            },
          ]}
          onSubmit={handleSubmit}
          disabled={pending}
        />
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='appointment-form'
          diabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  )
};
