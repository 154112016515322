import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { useUser, useNotify, s3, format } from '@moved/services';
import { ImageCropUpload, ImageCrop, Icon, Button } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { Screen } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';
import CSS from './styles/BadgeImage.module.scss';

export const BadgeImage = ({ screen, nextScreen, taskDefinition }) => {
  // HOOKS
  const { id, taskType } = useParams();
  const { user } = useUser();
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);
  const dispatch = useDispatch();
  const notify = useNotify();

  // STATE
  const [crop, setCrop] = useState(false);
  const [pending, setPending] = useState(false);

  const buildingName = building?.settings?.display_name ?? building?.name;

  const ready = crop?.file ? true : false;

  const handleNext = e => {
    e.preventDefault();
    if(!ready || pending) return;
    setPending(true);
    dispatch(getS3UploadUrl(building.id, {
      filename: s3.getUniqueFilename(crop.file.name, user),
      http_content_type: crop.file.type,
      subdirectory: `badges`,
    }))
      .then(({signed_request, url}) => s3.putFile(crop.file, signed_request).then(() => url))
      .then(url => dispatch(taskDefinition.actions.upload(id, { image_url: url })))
      .then(nextScreen)
      .catch(err => {
        notify.error(format.error(err));
        setPending(false);
      });
  };

  const handleReject = () => notify.error('Oops! The file does not meet the file type requirement.');

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className={CSS.wrapper}>
          <div className={CSS.uploader}>
            <ImageCropUpload
              id={`${taskType}_${screen.slug}_upload`}
              onCrop={setCrop}
              onReject={handleReject}
              cropOptions={{
                aspect: 1,
                maxWidth: 1200,
                minWidth: 400,
              }}
            />
          </div>

          {!ready ? (
            <div className={CSS.requirements}>
              <h3 className={CSS.title}>
                <Icon symbol={'Info-circle'} library={'code'} />
                <span>Requirements</span>
              </h3>
              <Snippet tag={'tasks.badge.upload.requirements'}>
                <ul>
                  <li>Must be a clear color photo of your face</li>
                  <li>Must have been taken in the last 6 months</li>
                  <li>Must have plain background</li>
                  <li>No hats, sunglasses, or filters</li>
                </ul>
              </Snippet>
            </div>
          ) : (
            <div className={CSS.badge_wrapper}>
              <div className={CSS.preview_title}>Preview</div>
              <div className={CSS.badge}>
                <div className={CSS.tab} />
                <ImageCrop.Preview file={crop.file} className={CSS.preview} />
                <div className={CSS.name}>
                  <Snippet tag={'tasks.badge.upload.preview_caption'}>
                    <span>{user.fullname}</span>
                  </Snippet>
                </div>

                <img src={building.logo_url} alt={buildingName} className={CSS.logo} />
              </div>
            </div>
          )}

        </div>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Submit'
          size='large'
          onClick={handleNext}
          disabled={!ready || pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
