import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CREATE_CHOICE_TASK_REQUEST',
  request: (id) => request.post(`/residents/choice-tasks/${id}/requests`, {})
    .then(r => r.data.data.choice_task),
  reducers: {
    success: (state, { response: choiceTask }) => ({
      ...state,
      choiceTasks: {
        ...state.choiceTasks,
        [choiceTask.id]: choiceTask,
      },
    }),
  }
};

export const {
  reducer,
  action: createRequest,
  usePending: useCreateRequestPending,
} = generate.redux(creator);

export const initialState = {
  choiceTasks: {},
}
