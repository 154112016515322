import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useModal, useUser } from '@moved/services';
import { Card, Icon, Button, Greeting } from '@moved/ui';

import { skipGettingStarted, useSkipGettingStartedPending } from '../actions/skipGettingStarted';

import { usePersonalTaskList } from '../contexts/PersonalTaskListContextProvider';
import { CreateCustomTaskModal } from '../modals/CreateCustomTaskModal';
import { ExploreTasksModal } from '../modals/ExploreTasksModal';
import { IntroModal } from '../modals/IntroModal';

import CSS from './styles/GettingStarted.module.scss';

export const useGettingStartedSteps = () => {
  const modal = useModal();
  const { moveId } = useParams();
  const taskList = usePersonalTaskList();

  return [
    {
      name: 'dashboard',
      title: 'Create your dashboard',
      description: 'Done! Look at you, making moves!',
      icon: { symbol: 'Done-circle', library: 'code' },
      isComplete: true, // complete by definition because they are on this page
    },
    {
      name: 'video',
      title: 'Welcome to Moved',
      description: 'Discover how to use your dashboard.',
      icon: { symbol: 'Bulb#1', library: 'home' },
      isComplete: taskList?.progress?.video === 1,
      onClick: () => modal.open(
        <IntroModal taskListId={taskList.id} />
      ),
    },
    {
      name: 'explore_tasks',
      title: 'Explore tasks',
      description: 'These contain exclusive offers and helpful resources',
      icon: { symbol: 'Binocular', library: 'general' },
      isComplete: taskList?.progress?.explore_tasks === 1,
      onClick: () => modal.open(
        <ExploreTasksModal taskListId={taskList.id} moveId={moveId} />
      ),
    },
    {
      name: 'first_task',
      title: 'Create custom tasks',
      description: 'It’s your move. Make it your own.',
      icon: { symbol: 'Plus', library: 'code' },
      isComplete: taskList?.progress?.first_task === 1,
      onClick: () => modal.open(
        <CreateCustomTaskModal taskListId={taskList.id} moveId={moveId} />
      ),
    },
  ];
};

export const GettingStarted = ({ className }) => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const steps = useGettingStartedSteps();
  const taskList = usePersonalTaskList();
  const skipPending = useSkipGettingStartedPending();

  const skip = () => dispatch(skipGettingStarted(taskList.id));

  return (
    <div className={className}>
      <div className='stackVertical gap-24'>
        <div className='stackVertical'>
          <div className='stackHorizontal justify-between'>
            <h3 className='displayS contentPrimary'>
              <Greeting user={user} />
            </h3>
            <Button
              text='Skip'
              color='tertiary'
              size='small'
              icon={{ library: 'media', symbol:'Forward' }}
              iconPosition='right'
              onClick={skip}
              disabled={skipPending}
            />
          </div>
          <p className='labelM contentSecondary'>
            Consider this your moving headquarters. Tackle your move like a
            pro - get organized, check off to-dos, and conquer the chaos.
          </p>
        </div>
        <div className='stackHorizontal gap-24 stackVertical--mobile'>
          { steps.map(step => (
            <Card
              key={step.title}
              className={classNames(
                CSS.card,
                {
                  [CSS.complete]:step.isComplete,
                  [CSS.clickable]:step.onClick != null,
                }
              )}
              onClick={step.onClick}
            >
              <Icon
                {...(step.isComplete ? { symbol: 'Done-circle', library: 'code' } : step.icon)}
                size='40px'
                className='marginBottom-40'
              />
              <div className='stackVertical gap-4'>
                <h4 className='headingS contentPrimary'>{ step.title }</h4>
                <p className='labelS contentSecondary'>{ step.description }</p>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};
