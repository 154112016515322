import React from 'react';
import { useParams, useHistory } from 'react-router-dom'

import { CompleteCard } from '@moved/ui';

import { Snippet } from '../../../../snippets';

import { Screen, CompleteScreenActions } from '../../shared';

import CSS from './styles/Complete.module.scss';

export const Complete = ({ screen, taskDefinition, origin, taskBaseRoute, moveId, onClose }) => {
  // HOOKS
  const { id } = useParams();
  const history = useHistory();

  const taskDetails = taskDefinition.selectors.useTaskable(id);

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <CompleteCard
          status={'approved'}
          flavor={(<Snippet tag={'tasks.cable-internet.summary'} />)}
          detailsTitle={null}
        >
          <p>
            You've marked this task as complete, so we'll assume you've signed
            up for service in your new apartment. If you ever need to check the
            service provider options for this property, they're always here for
            you to review.
          </p>
          <div className={CSS.start_over}>
            <button
              type="button"
              className={'btn-gray btn--small'}
              onClick={() => history.push(`${taskBaseRoute}${taskDefinition.getStartOverRoute(taskDetails)}`)}
            >
              Review options
            </button>
          </div>
        </CompleteCard>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  )

};
