import { Icon, Card, Button } from '@moved/ui';

import { useWizard, Content, Actions } from './OnboardingWizard';

import image from './images/welcome-graphic.png';
import CSS from './StepWelcome.module.scss';

export const StepWelcome = () => {
  const { next } = useWizard();
  return (
    <>
      <Content>

        <div className='stackVertical gap-4'>
          <div className='headingL contentPrimary'>
            Moving shouldn’t be stressful. We’re here to help.
          </div>
          <div className='labelM contentSecondary'>
            Ready to crush your move? We’ve got your back! Just share a few details, and we’ll tailor everything to make your move smooth and stress-free.
          </div>
        </div>

        <div className={CSS.divider} />

        <div className='stackVertical gap-28'>

          <div className='stackHorizontal gap-12 items-start'>
            <Card className='padding-12 radius-full'>
              <Icon library='files' symbol='Clipboard-check' />
            </Card>
            <div className='stackVertical'>
              <div className='labelL contentPrimary'>
                Helpful reminders
              </div>
              <div className='labelS contentSecondary'>
                Never forget a thing! Set reminders to keep your move on track - from booking movers to finding that last missing sock.
              </div>
            </div>
          </div>

          <div className='stackHorizontal gap-12 items-start'>
            <Card className='padding-12 radius-full'>
              <Icon library='shopping' symbol='Sale#2' />
            </Card>
            <div className='stackVertical'>
              <div className='labelL contentPrimary'>
                Exclusive offers
              </div>
              <div className='labelS contentSecondary'>
                Why pay full price? Score exclusive offers on moving services and products that make your life easier - and your wallet happier.
              </div>
            </div>
          </div>

          <div className='stackHorizontal gap-12 items-start'>
            <Card className='padding-12 radius-full'>
              <Icon library='communication' symbol='Group-chat' />
            </Card>
            <div className='stackVertical'>
              <div className='labelL contentPrimary'>
                Friendly support
              </div>
              <div className='labelS contentSecondary'>
                Stuck on something? No worries! Our friendly support team is just a click away to help you through the chaos. You’ve got this, and we’ve got you.
              </div>
            </div>
          </div>

        </div>

        <div className={CSS.divider}/>

        <Actions className={CSS.actions}>
          <Button
            text='Get moving'
            icon={{ library: 'navigation', symbol: 'Arrow-right' }}
            iconPosition='right'
            onClick={next}
          />
        </Actions>

      </Content>

      <div className={CSS.sideImage}>
        <img src={image} alt='Welcome' />
      </div>


    </>
  );
};
