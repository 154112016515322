import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CABLE_INTERNET_GET_PROVIDER_LINK',
  request: (id, providerId, data={}) => request.post(`/residents/cable-internet-tasks/${id}/cable-internet-providers/${providerId}/link`, data)
    .then(r => r?.data?.data.cable_internet_provider_link?.url),
};

export const {
  reducer,
  action: getProviderLink,
  usePending: useProviderLinkPending,
} = generate.redux(creator);
