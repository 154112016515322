import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_CABLE_INTERNET_TASK',
  request: (id) => request.get(`/residents/cable-internet-tasks/${id}`)
    .then(r => r?.data?.data?.cable_internet_task),
  selector: (state, id) => state?.cableInternetTasks?.[id],
  reducers: {
    success: (state, { response: cableInternetTask }) => ({
      ...state,
      cableInternetTasks: {
        ...state.cableInternetTasks,
        [cableInternetTask.id]: cableInternetTask,
      },
    }),
  }
};

export const {
  reducer,
  action: get,
  useResource: useCableInternetTask,
  usePending: useGetCableInternetTaskPending,
} = generate.redux(creator);

export const initialState = {};
