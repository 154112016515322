import { Button, Notebox } from '@moved/ui';

import { useAssistant } from './AssistantWidget';
import { ContactForm } from './ContactForm';
import { PanelHeader } from './PanelHeader';

export const ContactPanel = ({ animationClass }) => {
  const {
    activeQuestion,
    availablePanels,
    switchPanel,
  } = useAssistant();
  return (
    <div className='stackVertical gap-24 height-full'>
      <PanelHeader
        title='Contact us'
        animationClass={animationClass}
        action={availablePanels.includes('faq') ? (
          <Button
            onClick={() => switchPanel('faq')}
            icon={{ library: 'navigation', symbol: 'Chevron-left' }}
            color='secondary'
            size='small'
          />
        ) : availablePanels.includes('create') && (
          <Button
            onClick={() => switchPanel('create')}
            text='FAQs'
            icon={{ library: 'navigation', symbol: 'Plus' }}
            color='secondary'
            size='small'
          />
        )}
      />
      <div className='height-full width-full stackVertical gap-8 scrollable'>

        <ContactForm animationClass={animationClass}/>

        <div className='flex-grow stackVertical justify-end'>
          <Notebox
            color='neutral'
            icon={{ library: 'communication', symbol: 'Chat-smile' }}
            title='We’re here to help!'
            body={(
              <div className='stackVertical gap-8'>
                <div className='labelS'>
                  { activeQuestion?.answer?.route_to_property ? (
                    'Your property will follow up by email'
                  ) : (
                    'We will follow up by email (9am - 7pm ET)'
                  )}
                </div>
                { availablePanels.includes('faq') && (
                  <div className='faux-link' onClick={() => switchPanel('faq')}>
                    Return to FAQs
                  </div>
                )}
              </div>
            )}
            className={animationClass}
          />
        </div>
      </div>
    </div>
  );
};
