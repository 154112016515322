import React, { createContext, useContext } from 'react';
import classNames from 'classnames';
import CSS from './styles/Screen.module.scss';

import { useScroller } from '@moved/services';

import { ScreenTitle } from './ScreenTitle';
import { ScreenContent } from './ScreenContent';
import { ScreenActions } from './ScreenActions';

// Give the screen a context so that children can easily access the screen data
const ScreenContext = createContext();
export const useScreen = () => useContext(ScreenContext);

export const Screen = ({ screen, taskDefinition, className, children }) => {
  const scroller = useScroller();
  const context = {
    screen,
    taskDefinition,
  };
  return (
    <ScreenContext.Provider value={context}>
      <div className='scrollable height-full' ref={scroller.ref}>
        <div className={classNames('stackVertical gap-32', CSS.layout, className)}>
          { children }
        </div>
      </div>
    </ScreenContext.Provider>
  );
};
Screen.Title = ScreenTitle;
Screen.Content = ScreenContent;
Screen.Actions = ScreenActions;
