import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { CompleteCard, FieldList, Icon, Rating, Button } from '@moved/ui';
import { bookMovers, movingPlan } from '@moved/product';

import { getMove } from '../../../../moves/actions';

import { Screen, CompleteScreenActions } from '../../shared';

import CSS from './styles/ConfirmVendorLead.module.scss';

export const ConfirmVendorLead = ({ screen, taskDetails, taskDefinition, moveId, origin, goToScreen, onClose }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const resetPending = taskDefinition.selectors.useResetMoverBookingTaskPending?.();

  const selectedMovingCompany = taskDetails?.selected_moving_company_ads?.[0];

  const _changeSelection = () => {
    dispatch(taskDefinition.actions.reset(taskDetails.id))
      // refresh the move because reset affects MBT and MT on the move
      .then(r => dispatch(getMove(moveId)).then(() => r))
      .then(r => goToScreen(taskDefinition.getStartOverScreen(r)))
      .catch(err => notify.error(format.error(err)));
  };

  const fields = [
    ...movingPlan.getSummaryFields(taskDetails),
    ...bookMovers.getSummaryFields(moverBooking),
    {
      value: (
        <Button
          text='Change selection'
          size='small'
          color='secondary'
          disabled={resetPending}
          onClick={_changeSelection}
        />
      )
    },
  ];

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className={classNames('marginBottom-16',CSS.company_card)}>
          <div className='stackVertical width-full'>
            <label className='headingM contentPrimary'>{selectedMovingCompany.name}</label>
            <div className={classNames('labelS contentPrimary stackHorizontal gap-4',CSS.reviews)}>
              <Rating size='small' value={selectedMovingCompany.review_rating} precision={0.5} readOnly={true} classes={{sizeSmall:CSS.stars}} />
              { selectedMovingCompany.review_rating }
              <span className='contentSecondary'>({selectedMovingCompany.num_reviews})</span>
              <a href={selectedMovingCompany.review_url} target='_blank' className='stackHorizontal gap-2' rel="noopener noreferrer">
                <Icon library='navigation' symbol='Up-right' size='16px' color='inherit' />
                <span>View reviews</span>
              </a>
            </div>
            { selectedMovingCompany.min_hourly_rate_in_cents && (
              <div className='stackVertical'>
                <label className='labelS contentSecondary'>starting at</label>
                <span className='headingS contentPrimary'>{format.currency(selectedMovingCompany.min_hourly_rate_in_cents, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}/hr</span>
              </div>
            )}
          </div>
          { selectedMovingCompany.image && (
            <img className={CSS.icon} src={`${process.env.REACT_APP_S3_ASSET_URL}/vendors/logos/${selectedMovingCompany.image}`} alt={selectedMovingCompany.name}/>
          )}
        </div>
        <CompleteCard
          status={'approved'}
          icon={{ symbol: 'Sending', library: 'communication' }}
          title='Introduction sent'
          flavor={`An email has been sent to get a full service quote from ${selectedMovingCompany.name}`}
        >
          <FieldList list={fields} />
        </CompleteCard>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  )
};
