import { DashboardRedirect } from './components/DashboardRedirect';
import { Dashboard } from './components/Dashboard';
import { withMoveStep } from './contexts';
import { withAssistant } from '../assistant';

const routes = [
  {
    path: '/',
    name: 'DashboardRedirect',
    exact: true,
    component: DashboardRedirect,
    transition: 'crossFade',
    viewKey: ({moveId}) => `dashboard-${moveId}`,
  },
  {
    path: '/steps/:stepId(\\d+)',
    name: 'Dashboard',
    address: 'dashboard',
    exact: true,
    component: withMoveStep(withAssistant(Dashboard)),
    transition: 'crossFade',
    viewKey: ({moveId}) => `dashboard-${moveId}`,
  },
];

export default routes;
