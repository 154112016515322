import React from 'react';

import { CreditCardLogo, Button } from '@moved/ui';

import { Screen } from '../../shared';
import { LastMinuteNotice } from './LastMinuteNotice';
import { BookBreakdown } from './BookBreakdown';
import { SummaryFields } from './SummaryFields';

import CSS from './styles/MoveSummary.module.scss';

export const MoveSummary = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const { discounts = [] } = taskDefinition.selectors.useMove(moveId);

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className={CSS.content}>

          <div className={CSS.summary}>

            <LastMinuteNotice moverBooking={moverBooking} />

            <div className={CSS.info_content}>
              <SummaryFields moverBooking={moverBooking} />
            </div>
          </div>

          <BookBreakdown
            moverBooking={moverBooking}
            discounts={discounts}
          />

        </div>
      </Screen.Content>
      <Screen.Actions>
        <div className='stackVertical gap-20 items-center width-full'>
          <div className='stackHorizontal gap-8'>
            <CreditCardLogo symbol={'visa'} />
            <CreditCardLogo symbol={'mastercard'} />
            <CreditCardLogo symbol={'amex'} />
            <CreditCardLogo symbol={'apple-pay'} />
            <CreditCardLogo symbol={'discover'} />
          </div>
          <Button
            text='Next'
            size='large'
            onClick={() => nextScreen()}
            className='width-full'
          />
        </div>
      </Screen.Actions>
    </Screen>
  );
}
