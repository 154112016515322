import React from 'react';

import { format } from '@moved/services';
import { Card, Button } from '@moved/ui';

import CSS from './styles/CouponTicket.module.scss';

export const CouponTicket = ({ coupon, building, partner, cta, onClick }) => (
    <Card className='stackHorizontal gap-24 radius-12 marginTop-28 items-center stackVertical--mobile'>
      { coupon ? (
        <div className='stackVertical flex-auto'>
          <div className='stackHorizontal gap-8 '>
            <span className='displayS contentPrimary'>
              {
                coupon?.type === 'cents' ?
                  format.currency(coupon?.cents,{ minimumFractionDigits: 0, maximumFractionDigits: 0}) :
                  format.percent(coupon?.percent ?? 0)
              } Off
            </span>
          </div>
          <div className='labelS contentSecondary'>{ format.address(building,{ zipcode: false }) }</div>
        </div>
      ) : (
        <div className='stackVertical flex-auto'>
          <div className='stackHorizontal gap-8'>
            <span className='headingM contentPrimary' style={{textDecoration: 'line-through 1px solid'}}>
              $29.99
            </span>
            <span className='headingM contentSuccess'>
              Free
            </span>
          </div>
          <div className='labelS contentSecondary'>Access fee paid for by {partner?.name}</div>
        </div>
      )}
      <div className={CSS.divider}></div>
      <Button
        text={cta ?? 'Access now'}
        icon={{ library: 'navigation', symbol: 'Arrow-right' }}
        iconPosition='right'
        onClick={() => onClick?.()}
      />
    </Card>
);
