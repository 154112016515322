import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, AnimatedCheckmark, Textarea } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { useActiveMoveStep } from '../../dashboard';
import { sendMessage, useSendMessagePending } from '../actions/sendMessage';

import { useAssistant } from './AssistantWidget';

import CSS from './styles/ContactForm.module.scss';

export const ContactForm = ({ animationClass }) => {
  const dispatch = useDispatch();
  const { moveId } = useParams();
  const activeMoveStep = useActiveMoveStep();
  const pending = useSendMessagePending();
  const notify = useNotify();

  const { activeQuestion } = useAssistant();

  const [message, setMessage] = useState('');

  const submitMessage = () => {
    if(pending || !message || message.trim() === '') return false;

    dispatch(sendMessage({
      current_url: window.location.href,
      move_id: moveId,
      move_step_id: activeMoveStep?.id,
      message,
      answer_id: activeQuestion?.answer?.id,
      faq_question_id: activeQuestion?.id,
    }))
      .then(success => success ? setMessage(true) : notify.error())
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <div className='stackVertical gap-20 items-center height-full'>
      { message === true ? (
        <div className='stackVertical gap-8 items-center justify-center height-full'>
          <AnimatedCheckmark isChecked className={animationClass}/>
          <div className={classNames('stackVertical gap-8 items-center', animationClass)}>
            <h3 className='headingL contentPrimary'>Successfully sent!</h3>
            <div className='labelM contentSecondary'>
              { activeQuestion?.route_to_property ? (
                'Your property will be in touch during business hours'
              ) : (
                'We’ll be in touch within business hours (9am - 7pm ET)'
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          { activeQuestion && (
            <div className={classNames(
              'stackVertical gap-4 width-full padding-12-16 backgroundPrimary radius-8',
              animationClass,
            )}>
              <div className='labelXS contentSecondary'>Related question</div>
              <div className='labelM contentPrimary'>{activeQuestion.question}</div>
            </div>
          )}
          <div className={classNames('stackVertical gap-8 width-full', animationClass)}>
            <Textarea
              className={CSS.input}
              placeholder="Type a message..."
              value={message}
              name='message'
              onChange={({message}) => setMessage(message)}
              disabled={pending}
              autoResize
            />
            <Button
              className='width-full'
              text="Send"
              onClick={submitMessage}
              disabled={pending || !message || message.trim() === ''}
              icon={{ library: 'communication', symbol: 'Send' }}
              iconPosition='right'
            />
          </div>
        </>
      )}
    </div>
  );
};
