import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';

import { Icon, Button } from '@moved/ui';

import { useNotify, format } from '@moved/services';

import { useSnippet } from '../../../../snippets';
import { Screen } from '../../shared';

import CSS from './styles/ChooseMoved.module.scss';

export const ChooseMoved = ({ screen, nextScreen, taskDefinition }) => {
  const { moveId, id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [selectMovedPending, setSelectMovedPending] = useState();
  const [selectOtherPending, setSelectOtherPending] = useState();
  const pending = selectMovedPending || selectOtherPending;

  const moverBookingTask = taskDefinition.selectors.useMoverBookingTask(id);
  const nextButtonLabel = useSnippet('tasks.book-movers.moving-provider.confirm') ?? 'Continue';
  const optOutButtonLabel = useSnippet('tasks.book-movers.moving-provider.cancel') ?? 'No thanks';

  const selectMoved = () => {
    if(pending) return;
    setSelectMovedPending(true);
    dispatch(taskDefinition.actions.update(id, { interested_in_moved: true }))
      .then(() => !moverBookingTask?.mover_booking_id && dispatch(taskDefinition.actions.createMoverBooking(moveId, { mover_booking_task_id: id })))
      .then(() => moverBookingTask?.settings?.anonymous_ads_enabled && moverBookingTask?.selected_moving_company_ads ? dispatch(taskDefinition.actions.complete(id)) : dispatch(taskDefinition.actions.get(id)))
      .then(nextScreen)
      .catch(err => {
        setSelectMovedPending(false);
        notify.error(format.error(err))
      });
  };

  const selectOther = () => {
    if(pending) return;
    setSelectOtherPending(true);
    dispatch(taskDefinition.actions.update(id, { interested_in_moved: false }))
      .then(r => moverBookingTask?.selected_moving_company_ads ? dispatch(taskDefinition.actions.complete(id)) : r)
      .then(nextScreen)
      .catch(err => {
        setSelectOtherPending(false);
        notify.error(format.error(err))
      });
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className={CSS.map}/>
        <div className={CSS.card_row}>
          <div className={classNames(CSS.card, CSS.illustrated_bg)}>
            <h3 className='headingM contentPrimary marginBottom-8'>What is Moved?</h3>
            <div className='labelM contentSecondary stackVertical gap-8'>
              <div>Moved connects you with <em>your</em> perfect moving company.</div>
              <div>No need to shop around. Our partners at Moved have built a network of the most trusted local movers in the area.</div>
              <div>Connecting through Moved will earn you an exclusive, discounted rate.</div>
            </div>
          </div>
        </div>
        <div className={CSS.card_row}>
          <div className={CSS.card}>
            <Icon library='general' symbol='Star' size='32px' className={classNames(CSS.card_icon, CSS.blue)}/>
            <span className={CSS.card_text}>Verified 5 star customer reviews</span>
          </div>
          <div className={CSS.card}>
            <Icon library='shopping' symbol='Sale#2' size='32px' className={classNames(CSS.card_icon, CSS.green)}/>
            <span className={CSS.card_text}>Exclusive discounted rates courtesy of your property</span>
          </div>
          <div className={CSS.card}>
            <Icon library='files' symbol='File-done' size='32px' className={classNames(CSS.card_icon, CSS.red)}/>
            <span className={CSS.card_text}>Pre-approved COI (certificate of insurance)</span>
          </div>
        </div>
        <div className={CSS.card_row}>
          <div className={CSS.card}>
            <Icon library='shopping' symbol='Chart-line#1' size='32px' className={classNames(CSS.card_icon, CSS.orange)}/>
            <span className={CSS.card_text}>10X better performance than industry standard</span>
          </div>
          <div className={CSS.card}>
            <Icon library='general' symbol='Like' size='32px' className={classNames(CSS.card_icon, CSS.brown)}/>
            <span className={CSS.card_text}>Experience with moves at your community</span>
          </div>
          <div className={CSS.card}>
            <Icon library='general' symbol='User' size='32px' className={classNames(CSS.card_icon, CSS.purple)}/>
            <span className={CSS.card_text}>Moved Concierge service included</span>
          </div>
        </div>
      </Screen.Content>

      <Screen.Actions>
        <Button
          text={nextButtonLabel}
          size='large'
          onClick={selectMoved}
          disabled={pending || selectMovedPending}
          className='width-full'
        />
        { !moverBookingTask?.settings?.anonymous_ads_enabled && (
          <Button
            text={optOutButtonLabel}
            size='large'
            color='secondary'
            onClick={selectOther}
            disabled={pending || selectOtherPending}
          />
        )}
      </Screen.Actions>
    </Screen>
  );
}
