import React from 'react';

import { AccordionList } from '@moved/ui';

import { InsuranceDisclaimer } from './InsuranceDisclaimer';
import { CancellationDisclaimer } from './CancellationDisclaimer';

import CSS from './styles/QuoteFAQs.module.scss';

const sections = [
  {
    label: 'Who is my moving company?',
    content: (
      <div>
        Your move will be matched with a top-tier company in Moved’s vetted
        network of moving companies. You’ll receive an email introducing you to
        the moving company shortly after you’ve booked the move.
      </div>
    ),
  },
  {
    label: 'When will the movers show up?',
    content: (
      <div>
        Your time preference is shared with the moving company, who will provide
        you with a specific arrival window. For example, if the provided arrival
        window is 8AM - 11AM, the movers can arrive <em>as early</em> as 8AM
        (please be ready for them) or <em>as late</em> as 11AM (make sure to
        account for enough time to complete the move according to any
        reservations, traffic, etc.)
      </div>
    ),
  },
  {
    label: 'What if I need to cancel or reschedule?',
    content: (
      <div>
        <CancellationDisclaimer />
      </div>
    ),
  },
  {
    label: 'Is my move insured?',
    content: (
      <div>
        <InsuranceDisclaimer />
      </div>
    ),
  },
  {
    label: 'What is additional moving insurance?',
    content: (
      <div>
        Additional moving insurance can be purchased to cover the cost of
        replacing your belongings if they are damaged during the move. To
        determine if you need to purchase it,{' '}
        <a href="https://moved.com/2018/05/03/2018-05-03-do-you-need-moving-insurance/" target="_blank" rel="noopener noreferrer">this blog post</a>{' '}
        may be helpful. We recommend getting additional moving insurance for any
        high-value items. You can obtain this extra coverage by{' '}
        <a href="https://go.moved.com/insurance" target="_blank" rel="noopener noreferrer">submitting a quote request</a>.
      </div>
    ),
  },
  {
    label: 'Will the movers disassemble and reassemble my items?',
    content: (
      <div>
        Yes! Standard disassembly of basic furniture (i.e. basic metal bed
        frames) is included. Disassembly/reassembly of more complicated
        furniture items such as cribs, bunk beds, or murphy beds may be subject
        to an additional fee. After booking you'll be introduced to the moving
        company and you should let them know if you have any such items - e.g.
        murphy beds, bunk beds, IKEA closets, cribs, wardrobes, custom shelving,
        chandeliers, pianos, and any other complex, time-consuming items.
      </div>
    ),
  },
];

export const QuoteFAQs = (props) => (
  <div className={CSS.questions}>
    <h3 className={'mb-20'}>Questions?</h3>
    <AccordionList panels={sections}/>
  </div>
);
