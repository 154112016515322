import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams,useHistory } from "react-router-dom";
import classNames from 'classnames';

import { Icon, PopOver, ConfirmationModal, AtomSpinner, Button } from '@moved/ui';
import { useNotify, format, useModal } from '@moved/services';

import { Screen } from '../../shared';
import { OtherVehicles } from './OtherVehicles';

import CSS from './styles/VehiclesSummary.module.scss';

const formatVehicleName = vehicle => `${vehicle.make} ${vehicle.model}`;

const VehicleCard = ({ vehicle, activeRequest, taskDefinition, taskBaseRoute}) => {
  const history = useHistory();
  const { id } = useParams();
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();

  // Remove vehicle details handler
  const handleDeleteVehicleDetails = (vehicle, vehicles) => {

    // check if vehicle to be removed is the only vehicle
    if((vehicles.length === 1)) {
      return Promise.all([
        dispatch(taskDefinition.actions.cancelVehicleDetails(activeRequest.id, vehicle.id)),
        dispatch(taskDefinition.actions.reset(id))
      ])
      .then(() => notify.default(`Details for ${formatVehicleName(vehicle)} have been removed.`))
      .catch(error => notify.error(format.error(error)));
    }

    return dispatch(taskDefinition.actions.cancelVehicleDetails(activeRequest.id, vehicle.id))
      .then(() => notify.default(`Details for ${formatVehicleName(vehicle)} have been removed.`))
      .catch(error => notify.error(format.error(error)));
  }


  // remove vehicle details confirmation modal
  const openRemoveModal = (vehicleDetails, vehicles) => modal.open(
    <ConfirmationModal
      title={`Cancel registration for ${formatVehicleName(vehicleDetails)}`}
      copy={(
        <>
          Are you sure you wish to delete all details for <strong>{formatVehicleName(vehicleDetails)}</strong> <em>({vehicleDetails.year})</em> and withdraw its registration?
          This action cannot be undone.
        </>
      )}
      confirmText='Confirm'
      cancelText='Never mind'
      onConfirm={() => handleDeleteVehicleDetails(vehicle, vehicles)}
    />
  );

  return (
    <div className={CSS.card}>
      <div className={CSS.popover}>
        <PopOver
          id={`more_${vehicle.id}`}
          customClass={CSS.vehicle_popover}
          className={classNames(CSS.icon,CSS.icon_more)}
          hideArrow="true"
          placement="bottom-end"
          trigger="click"
          delayHide={400}
          tooltip={(
            <div id={'popover_more_options'}>
              <div
                className={CSS.popover_action}
                onClick={e => {
                  e.preventDefault();
                  history.push(`${taskBaseRoute}/${id}/details/${vehicle.id}`);
                }}
              >
                <Icon symbol={'Edit'} library={'general'} size={'20px'} className={CSS.popover_icon} />
                <span className={CSS.popover_link}>Edit</span>
              </div>
              <div className={classNames(CSS.popover_action, CSS.delete)} onClick={() => openRemoveModal(vehicle, activeRequest.vehicles)}>
                <Icon symbol={'Close'} library={'navigation'} size={'20px'} className={CSS.popover_icon} />
                <span className={CSS.popover_link}>Cancel registration</span>
              </div>
            </div>
          )}
          stopPropagation
        >
          {() => (
            <Icon symbol='Other#2' library='general' className={CSS.more_icon} />
          )}
        </PopOver>
      </div>
      <div className={CSS.circle} />
      <h3 className={CSS.name}>{formatVehicleName(vehicle)}</h3>
      <p className={CSS.year}>{vehicle.year}</p>
        <div className={CSS.vehicle_icon}>
          <div className={CSS.spacer} />
          <Icon
            symbol={format.capitalize(vehicle.vehicle_type)}
            library={'vehicles'}
            size={'25%'}
          />
        </div>
    </div>
  );
};


export const VehiclesSummary = ({ screen, nextScreen, taskDefinition, taskDetails, taskBaseRoute }) => {
  // HOOKS
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const notify = useNotify();

  // REDUX
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const updatePending = taskDefinition.selectors.useVehicleRegistrationPending();
  const submitPending = taskDefinition.selectors.useUpdateVehiclePending();
  const loading = taskDefinition.selectors.useVehicleRegistrationPending();

  // STATE
  const pending = updatePending || submitPending;

  const addVehicle = () => {
    dispatch(taskDefinition.actions.createVehicle(activeRequest.id, {}))
    .then(() => history.push(`${taskBaseRoute}/${id}`))
    .catch(error => notify.error(format.error(error)));
  };

  const declineVehicles = e => {
    e.preventDefault();

    return dispatch(taskDefinition.actions.update(id, { 'has_vehicles': false }))
      .then(nextScreen)
      .catch(error => notify.error(format.error(error)));
  };

  const handleSubmit = e => {
    e.preventDefault();
    return dispatch(taskDefinition.actions.submitRequest(activeRequest.id))
      .then(nextScreen)
      .catch(error => notify.error(format.error(error)));

  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <OtherVehicles taskDetails={taskDetails} />
        )}
      >
        {loading ? (
          <AtomSpinner />
        ) : (
          <div className={CSS.card_holder}>
            {activeRequest.vehicles.filter(vehicle => vehicle.vehicle_type != null).map(vehicle => (
              <VehicleCard
                key={vehicle.id}
                vehicle={vehicle}
                taskDefinition={taskDefinition}
                taskBaseRoute={taskBaseRoute}
                activeRequest={activeRequest}
              />
            ))}
            <div className={CSS.add} onClick={addVehicle}>
              <div className={CSS.add_button}>
                <Icon symbol={'Plus'} library={'navigation'} size={'24px'} className={CSS.add_icon} />
              </div>
              <div className={CSS.add_text}>Add another vehicle</div>
            </div>
          </div>
        )}
      </Screen.Content>
      <Screen.Actions>
        {activeRequest.vehicles.length > 0 ? (
          <Button
            text='Submit'
            size='large'
            onClick={handleSubmit}
            disabled={pending}
            className='width-full'
          />
        ) : (
          <Button
            text='No additional vehicles'
            size='large'
            color='secondary'
            onClick={declineVehicles}
            disabled={pending}
            className='width-full'
          />
        )}
      </Screen.Actions>
    </Screen>
  );
};
