import { usePrevious } from 'react-use';
import { useTransition, animated } from 'react-spring';
import classNames from 'classnames';

import { DynamicFormScreen } from '../types/shared/components/DynamicFormScreen';

import CSS from './styles/TaskScreenTransition.module.scss';

// component for rendering each screen component and transitioning between them
export const TaskScreenTransition = ({ screen, index, ...passProps }) => {
  const previousIndex = usePrevious(index);

  const transitions = useTransition(screen, screen => `${screen.slug}${screen.context ? screen.context : ''}`, {
    initial: { opacity: 1, top: '0%' },
    from: { opacity: 0, top: index > previousIndex ? '100%' : '-100%' },
    enter: { opacity: 1, top: '0%' },
    leave: { opacity: 0, top: index > previousIndex ? '-100%' : '100%' },
  });

  return transitions.map(({ item:currentScreen, props:animationProps }) => {
    const ScreenComponent = currentScreen.component ?? DynamicFormScreen;
    return (
      <animated.div
        key={`${currentScreen.slug}${currentScreen?.context ?? ''}`}
        style={animationProps}
        className={classNames(CSS.animatedScreen, CSS[`task_${currentScreen.slug}`])}
      >
        <ScreenComponent screen={currentScreen} {...passProps} />
      </animated.div>
    );
  })
}
