import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useDrawer, useModal, useNotify, format } from '@moved/services';
import { Drawer } from '@moved/ui';

import { useMove } from '../../../moves/actions/getMove';
import { EditAddressModal } from '../../modals/EditAddressModal';
import { useTask } from '../../actions/getTask';
import { getInsurancePurchaseIntent, useInsurancePurchaseIntent } from './actions/get';
import { createInsurancePurchaseIntent } from './actions/create';
import { PurchaseRentersInsuranceFlow } from './PurchaseRentersInsuranceFlow';

export const PurchaseRentersInsuranceDrawer = ({ taskId, moveId }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const drawer = useDrawer();
  const personalTask = useTask(taskId);
  const move = useMove(moveId);

  const purchaseIntent = useInsurancePurchaseIntent(personalTask?.task_details_id);

  const fetchPurchaseIntent = useCallback(
    (personalTask) => dispatch(
      personalTask?.task_details_id ?
        getInsurancePurchaseIntent(personalTask.task_details_id) :
        createInsurancePurchaseIntent(personalTask.id)
    ).catch(err => notify.error(format.error(err))),
    [dispatch, notify]
  );

  useEffect(() => {
    if(!personalTask || purchaseIntent) return;
    if(move?.destination_address == null) {
      window.setTimeout(() => {
        modal.open(
          <EditAddressModal
            name='destination'
            move={move}
            description='We will need your new address to find available providers and rates in your area.'
          />,
          {
            onClose: (updatedMove) => {
              if(updatedMove?.destination_address == null) drawer.close();
              else fetchPurchaseIntent(personalTask);
            },
          },
        );
      }, 400); // slight delay to allow the drawer to open before the modal
    }
    else fetchPurchaseIntent(personalTask);
  }, [taskId, fetchPurchaseIntent]); // eslint-disable-line

  return (
    <Drawer>
      <PurchaseRentersInsuranceFlow
        personalTask={personalTask}
        purchaseIntent={purchaseIntent}
        move={move}
        dashboardRoute={`/moves/${moveId}/personal-tasks`}
      />
    </Drawer>
  );
}
