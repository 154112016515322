import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@moved/ui';
import { format, useNotify } from '@moved/services';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

import { createRequest, useCreateRequestPending } from '../actions/createRequest';

export const Summary = ({ screen, taskDetails, taskDefinition, origin, moveId, goToScreen, onClose }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const requiresApproval = taskDetails?.settings?.requires_approval;
  const resetPending = useCreateRequestPending();

  const handleReset = () => {
    if(resetPending) return;
    dispatch(createRequest(taskDetails.id))
      // refresh the move because reset affects the MT on the move
      .then(r => dispatch(getMove(moveId)).then(() => r))
      .then(r => goToScreen(taskDefinition.getStartOverScreen(r)))
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      moveId={moveId}
      onClose={onClose}
      extraAttributes={[
        !(activeRequest.status === 'approved' && requiresApproval) && {
          value: (
            <Button
              size='small'
              color='secondary'
              onClick={handleReset}
              text='Change selection'
              disabled={resetPending}
            />
          )
        }
      ].filter(Boolean)}
    />
  );

};
