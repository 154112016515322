import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { DateTime } from 'luxon';

import { Icon, Card, Button, Form, FormDateSelect } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { updateMove, getMoveSummaries } from '../../../moves/actions';
import { useUpdateMovePending } from '../../../moves/actions/selectors';
import { useWizard, Content, Actions } from './OnboardingWizard';

import dateGraphic from './images/date-graphic.png';
import CSS from './StepDate.module.scss';

export const StepDate = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { move, next, prev } = useWizard();

  const pending = useUpdateMovePending();

  const initialDate = (
    move?.move_date ?? // previously entered value
    (move?.move_steps ?? []).find(step => step?.date)?.date // prepopulated from the lease data
  ) ?? '';

  const [isFormValid, setIsFormValid] = useState(initialDate != null);

  const submit = (data) => {
    dispatch(updateMove(move?.id, data))
      .then(() => dispatch(getMoveSummaries())) // refresh move summaries to reflect the new date
      .then(() => next())
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <>
      <Content>

        <div className='stackVertical gap-4'>
          <div className='headingL contentPrimary'>
            <span className='contentSecondary marginRight-12'>2/3</span>
            Have a date in mind?
          </div>
          <div className='labelM contentSecondary'>
            No stress if your move date isn’t set yet - you can change it anytime. Selecting a date now just helps us track your progress and keep things moving.
          </div>
        </div>

        <Form
          name={'date-form'}
          onSubmit={submit}
          validation={Yup.object({
            move_date: Yup.string().nullable().required('required'),
          })}
          onChange={({ move_date }) => setIsFormValid(move_date != null)}
        >
          <div className='stackVertical gap-28'>
            <div className='stackHorizontal gap-24 items-center'>
              <Card className='padding-8 radius-full'>
                <Icon library='code' symbol='Date' />
              </Card>
              <FormDateSelect
                name='move_date'
                label='Move date'
                value={initialDate}
                className='width-full'
                minDate={DateTime.now().toISODate()}
                hint='You can change this any time.'
              />
            </div>
          </div>
        </Form>

        <Actions>
          <Button
            text='Back'
            icon={{ library: 'navigation', symbol: 'Arrow-left' }}
            onClick={prev}
            color='secondary'
            disabled={pending}
          />
          <Button
            text='Next'
            icon={{ library: 'navigation', symbol: 'Arrow-right' }}
            iconPosition='right'
            form={'date-form'}
            disabled={!isFormValid || pending}
          />
        </Actions>

      </Content>

      <div className={CSS.sideImage}>
        <img src={dateGraphic} alt='Date icon' />
        <div className={CSS.overlay}>
          <div className={CSS.pinShadow} />
          <Icon library='code' symbol='Date' size='48px' className={CSS.pin} color='black' />
        </div>
      </div>

    </>
  );
};
