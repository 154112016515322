import React from 'react';
import { AtomSpinner } from '@moved/ui';

import { Screen } from './Screen';

export const ScreenLoading = ({ message }) => {
  return (
    <Screen>
      <div className='stackVertical gap-24 items-center'>
        <AtomSpinner size={60} />
        { message && <div className='labelL contentSecondary'>{message}</div>}
      </div>
    </Screen>
  );
}
