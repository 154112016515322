import React from 'react';

import { Four04, Footer } from '@moved/product';

export const SidebarPageNotFound = () => (
  <div className='stackVertical height-full'>
    <Four04 />
    <Footer />
  </div>
);
