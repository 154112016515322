import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { groupBy } from 'lodash';

import { format, useNotify } from '@moved/services';
import { AtomSpinner, Button, ExpandableCardList } from '@moved/ui';

import { InternetOfferCard } from './InternetOfferCard';

import CSS from './styles/ProviderServices.module.scss';

import { Screen } from '../../shared';

import {
  getProviderServices,
  useProviderServices,
  useProviderServicesPending,
} from '../actions/getProviderServices';
import {
  getProviderLink,
  useProviderLinkPending,
} from '../actions/getProviderLink';
import {
  submit,
  useSubmitCableInternetTaskPending,
} from '../actions/submit';

export const ProviderServices = ({
  nextScreen,
  screen,
  taskDefinition,
}) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  const providerServices = useProviderServices(id);

  const servicesPending = useProviderServicesPending();
  const linkPending = useProviderLinkPending();
  const submitPending = useSubmitCableInternetTaskPending();

  useEffect(() => {
    dispatch(getProviderServices(id))
      .catch(() => notify.error('Error loading cable internet options'))
  }, [id, dispatch, notify]);

  const handleSubmit = () => {
    if (submitPending) return;
    dispatch(submit(id))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  // Generates external link when user clicks CTA in InternetOfferCard
  const handleProviderClick = (offer, order) => {
    if (offer?.provider?.id == null || linkPending) return;
    const metadata = {
      origin: 'pricing',
      provider_order: order,
      cta_text: 'Choose this plan',
    };
    dispatch(getProviderLink(id, offer?.provider?.id, metadata))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => notify.error(format.error(err)));
  };

  if (servicesPending) return <AtomSpinner />;

  const groupedByProvider = groupBy(providerServices, (d) => d.provider.id);

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <section className={classNames(CSS.container, 'stackVertical gap-16')}>
          {Object.entries(groupedByProvider).map(([providerId, providerOptions], providerIndex) => (
            <ExpandableCardList
              key={providerId}
              hideCta='Hide plans'
              showCta='View all plans'
              cardList={providerOptions.map(provider => (
                <InternetOfferCard
                  key={provider.id}
                  internetOffer={provider}
                  onClick={(offer) => handleProviderClick(offer,providerIndex)}
                />
              ))}
            />
          ))}
        </section>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Complete'
          size='large'
          onClick={handleSubmit}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
