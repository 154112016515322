import { Button, Notebox } from '@moved/ui';

import { useAssistant } from './AssistantWidget';
import { PanelHeader } from './PanelHeader';
import { Faq } from './Faq';

export const FaqsPanel = ({ animationClass }) => {
  const {
    questions,
    availablePanels,
    switchPanel,
  } = useAssistant();
  return (
    <div className='stackVertical gap-24 height-full'>
      <PanelHeader
        title='FAQs'
        animationClass={animationClass}
        action={availablePanels.includes('create') && (
          <Button
            onClick={() => switchPanel('create')}
            text='Add'
            icon={{ library: 'navigation', symbol: 'Plus' }}
            color='secondary'
            size='small'
          />
        )}
      />
      <div className='height-full width-full stackVertical gap-8 scrollable'>
        { questions?.map((faq) => (
          <Faq
            key={faq.id}
            faq={faq}
            className={animationClass}
          />
        ))}
        { availablePanels.includes('contact') && (
          <div className='flex-grow stackVertical justify-end'>
            <Notebox
              color='neutral'
              icon={{ library: 'communication', symbol: 'Chat-smile' }}
              title='Need more help?'
              body={(
                <div className='stackVertical gap-8'>
                  <div className='labelS'>
                    Save time by starting your support request online and we'll connect you to an expert.
                  </div>
                  <div className='faux-link' onClick={() => switchPanel('contact')}>
                    Send us your question
                  </div>
                </div>
              )}
              className={animationClass}
            />
          </div>
        )}
      </div>
    </div>
  );
};
