import React from 'react';
import classNames from 'classnames';

import { Icon, Notebox } from '@moved/ui';
import { format, helpers } from '@moved/services';

import CSS from './styles/OtherPets.module.scss';

const PetSummary = ({ pet }) => {
  const statusMap = { 'pending-approval': 'In review'};
  return (
    <div className={CSS.panel}>
      {pet.photo_url ? (
        <div
          className={CSS.splash}
          style={{ backgroundImage: `url('${pet.photo_url}')`}}
        />
      ) : (
        <div className={CSS.splash}>
          <Icon
            symbol={format.capitalize(pet.pet_type)}
            library={'animals'}
            size={'30px'}
          />
        </div>
      )}

      <div className={CSS.grow}>
        <div className={CSS.name}>
          <p>{pet.name}</p>
          <span>Name</span>
        </div>
        <div className={CSS.breed}>
          <p>{pet.breed}</p>
          <span>Breed</span>
        </div>
        <div className={CSS.submitted_by}>
          <p>{format.fullname(pet.submitted_by)}</p>
          <span>Provided by</span>
        </div>
      </div>

      <div className={CSS.status}>
        <div className={classNames(CSS.pill,CSS[(pet?.status ?? '').toLowerCase()])}>
          {statusMap[pet.status] ?? format.capitalize(helpers.convertNamingConvention(pet.status,'kebab','space'))}
        </div>
      </div>
    </div>
  );
};

export const OtherPets = ({ taskDetails }) => {
  if((taskDetails?.other_pets_on_lease ?? []).length < 1) return false;
  return (
    <Notebox
      color='info'
      icon={{ symbol: 'Warning-1-circle', library: 'code' }}
      title='Other animals associated with your lease'
      body={(
        <div className='stackVertical gap-12 width-full paddingTop-4'>
          {taskDetails?.other_pets_on_lease?.map(pet => (
            <PetSummary key={pet.id} pet={pet}/>
          ))}
        </div>
      )}
    />
  );
};
