import { choiceTask as helpers } from '@moved/product';

import { BaseTask } from '../shared/components/BaseTask';

import * as actions from './actions';
import { useChoiceTask } from './actions/get';

import { screens } from './screens';

export class Choice extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: useChoiceTask,
      }
    });
  }

  static label = 'Choice';
  static slug = 'choice';
  static icon = 'Question';
  static helpers = helpers;

  _flowBuilder() {
    return [
      this.screens['question'],
      this.screens['summary'],
    ];
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
