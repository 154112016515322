import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { get, merge } from 'lodash';

import { useNotify, format } from '@moved/services';
import { Button } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import { Screen } from '../../shared';

import CSS from './styles/Orientation.module.scss';

export const OrientationScreens = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  const completePending = taskDefinition.selectors.useCompletePending();
  const createPending = taskDefinition.selectors.useCreateOrientationRequestPending();
  const taskDetails = taskDefinition.selectors.useTaskable(id);

  const pending = completePending || createPending;

  const handleNext = () => {
    const data = {
      [screen.slug]: true,
      status: 'completed',
    }
    const screens = (taskDefinition.flow ?? []).map(screen => screen.slug);
    const activeRequest = get(taskDetails,'requests[0]');

    // Create request if none exists
    if(!activeRequest) {
      if(screens[screens.length-2] === screen.slug) {
        return dispatch(taskDefinition.actions.createRequest(id))
          .then(() => dispatch(taskDefinition.actions.complete(id, data)))
          .then(resp => dispatch(taskDefinition.actions.submitRequest(resp.requests[0].id)))
          .then(() => nextScreen())
          .catch(error => notify.error(format.error(error)));
      } else {
        return dispatch(taskDefinition.actions.createRequest(id))
          .then(() => dispatch(taskDefinition.actions.complete(id, data)))
          .then(() => nextScreen())
          .catch(error => notify.error(format.error(error)));
      }
    }
    // Submit most recent request on last screen
    // if status is 'draft'
    else if(screens[screens.length-2] === screen.slug && activeRequest.status === 'draft') {
      return dispatch(taskDefinition.actions.complete(id, data))
        .then(() => dispatch(taskDefinition.actions.submitRequest(activeRequest.id)))
        .then(() => nextScreen())
        .catch(error => notify.error(format.error(error)));
    }
    // normal screen submit
    else {
      return dispatch(taskDefinition.actions.complete(id, data))
        .then(() => nextScreen())
        .catch(error => notify.error(format.error(error)));
    }
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className='stackHorizontal gap-20 justify-center'>
          <div className={CSS.container}>
            <ResidentContentRenderer content={merge({id:screen.id},screen.content)} />
          </div>
          {screen.side_column_image_url && (
            <div className={CSS.side_column} style={{ backgroundImage: `url(${screen.side_column_image_url})`}} />
          )}
        </div>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text={screen.cta_text}
          size='large'
          disabled={pending}
          onClick={handleNext}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );

};
