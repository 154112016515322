import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ASSISTANT_SEND_MESSAGE',
  request: (data={}) => request.post(`/residents/contact`, data),
};

export const {
  reducer,
  action: sendMessage,
  usePending: useSendMessagePending,
} = generate.redux(creator);
