import React from 'react';

import { ResidentTagRenderer } from '../../../../common';
import { useSnippet } from '../../../../snippets/hooks/useSnippet';

import { useScreen } from './Screen';

// Render quotes screen
export const ScreenTitle = () => {
  const { screen, taskDefinition } = useScreen();
  const snippetPrefix = `tasks.${taskDefinition?.slug}.${screen.slug}`;

  const title = useSnippet(`${snippetPrefix}.title`, 'Screen title') ?? screen.title;
  const subtitle = useSnippet(`${snippetPrefix}.subtitle`, 'Screen subtitle') ?? screen.subtitle;

  if(!title && !subtitle) return null;
  return (
    <div className='stackVertical gap-4 paddingTop-4 txt-center'>
      { title && (
        (typeof title === 'string') ? (
          <ResidentTagRenderer content={title} as={'h1'} className='displayS contentPrimary' />
        ) : (
          <h1 className='displayS contentPrimary'>{ title }</h1>
        )
      )}
      { subtitle && (
        (typeof subtitle === 'string') ? (
          <ResidentTagRenderer content={subtitle} as={'h4'} className='labelM contentSecondary' />
        ) : (
          <h4 className='labelM contentSecondary'>{ subtitle }</h4>
        )
      )}
    </div>
  )
};
