import React from 'react';
import { DateTime } from 'luxon';

import { useUser } from '@moved/services';
import { CompleteCard, Accordion, FieldList } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { useActiveBuildingMoveTask } from '../../../contexts/BuildingMoveTaskContext';
import { Screen } from './Screen';
import { CompleteScreenActions } from './CompleteScreenActions';
import { PastRequest } from './PastRequest';
import { OnHoldArtifact } from './OnHoldArtifact';
import { ServiceProviderAd } from './ServiceProviderAd';

export const SummaryScreen = ({
  origin,
  screen,
  moveId,
  onClose,
  extraAttributes=[], // extra attributes to append to the list of field attributes
  extraContent, // arbitrary content to render at the bottom inside the complete card
  noteboxes, // additional noteboxes to render above the complete card
}) => {

  const { user } = useUser();
  const {
    activeTaskDefinition,
    activeTaskDetails,
    activeTaskSummary,
    activeBuildingMoveTask,
  } = useActiveBuildingMoveTask();

  const { helpers, slug: taskType, getStatusCopy } = activeTaskDefinition;

  // fetch necessary records
  const artifacts = helpers.getArtifacts(activeTaskSummary) || [];
  const activeArtifact = artifacts[0];
  const overrides = helpers.getOverrides(activeTaskSummary) || [];
  const activeOverride = overrides[0];
  const activeRequest = helpers.getActiveRequest(activeTaskDetails);

  // display decision helpers
  let isRequestPriority = (
    activeTaskDefinition?.config?.supportsOnHoldArtifact &&
    ['pending-approval','rejected'].includes(activeRequest?.status)
  );
  let isActiveRequestHistory = activeRequest?.status === 'cancelled';

  // disambiguate when rejected activeRequest and artifact/override are present
  // is artifact on-hold from before rejection or is rejected request replaced by artifact?
  if ((activeArtifact || activeOverride) && activeRequest?.status === 'rejected') {
    const overrideOrArtifact = activeArtifact || activeOverride;
    const artifactTimestamp = overrideOrArtifact?.created_at && DateTime.fromISO(overrideOrArtifact.created_at);
    const requestTimestamp = DateTime.fromISO(activeRequest.responded_at);
    // if activeRequest timestamp is newer, show activeRequest for summary (default to this above)
    // else show artifact summary and append the activeRequest to the history if the artifact was not created by user
    if (artifactTimestamp > requestTimestamp) {
      isRequestPriority = false;
      // edge case with history (add back in the activeRequest if it didn't lead to the current artifact / override)
      isActiveRequestHistory = overrideOrArtifact?.created_by_user?.id !== user.id;
    }
  }

  const requestHistory = [
    isActiveRequestHistory && activeRequest,
    ...(helpers.getRequestHistory(activeTaskDetails) || []),
  ].filter(v=>v);

  let status, statusCopy, attributes=[], notes;
  if (activeOverride && !isRequestPriority) {
    status = 'approved';
    statusCopy = getStatusCopy({ status: 'override' }, activeTaskDetails);
    attributes = helpers.getOverrideFields(activeOverride);
  }
  else if (activeArtifact && !isRequestPriority) {
    status = 'approved';
    statusCopy = getStatusCopy({ status: 'approved' }, activeTaskDetails);
    attributes = helpers.getArtifactFields(activeArtifact);
  }
   else if (activeRequest) {
    status = activeRequest?.status;
    statusCopy = getStatusCopy(activeRequest, activeTaskDetails);
    attributes = helpers.getRequestFields(activeRequest);
    notes = activeRequest?.notes;
  }

  return (
    <Screen taskDefinition={activeTaskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={noteboxes}
      >
        <div className='stackVertical gap-48'>
          <CompleteCard
            status={status}
            title={(
              <Snippet tag={`tasks.${taskType}.summary.${status}.title`}>
                { statusCopy?.summary?.title }
              </Snippet>
            )}
            color={statusCopy?.summary?.color}
            flavor={(
              <Snippet tag={`tasks.${taskType}.summary.${status}.flavor`}>
                { statusCopy?.summary?.flavor }
              </Snippet>
            )}
            notes={notes}
            detailsTitle={statusCopy?.summary?.detailsTitle}
          >
            <FieldList list={[
              ...attributes,
              ...extraAttributes,
            ]} />
            { extraContent }
          </CompleteCard>

          {/* if there is a pending, expired, or rejected request, show any artifacts as on hold */}
          {['pending-approval','rejected','expired'].includes(activeRequest?.status) &&
            activeTaskDefinition.config.supportsOnHoldArtifact && (
            artifacts.map(artifact => (
              <OnHoldArtifact
                artifact={artifact}
                task={activeTaskDefinition}
                key={artifact.id}
              />
            ))
          )}

          <ServiceProviderAd
            ad={activeTaskDetails?.service_provider_ads?.at(0)}
            buildingMoveTaskId={activeBuildingMoveTask.id}
          />

          { requestHistory.length > 0 && (
            <Accordion
              label={(<h5 className='m-0'>History</h5>)}
              content={requestHistory.map(request => (
                <PastRequest
                  key={request.id}
                  request={{
                    ...request,
                    settings: activeTaskDetails.settings,
                  }}
                />
              ))}
            />
          )}
        </div>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />

    </Screen>
  )
};
