import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import * as Yup from 'yup';

import { DynamicForm, AtomSpinner, Button } from '@moved/ui';
import { useNotify, useUser, format } from '@moved/services';

import { Screen } from '../../shared';
import { getS3UploadUrl } from "../../shared/actions";
import { InsuranceUpsellNotebox } from './InsuranceUpsellNotebox';
import CSS from './styles/PetDetails.module.scss'

export const PetDetails = ({ screen, nextScreen, taskDefinition }) => {
  const { id, screenContext } = useParams();
  const { user } = useUser();
  const dispatch = useDispatch();
  const notify = useNotify();

  const taskable = taskDefinition.selectors.useTaskable(id);
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskable);
  const activePet = taskDefinition.getActivePet(taskable, screenContext);
  const updatePending = taskDefinition.selectors.usePetRegistrationPending();
  const requestPending = taskDefinition.selectors.useUpdatePetRequestPending();
  const loading = taskDefinition.selectors.usePetRegistrationPending();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);

  // STATE
  const pending = updatePending || requestPending;

  const fields = [
    {
      type: 'text',
      name: 'breed',
      label: 'Breed',
      flavor: 'Samoyed, Labrador, Border collie, etc.',
      required: true,
      half: true,
      value: activePet?.breed,
    },
    {
      type: 'integer',
      name: 'age_years',
      label: 'Age ( Years )',
      required: 'Age is required',
      half: true,
      className: CSS.tiny,
      value: activePet?.age_years,
    },
    {
      type: 'difference',
      name: 'age_months',
      label: 'Months',
      required: 'Months is required',
      difference: 0,
      half: true,
      className: CSS.tiny,
      value: activePet?.age_months ?? 0,
    },
    {
      type: 'number',
      name: 'weight_in_lbs',
      label: 'Weight (lbs)',
      required: true,
      half: true,
      value: activePet?.weight_in_lbs,
    },
    {
      type: 'text',
      name: 'color',
      label: 'Color',
      flavor: 'Beige, black, white, etc.',
      required: true,
      half: true,
      value: activePet?.color,
    },
    {
      type: 'select',
      name: 'legal_owner_tenant_id',
      label: 'Owner',
      required: true,
      options:  taskable.tenants_on_lease.map(tenant => ({
        label: format.fullname(tenant),
        value: tenant.id,
      })),
      value: activePet?.owner?.id ?? (taskable?.tenants_on_lease ?? []).find(tenant => tenant.user_id === user.id)?.id,
    },
    {
      type: 'slideToggle',
      name: 'gender',
      label: 'Gender',
      required: true,
      half: true,
      options: [
        { value: 'female', label: 'Female' },
        { value: 'male', label: 'Male' },
      ],
      value: activePet?.gender,
    },
    {
      type: 'slideToggle',
      name: 'is_service_animal',
      label: 'Is this a service animal?',
      required: true,
      half: true,
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
      value: activePet?.is_service_animal,
    },
    taskable.settings.service_animal_documentation_enabled && {
      type: 'uploader',
      name: 'service_animal_documentation_url',
      label: (<span className={CSS.service_animal_documentation_label}>Upload service animal documentation</span>),
      className: CSS.uploader_label,
      required: 'Proof of service animal documentation is required',
      s3UploadRequest: (fileData) => dispatch(getS3UploadUrl(building.id,{
        ...fileData,
        subdirectory: `pet-registration`,
      })),
      options: {
        accept: {
          'image/jpg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc','.docx'],
        },
        useUniqueFilenames: true,
      },
      value: activePet?.service_animal_documentation_url,
      custom: {
        hidden: {
          compare: {field: 'is_service_animal'},
          boolean: 'not',
          value: true,
        }
      }
    },
  ].filter(Boolean);

  const validation = Yup.object().shape({
    age_months: Yup.number().max(11,'Cannot be more than 11 months'),
  });

  const _handleSubmit = formData => {
    if(pending) return;

    dispatch(taskDefinition.actions.updatePet(activeRequest.id, activePet.id, formData))
      .then(nextScreen)
      .catch(err =>  notify.error(format.error(err)));

  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <InsuranceUpsellNotebox />
        )}
      >
        { !loading ? (
          <DynamicForm
            id='pet-details'
            fields={fields}
            formStyle={'underline'}
            onSubmit={_handleSubmit}
            disabled={pending}
            validation={validation}
          />
        ) : (
          <AtomSpinner />
        )}
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='pet-details'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
