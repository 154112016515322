import React from 'react';
import classNames from 'classnames';

import { Notebox } from '@moved/ui';

import { ResidentTagRenderer } from '../../../../common';
import { useSnippet } from '../../../../snippets/hooks/useSnippet';
import { useScreen } from './Screen';

import CSS from './styles/ScreenContent.module.scss';

export const ScreenContent = ({ className, noteboxes, children }) => {
  const { screen, taskDefinition } = useScreen();
  const snippetPrefix = `tasks.${taskDefinition?.slug}.${screen.slug}`;
  const screenNotebox = {
    title: useSnippet(`${snippetPrefix}.noteBox.title`, 'Notebox title'),
    body: useSnippet(`${snippetPrefix}.noteBox.copy`, 'Notebox copy'),
    color: useSnippet(`${snippetPrefix}.noteBox.color`, 'Notebox color'),
    icon: {
      symbol: useSnippet(`${snippetPrefix}.noteBox.icon.symbol`, 'Notebox icon symbol'),
      library: useSnippet(`${snippetPrefix}.noteBox.icon.library`, 'Notebox icon library'),
    },
  };
  const isScreenNotebox = screenNotebox.title != null || screenNotebox.body != null;

  return (
    <div className={classNames(CSS.content,className)}>
      {(noteboxes || isScreenNotebox) && (
        <div className='stackVertical gap-24 width-full' style={{maxWidth:'880px'}}>
          { isScreenNotebox && (
            <Notebox
              color={screenNotebox.color}
              icon={screenNotebox.icon?.symbol && screenNotebox.icon}
              title={screenNotebox.title}
              body={
                (typeof screenNotebox.body === 'string') ? (
                  <ResidentTagRenderer content={screenNotebox.body} />
                ) : (
                  screenNotebox.body
                )
              }
            />
          )}
          { noteboxes }
        </div>
      )}
      <div className={CSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
        { children }
      </div>
    </div>
  );
}
