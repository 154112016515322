import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useNotify, useUser, format } from '@moved/services';
import { DynamicForm, Notebox, Button  } from '@moved/ui';

import { Screen } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';
import { ProviderDetails } from './ProviderDetails';

export const AccountDetails = ({ screen, taskDefinition, nextScreen, previousScreen }) => {
  const { id } = useParams();
  const { user } = useUser();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);
  const utilitySetup = taskDefinition.selectors.useUtilitySetup(id);
  const updatePending = taskDefinition.selectors.useUpdateUtilityRequestPending();
  const submitPending = taskDefinition.selectors.useSubmitUtilityRequestPending();
  const pending = updatePending || submitPending;

  const activeRequest = taskDefinition.helpers.getActiveRequest(utilitySetup);
  const activeRequestIsMyRequest = activeRequest?.submitted_by?.user_id === user.id;
  const providerID = activeRequest?.utility_provider?.id;
  const { utility_providers: providers } = utilitySetup;
  const provider = providers.find(provider => provider.id === providerID);

  const {
    account_number_required,
    confirmation_number_required,
    gridrewards_enabled,
    publicgrid_enabled,
    upload_required,
  } = utilitySetup?.settings ?? {};

  const isLastScreen = !gridrewards_enabled;

  const fields = [
    {
      label: `Enter your ${provider?.name} account information below`,
      type: 'title',
    },
    account_number_required && {
      name: 'account_number',
      label: 'Account number',
      type: 'text',
      value: activeRequest?.account_number,
      remoteValidation: { url: `/residents/utility-providers/${providerID}/verify-account-number`, msg: 'Invalid account number format' },
      required: 'You must provide an account number',
    },
    confirmation_number_required && {
      name: 'confirmation_number',
      label: 'Confirmation number',
      type: 'text',
      value: activeRequest?.confirmation_number,
      required: 'You must provide a confirmation number',
    },
    upload_required && {
      label: 'Utilities proof image',
      type: 'uploader',
      name: 'file_url',
      s3UploadRequest: (fileData) => dispatch(getS3UploadUrl(building.id,{
        ...fileData,
        subdirectory: `utilities`,
      })),
      value: activeRequest?.file_url,
      required: true,
      options: {
        accept: {
          'image/jpg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
        },
        useUniqueFilenames: true,
        multiple: false,
        maxFiles: 1,
      }
    },
  ].filter(v=>v);

  const _handleNext = data => {
    if(pending) return false;
    // Create request if none exists or draft request belongs to roommate
    const createOrUpdateRequest = (!activeRequest || !activeRequestIsMyRequest) ?
      dispatch(taskDefinition.actions.createRequest(id, { ...data, utility_provider_id: providerID })) :
      dispatch(taskDefinition.actions.updateRequest(activeRequest.id, data));
    createOrUpdateRequest
      // submit the request if this is the last screen
      .then(resp => isLastScreen ?
        dispatch(taskDefinition.actions.submitRequest(resp?.requests?.[0]?.id)) :
        resp
      )
      .then(() => nextScreen())
      .catch(error => notify.error(format.error(error)));
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={providers?.length > 1 && !publicgrid_enabled && (
          <Notebox
            icon={{ library:'task', symbol:'Proof-of-renters' }}
            title='View Provider Information'
            color='green'
            body={(
              <span>
                <span className='faux-link' onClick={() => previousScreen()}>Click here</span> to view the full list of your provider options.
              </span>
            )}
          />
        )}
      >
        <div className='stackVertical gap-24'>
          <ProviderDetails provider={provider} />
          <DynamicForm id='provider-details'
            fields={fields}
            formStyle={'underline'}
            onSubmit={_handleNext}
            disabled={pending}
          />
        </div>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text={ isLastScreen ? 'Submit' : 'Next' }
          size='large'
          form='provider-details'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  )
};
