import classNames from 'classnames';

import { format, useModal } from '@moved/services';
import { Button, Card, Icon, TruncateText } from '@moved/ui';

import { LegalDisclaimerModal } from './LegalDisclaimerModal.js';

import CSS from './styles/InternetOfferCard.module.scss';

export const InternetOfferCard = ({
  internetOffer,
  onClick,
}) => {
  const modal = useModal();

  const showTermsConditionsModal = (legalText) => {
    modal.open(
      <LegalDisclaimerModal legalText={legalText} />
    );
  };

  // Add cents if not included in price
  const formatPrice = (price) => {
    if (price.includes('.')) {
      return price;
    }
    return `${price}.00`;
  };

  return (
    <Card
      key={internetOffer.id}
      className={CSS.wrapper}
    >
      <div className={classNames(CSS.item, CSS.vendor, 'stackVertical gap-8 items-start')}>
        <img
          className={CSS.logo}
          src={internetOffer.provider.logo_url}
          alt={internetOffer.provider.name}
        />
        <span className={classNames(CSS.badge, 'labelS contentSecondary backgroundPrimary')}>
          {internetOffer.name}
        </span>
      </div>
      <div className={classNames(CSS.item, CSS.speed)}>
        <h2 className='headingM contentPrimary'>
          {internetOffer.download_speed}
        </h2>
        <div className='labelS contentSecondary stackHorizontal gap-4'>
          <Icon
            size='16px'
            symbol='Angle-double-down'
            library='navigation'
            color='gray'
          />
          <span>Max download speed</span>
        </div>
      </div>
      <div className={classNames(CSS.item, CSS.type)}>
        <h2 className='headingM contentPrimary'>
          {internetOffer.connection_type}
        </h2>
        <p className='labelS contentSecondary stackHorizontal gap-4'>
          <Icon
            size='16px'
            symbol='Wi-fi'
            library='devices'
            color='gray'
          />
          <span>Connection type</span>
        </p>
      </div>
      <div className={classNames(CSS.item, CSS.price)}>
        <h2 className='headingM contentPrimary stackHorizontal gap-4 items-center'>
          {formatPrice(internetOffer.price_description)}
          <span className='labelM paddingTop-4'>
            /mo
          </span>
          {internetOffer.legal_disclaimer_text && (
            <span style={{ cursor: 'pointer' }}>
              <Icon
                size='20px'
                symbol='Info-circle'
                library='code'
                onClick={() => showTermsConditionsModal(internetOffer.legal_disclaimer_text)}
              />
            </span>
          )}
        </h2>
        { internetOffer.price_disclaimer_text && (
          <TruncateText
            className={CSS.legalText}
            maxLength={120}
            text={internetOffer.price_disclaimer_text}
          />
        )}
      </div>
      <div className={classNames(CSS.item, CSS.cta)}>
        {internetOffer.provider?.phone != null && (
          <>
            <p className='labelXS contentSecondary'>
              Call to order
            </p>
            <p className='labelM contentPrimary'>
              {format.phone(internetOffer.provider.phone)}
            </p>
          </>
        )}
        <Button text='Choose this plan' onClick={() => onClick(internetOffer)} />
      </div>
    </Card>
  );
};
