import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { DynamicForm, AtomSpinner, Button } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { Screen } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';
import { InsuranceUpsellNotebox } from './InsuranceUpsellNotebox';

import CSS from './styles/PetMedical.module.scss'

// Multi-file document upload screen component
export const PetMedical = ({ screen, nextScreen, taskDefinition, }) => {
  const { id, screenContext } = useParams();
  const dispatch = useDispatch();
  const notify = useNotify();

  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);

  const taskable = taskDefinition.selectors.useTaskable(id);
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskable);
  const activePet = taskDefinition.getActivePet(taskable, screenContext);
  const updatePending = taskDefinition.selectors.usePetRegistrationPending();
  const requestPending = taskDefinition.selectors.useUpdatePetRequestPending();
  const loading = taskDefinition.selectors.usePetRegistrationPending();
  const { settings } = taskable;

  const pending = updatePending || requestPending;

  const fields = [
    settings.license_number_enabled && {
      type: 'text',
      name: 'license_number',
      label: 'Pet License Number',
      required: 'This field is required',
      isTwoCol: true,
      value: activePet?.license_number,
    },
    settings.license_documentation_enabled && {
      type: 'uploader',
      name: 'license_documentation_url',
      label: (<span className={CSS.pet_license_label}>Upload pet license documentation</span>),
      required: 'A photo or copy of pet license is required',
      s3UploadRequest: (fileData) => dispatch(getS3UploadUrl(building.id,{
        ...fileData,
        subdirectory: `pet-registration`,
      })),
      options: {
        accept: {
          'image/jpg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc','.docx'],
        },
        useUniqueFilenames: true,
      },
      value: activePet?.license_documentation_url,
    },
    settings.neutered_status_enabled && {
      type: 'itemList',
      name: 'is_spayed_or_neutered',
      label: (<span className={CSS.neutered_label}>Is this animal spayed or neutered?</span>),
      required: 'This field is required',
      isTwoCol: true,
      options: [
        { value: true, label: 'Yes', icon: { symbol: 'Check', library: 'navigation' }, },
        { value: false, label: 'No', icon: { symbol: 'Close', library: 'navigation' }, },
      ],
      value: activePet?.is_spayed_or_neutered,
    },
    settings.neutered_date_enabled && {
      type: 'datePicker',
      name: 'spayed_or_neutered_date',
      label: 'Spayed/neutered date',
      required: true,
      value: activePet?.spayed_or_neutered_date,
      custom: settings.neutered_status_enabled ? {
        hidden: {
          compare: { field:'is_spayed_or_neutered' },
          boolean: 'not',
          value: true,
        }
      } : null
    },
    settings.vaccine_records_enabled && {
      type: 'uploader',
      name: 'vaccine_file_urls',
      label: (<span className={CSS.vaccination_label}>Upload proof of vaccination</span>),
      required: 'Proof of vaccination is required',
      s3UploadRequest: (fileData) => dispatch(getS3UploadUrl(building.id,{
        ...fileData,
        subdirectory: `pet-registration`,
      })),
      options: {
        accept: {
          'image/jpg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc','.docx'],
        },
        useUniqueFilenames: true,
        multiple: true,
        maxFiles: 6,
      },
      value: activePet?.vaccine_file_urls,
    },
  ].filter(v => v);

  const _handleSubmit = formData => {
    if(pending) return false;

    const cleanData = {
      ...formData,
      spayed_or_neutered_date: (formData.spayed_or_neutered_date === '')
        ? null
        : formData.spayed_or_neutered_date,
    };

    dispatch(taskDefinition.actions.updatePet(activeRequest.id, activePet.id, cleanData))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <InsuranceUpsellNotebox />
        )}
      >
        { !loading ? (
          <DynamicForm
            id='pet-medical'
            fields={fields}
            formStyle={'underline'}
            onSubmit={_handleSubmit}
            disabled={pending}
          />
        ) : (
          <AtomSpinner />
        )}
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='pet-medical'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
