import * as screenComponents from './components';

export const screens = {

  'coi-submit': {
    slug: 'submit',
    component: screenComponents.Submit,
    label: 'Submit COI',
    title: 'Submit processed COI for moving company',
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest && activeRequest?.status !== 'draft';
    },
    maxWidth: 600,
  },
  'summary': {
    slug: 'summary',
    component: screenComponents.Complete,
    label: 'COI Submitted',
    backRestricted: true,
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest?.status === 'approved';
    },
  },

};
