import { Question } from './components/Question.js';
import { Summary } from './components/Summary.js';

export const screens = {

  'question': {
    slug: 'question',
    component: Question,
    label: 'Choice',
    isCompleted: (taskDetails, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(taskDetails);
      return activeRequest && activeRequest?.status !== 'draft';
    },
  },
  'summary': {
    slug: 'summary',
    component: Summary,
    label: 'Summary',
    backRestricted: true,
    isCompleted: (taskDetails, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(taskDetails);
      return activeRequest?.status === 'approved';
    },
  },

};
