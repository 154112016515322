import React from 'react';
import { useParams, } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useNotify, useUser, format } from '@moved/services';
import { DynamicForm, Button } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import { Screen } from '../../shared';

import * as gridRewardsDetails from './gridrewards_details.json';

import CSS from './styles/GridRewardsScreen.module.scss';

export const GridRewardsScreen = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const { user } = useUser();
  const notify = useNotify();
  const dispatch = useDispatch();

  // alias some long syntax down to a more readable size
  const {
    actions: { createRequest, updateRequest, submitRequest, submitPublicGridRequest },
    helpers: { getActiveRequest },
    selectors: { useActiveMoveStep, useBuilding, useUtilitySetup, useUpdateUtilityRequestPending, useSubmitUtilityRequestPending },
  } = taskDefinition;

  // redux
  const activeMoveStep = useActiveMoveStep();
  const building = useBuilding(activeMoveStep?.building?.slug);
  const utilitySetup = useUtilitySetup(id);
  const updatePending = useUpdateUtilityRequestPending();
  const submitPending = useSubmitUtilityRequestPending();
  const pending = updatePending || submitPending;

  const activeRequest = getActiveRequest(utilitySetup);
  const activeRequestIsMyRequest = activeRequest?.submitted_by?.user_id === user.id;
  const providerID = activeRequest?.utility_provider?.id;
  const openGridRewards = () => {
    window.open('https://www.gridrewards.com/','_blank','noopener');
  };

  const submit = (data) => {
    if(pending) return false;
    // Create request if none exists or draft request belongs to roommate
    const createOrUpdateRequest = (!activeRequest || !activeRequestIsMyRequest) ?
      dispatch(createRequest(id, { ...data, utility_provider_id: providerID })) :
      dispatch(updateRequest(activeRequest.id, data));
    createOrUpdateRequest
      .then(r => utilitySetup.public_grid_opt_in === true ?
        dispatch(submitPublicGridRequest(getActiveRequest(r)?.id)) :
        dispatch(submitRequest(getActiveRequest(r)?.id))
      )
      .then(() => nextScreen())
      .catch(error => notify.error(format.error(error)));
  };

  const fields = [
    {
      name: 'consent_to_share_data_with_program_provider',
      label: (
        <>
          Enroll in GridRewards, get notifications - earn money for using less energy during peak demand hours.{' '}
          <a href="https://admin.logicalbuildings.com/tos" target="_blank" rel="noopener noreferrer">See Terms</a>
        </>
      ),
      type: 'checkbox',
      value: true,
      className: CSS.consent,
    },
    {
      name: 'consent_to_share_data_with_property',
      label: (
        <>
          I authorize Logical Buildings to access my utility data and share such utility data with {building.name} for carbon emissions tracking and regulatory benchmarking purposes.{' '}
          <a href="https://logicalbuildings.com/" target="_blank" rel="noopener noreferrer">Learn about Logical Buildings</a>
        </>
      ),
      type: 'checkbox',
      value: true,
      className: CSS.consent,
    },
  ];
  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>

          <div className={CSS.sizer}>

            <div className={CSS.container}>
              <div className={CSS.content}>
                <ResidentContentRenderer content={gridRewardsDetails} />
              </div>
              <div className={CSS.button}>
                <Button
                  text='Visit GridRewards'
                  icon={{library: 'navigation', symbol: 'Up-right'}}
                  iconPosition='right'
                  color='secondary'
                  onClick={openGridRewards}
                />
              </div>
              <div className={CSS.form}>
                <DynamicForm id={`gridrewards-form`}
                  className={CSS.form}
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={submit}
                  disabled={pending}
                />
              </div>
            </div>

            <div className={CSS.side_column} style={{
              backgroundImage: `url('https://moved-assets.s3.amazonaws.com/service-providers/gridrewards/gridrewards_side_image.png')`,
              backgroundColor: '#6943D1'
            }} />

          </div>

      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Submit'
          size='large'
          form='gridrewards-form'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
