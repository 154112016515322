import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { union } from 'lodash';

import { useNotify, format } from '@moved/services';
import { DynamicForm, Button, Notebox } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { Screen } from '../../shared';

import CSS from './styles/KeyPickup.module.scss';

export const KeySelectRoommates = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  const updatePending = taskDefinition.selectors.useUpdateRequestPending();
  const createPending = taskDefinition.selectors.useCreateRequestPending();
  const pending = updatePending || createPending;

  const keyPickupTask = taskDefinition.selectors.useTaskable(id);
  const { roommate_keys = [] } = keyPickupTask;

  const approvedRequest = (keyPickupTask?.requests ?? []).find(request => request.status === 'approved');
  const draftRequest = keyPickupTask?.requests?.[0]?.status === 'draft' ? keyPickupTask.requests[0] : null;

  // Submit form, create or update depending
  const handleSubmit = data => {
    if (pending) return;

    if(approvedRequest?.tenant_key_ids) data.tenant_key_ids = union(approvedRequest.tenant_key_ids,data.tenant_key_ids);
    else data.tenant_key_ids.push(keyPickupTask.current_tenant_key.id);

    if(draftRequest) {
      dispatch(taskDefinition.actions.updateRequest(draftRequest.id, { ...data }))
        .then(nextScreen)
        .catch(err => notify.error(format.error(err)));
    } else {
      dispatch(taskDefinition.actions.createRequest(id))
        .then((resp) => dispatch(taskDefinition.actions.updateRequest(resp?.requests?.[0]?.id, {
          ...data,
          ...(keyPickupTask?.building_calendar_options?.length === 1
              ? {'building_calendar_id': keyPickupTask?.building_calendar_options?.[0]?.id }
              : null
          ),
        })))
        .then(nextScreen)
        .catch(err => notify.error(format.error(err)));
    }
  }

  const coveredTenants = (approvedRequest?.covered_tenants ?? [])
    .map(tenant => format.fullname(tenant))
    .filter(tenant => format.fullname(keyPickupTask?.current_tenant_key) !== tenant)
    .join(',');

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={approvedRequest?.tenant_key_ids?.length > 1 && (
          // Add notebox to item if user is rescheduling and has additional covered_tenants in their approved request
          <Notebox
            title='Reminder'
            body={`You will also be responsible for ${coveredTenants} at your appointment, and they've been notified of this.`}
          />
        )}
      >
        <DynamicForm
          id='roommate-form'
          fields={[
            {
              label: (
                <Snippet tag='tasks.keys.roommates.select_roommates_label' description='Roommate select label'>
                  Select if you are also picking up keys for these residents.
                </Snippet>
              ),
              type: 'title',
            },
            {
              name: 'tenant_key_ids',
              type: 'itemList',
              value: draftRequest ? draftRequest.tenant_key_ids : [],
              required: false,
              isMulti: true,
              className: CSS.roommates_form,
              options: roommate_keys.filter(key => !key.key_pickup_appointment_id)
                .map(key => ({
                  label: format.fullname(key),
                  value: parseInt(key.id),
                })),
            },
          ]}
          onSubmit={handleSubmit}
          disabled={pending}
        />
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='roommate-form'
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  )
};
