import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_CHOICE_TASK_QUESTION_DETAILS',
  request: (id) => request.get(`/residents/choice-task-questions/${id}`)
    .then(r => r.data.data.choice_task_question),
  selector: (state, id) => state?.questionDetails?.[id],
  reducers: {
    success: (state, { response: questionDetails }) => ({
      ...state,
      questionDetails: {
        ...state.questionDetails,
        [questionDetails.id]: questionDetails,
      },
    }),
  }
};

export const {
  reducer,
  action: getQuestionDetails,
  useResource: useQuestionDetails,
  usePending: useQuestionDetailsPending,
} = generate.redux(creator);

export const initialState = {
  questionDetails: {},
};
