import { merge } from 'lodash';

import * as create from './create';
import * as get from './get';
import * as getCableInternetProviders from "./getProviders";
import * as getCableInternetProviderServices from "./getProviderServices";
import * as getProviderLink from './getProviderLink';
import * as submit from './submit';
import * as update from './update';

export const reducers = [
  create.reducer,
  get.reducer,
  getCableInternetProviders.reducer,
  getCableInternetProviderServices.reducer,
  getProviderLink.reducer,
  submit.reducer,
  update.reducer,
];

export const initialState = merge(
  create.initialState,
  getCableInternetProviders.initialState,
  getCableInternetProviderServices.initialState,
);
