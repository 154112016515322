import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useDrawer, useNotify, format } from '@moved/services';

import { getTask } from '../actions/getTask';
import { usePersonalTaskList } from '../contexts/PersonalTaskListContextProvider';
import { taskTypes } from '../types';

export const TaskDetails = () => {
  const drawer = useDrawer();
  const notify = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();

  const { moveId, taskId } = useParams();
  const taskList = usePersonalTaskList();

  useEffect(() => {
    dispatch(getTask(taskId))
      .then((taskDetails) => {
        // some tasks will open DefaultDrawer, and some will have custom drawer components
        // the taskTypes map defines which drawer component to use for each task type
        const { TaskDrawer, options } = taskTypes[taskDetails.task_type] ?? taskTypes['common'];
        drawer.open(
          <TaskDrawer taskId={taskId} taskListId={taskList.id} moveId={moveId} />,
          {
            ...options,
            onClose: () => history.push(`/moves/${moveId}/personal-tasks`),
          }
        );
      })
      .catch(err => {
        notify.error(format.error(err));
        history.push(`/moves/${moveId}/personal-tasks`);
      });
    return () => drawer.close();
  },[taskId]); // eslint-disable-line

  return null;
};
