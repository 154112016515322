import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CREATE_CABLE_INTERNET_TASK',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/cable-internet-tasks`, data)
    .then(r => r?.data?.data?.cable_internet_task),
  reducers: {
    success: (state, { response: cableInternetTask }) => ({
      ...state,
      cableInternetTasks: {
        ...state.cableInternetTasks,
        [cableInternetTask.id]: cableInternetTask,
      },
    }),
  }
};

export const {
  reducer,
  action: create,
  usePending: useCreateCableInternetTaskPending,
} = generate.redux(creator);

export const initialState = {
  cableInternetTasks: {},
};
