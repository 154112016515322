import { Button } from '@moved/ui';

import { useAssistant } from './AssistantWidget';
import { PanelHeader } from './PanelHeader';
import { OverrideFaqForm } from './OverrideFaqForm';

export const OverrideFaqPanel = ({ animationClass }) => {
  const { switchPanel } = useAssistant();

  return (
    <div className='stackVertical gap-24 height-full'>
      <PanelHeader
        title='Add custom answer'
        animationClass={animationClass}
        action={(
          <Button
            onClick={() => switchPanel('faq')}
            icon={{ library: 'navigation', symbol: 'Chevron-left' }}
            color='secondary'
            size='small'
          />
        )}
      />
      <div className='height-full width-full stackVertical gap-8 scrollable'>
        <OverrideFaqForm />
      </div>
    </div>
  );
};
