import React from 'react';
import classNames from 'classnames';

import { Button } from '@moved/ui';

import { useAssistant } from './AssistantWidget';

import CSS from './styles/PanelHeader.module.scss';

export const PanelHeader = ({ title, action, animationClass }) => {
  const { toggleAssistant } = useAssistant();
  return (
    <div className={CSS.container}>
      { action && (
        <div className={classNames(CSS.leftAction, animationClass)}>
          { action }
        </div>
      )}
      { title && (
        <div className={classNames('headingM contentPrimary', animationClass)}>
          {title}
        </div>
      )}
      <div className={classNames(CSS.rightAction, animationClass)}>
        <Button
          onClick={toggleAssistant}
          icon={{ library: 'navigation', symbol: 'Close' }}
          color='secondary'
          size='small'
        />
      </div>
    </div>
  );
};
