import { Marketplace } from './components/Marketplace';
import { withAssistant } from '../assistant';

const routes = [
  {
    path: '/marketplace',
    name: 'Marketplace',
    address: 'marketplace',
    component: withAssistant(Marketplace),
    transition: 'crossFade',
    exact: true,
    viewKey: ({ moveId }) => `marketplace-${moveId}`,
  },
];

export default routes;
