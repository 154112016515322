import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { useRouter, useUser, useModal, useNotify, format } from '@moved/services';
import { Collapsible, Icon, Button, Badge, ConfirmationModal, Tooltip } from '@moved/ui';

import { useAssistant } from './AssistantWidget';

import { markAnswerHelpful } from '../actions/markAnswerHelpful';
import { deleteFaqAnswer } from '../actions/deleteFaqAnswer';
import { blockFaqQuestion } from '../actions/blockFaqQuestion';

import CSS from './styles/Faq.module.scss';

const answerBadgeMap = {
  global: {
    text: 'global',
    type: 'default',
  },
  partner: {
    text: 'client',
    type: 'info',
  },
  building: {
    text: 'property',
    type: 'warning',
  },
};

export const Faq = ({ faq, className }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const modal = useModal();
  const { isProxy } = useUser();
  const { params, activeRouteAddress } = useRouter();
  const { availablePanels, switchPanel, refreshFaqs, building } = useAssistant();

  const [open, setOpen] = useState(false);
  const [isHelpful, setIsHelpful] = useState(false);

  const markHelpful = () => {
    if(isHelpful) return;
    setIsHelpful(true);
    dispatch(markAnswerHelpful(faq.answer?.id, {
      faq_question_id: faq.id,
      move_id: parseInt(params?.moveId),
      move_step_id: parseInt(params?.stepId),
      screen_address: activeRouteAddress,
    }));
  }

  const confirmDelete = () => {
    const deleteAnswer = () => dispatch(deleteFaqAnswer(faq.answer?.id, { faq_question_id: faq.id }))
      .then(() => refreshFaqs())
      .catch((err) => notify.error(format.error(err)));
    modal.open(
      <ConfirmationModal
        isWarning
        title='Are you sure?'
        copy={`This will permanently remove the ${answerBadgeMap[faq.answer?.scope]?.text} answer to this question. The question will remain only if there is a more generic answer available.`}
        confirmText='Delete'
        cancelText='Never mind'
        onConfirm={deleteAnswer}
      />
    );
  };

  const confirmBlock = () => {
    const blockQuestion = () => dispatch(blockFaqQuestion(faq?.id, { partner_id: building?.partner?.id }))
      .then(() => refreshFaqs())
      .catch((err) => notify.error(format.error(err)));
    modal.open(
      <ConfirmationModal
        isWarning
        title='Are you sure?'
        copy={`This will permanently hide this question for all of ${building?.partner?.name}'s tenants.`}
        confirmText='Block'
        cancelText='Never mind'
        onConfirm={blockQuestion}
      />
    );
  };

  return (
    <div className={classNames(
      'stackVertical padding-12-16 radius-8 backgroundPrimary',
      className,
    )}>
      <div className='stackHorizontal justify-between cursor-pointer' onClick={() => setOpen(!open)}>
        <div className='labelL contentPrimary'>
          { faq.question }
        </div>
        <Icon
          library='navigation'
          symbol='Chevron-down'
          className={classNames('flex-none',CSS.icon,{[CSS.open]: open})}
        />
      </div>
      <Collapsible open={open}>
        <div className='stackVertical gap-20 paddingTop-12'>
          <div className='stackVertical gap-8'>
            <div className='labelM contentSecondary'>{faq.answer?.answer}</div>
          </div>
          { isProxy ? (
            <div className='stackHorizontal gap-8 flex-wrap items-center'>
              <Badge
                text={answerBadgeMap[faq.answer?.scope]?.text ?? 'global'}
                type={answerBadgeMap[faq.answer?.scope]?.type}
                size='small'
              />
              <Tooltip tooltip='Edit current answer' mountToBody>
                <Button
                  icon={{ library:'general', symbol:'Edit' }}
                  color='secondary'
                  size='small'
                  onClick={() => switchPanel('edit', faq)}
                />
              </Tooltip>
              { faq?.answer?.scope !== 'building' && (
                <Tooltip tooltip='Add answer override' mountToBody>
                  <Button
                    icon={{ library:'navigation', symbol:'Plus' }}
                    color='secondary'
                    size='small'
                    onClick={() => switchPanel('override', faq)}
                  />
                </Tooltip>
              )}
              { faq?.answer?.scope !== 'global' && (
                <Tooltip tooltip='Delete answer override' mountToBody>
                  <Button
                    icon={{ library:'general', symbol:'Trash' }}
                    color='secondary'
                    showWarning
                    size='small'
                    onClick={confirmDelete}
                  />
                </Tooltip>
              )}
              { faq?.answer?.scope === 'global' && (
                <Tooltip tooltip={`Block question for ${building?.partner?.name}`} mountToBody>
                  <Button
                    icon={{ library:'code', symbol:'Stop' }}
                    color='secondary'
                    showWarning
                    size='small'
                    onClick={confirmBlock}
                  />
                </Tooltip>
              )}
            </div>
          ) : (
            <div className='stackVertical gap-8'>
              <div className='labelM contentPrimary'>Was this helpful?</div>
              <div className='stackHorizontal gap-8'>
                <Button
                  text={isHelpful ? 'Thanks' : 'Yes'}
                  icon={{ library:'general', symbol:'Like' }}
                  color='secondary'
                  size='small'
                  onClick={markHelpful}
                  className={classNames(CSS.helpfulButton, {[CSS.active]: isHelpful})}
                  disabled={isHelpful}
                />
                { availablePanels.includes('contact') && (
                  <Button
                    text={faq.answer?.route_to_property ? 'Contact your property' : 'Ask follow up question'}
                    color='secondary'
                    size='small'
                    onClick={() => switchPanel('contact', faq)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Collapsible>
    </div>
  );
};
