import { useEffect } from 'react';
import nextId from 'react-id-generator';

import { useSnippets } from '@moved/services';

import { useActiveMoveStep } from '../../dashboard';
import { useBuilding } from '../../common/actions/selectors';
import { useSnippetMap } from '../actions/selectors'

export const useSnippet = (tag, description='') => {
  const activeMoveStep = useActiveMoveStep();
  const building = useBuilding(activeMoveStep?.building?.slug);
  const { add, remove } = useSnippets();

  const snippet = useSnippetMap(building?.id, tag);

  useEffect(() => {
    const id = nextId('snippet-');  // Create Unique ID for removing/updating snippet
    add(id, tag, description);      // Add snippet on initial mount
    return () => remove(id);        // Remove snippet on unmount
  }, [tag, description, add, remove]);

  return snippet;
};
