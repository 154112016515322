import { LayoutSidebar } from '../common';
import { MovesList, Move, withMoveSummaries } from './components';

import { withAssistant } from '../assistant';
import { SidebarPageNotFound } from '../common/components';
import dashboardRoutes from '../dashboard/routes';
import personalTaskRoutes from '../personalTasks/routes';
import marketplaceRoutes from '../marketplace/routes';
import tasksRoutes from '../tasks/routes';

const routes = [
  {
    path: '/moves',
    name: 'Moves List',
    address: 'moves-list',
    component: withAssistant(withMoveSummaries(MovesList)),
    exact: true,
    transitions: {
      in: 'rotateRoomBottom',
      out: 'rotateRoomTop',
    },
    viewKey: () => `moves-list`,
  },
  {
    path: '/moves/:moveId(\\d+)',
    name: 'Move',
    component: withMoveSummaries(Move),
    viewKey: ({ moveId }) => `move-${moveId}`,
    transition: 'rotateRoomTop',
    children: [
      ...tasksRoutes,
      {
        path: '/',
        name: 'Dashboards',
        component: LayoutSidebar,
        viewKey: ({ moveId }) => `dashboard-${moveId}`,
        transitions: {
          in: 'scaleOut',
          out: 'scale',
        },
        children: [
          ...dashboardRoutes,
          ...personalTaskRoutes,
          ...marketplaceRoutes,
          {
            path: '*',
            name: 'Page not found',
            component: SidebarPageNotFound,
          },
        ],
      },
    ],
  },
];

export default routes;
