import React from 'react';

import * as screenComponents from './components';

export const screens = {

  offers: {
    slug: 'offers',
    label: "Additional offers",
    title: 'What offers are you interested in?',
    subtitle: (<>Help us improve! Let us know what you&rsquo;re interested in seeing on the website.</>),
    additionalData: { options: { action: 'getOptionsList', selector: 'useAddonOptionsList' }},
    isCompleted: (data) => data?.selections != null,
    fields: (data = {}, additionalData={}) => {
      const options = additionalData?.options?.data;
      return [
        {
          type: 'itemList',
          name: 'selections',
          isNumeric: true,
          isMulti: true,
          isTwoCol: true,
          options: options ? options.map((option) => {
            return {
              value: option.id,
              label: option.label,
              icon: {
                symbol: option.icon_name,
                library: option.icon_library,
              },
            };
          }) : [],
          value: data?.selections ? data.selections.map(selection => selection.addon_interest_option_id) : [],
        },
      ];
    },
  },

  'complete': {
    slug: 'complete',
    label: 'Complete',
    component: screenComponents.Complete,
    isCompleted: (data) => data?.selections != null,
  },

};
