import React, { useState } from 'react';
import classNames from 'classnames';

import { format } from '@moved/services';
import { Icon, Card } from '@moved/ui';

import CSS from './styles/Payment.module.scss';

export const PaymentSummaryCards = ({ deposit, currentAmount }) => {
  const [showPayments, setShowPayments] = useState(false);
  return (
    <div className={classNames('stackVertical gap-24',CSS.summary)}>
      <Card>
        <h3 className={CSS.title}>Summary of charges</h3>
        { deposit.details ? (
          <div className={classNames('stackVertical gap-16',CSS.section)}>
            { deposit.details.map((lineItem,index) => (
              <div className={CSS.line_item} key={index}>
                <span className={CSS.line_label}>{lineItem.label}</span>
                <span className={CSS.line_value}>{format.currency(lineItem.amount)}</span>
              </div>
            ))}
          </div>
        ) : null }
        <div className={classNames('stackVertical gap-16',CSS.section)}>
          <div className={CSS.line_item}>
            <span className={classNames(CSS.line_label, CSS.black)}>Total amount</span>
            <span className={classNames(CSS.line_value, CSS.black)}>{format.currency(deposit.total)}</span>
          </div>
        </div>
        { deposit.payments.length ? (
          <div className='stackVertical gap-12'>
            <div id='show_payments' className={classNames(CSS.line_item, CSS.collapse, {[CSS.invert]:showPayments})}
              onClick={() => setShowPayments(!showPayments)}>
              <span className={classNames(CSS.line_label, CSS.black)}>Payments</span>
              <span className={classNames(CSS.line_value, CSS.black)}>
                <Icon symbol='Chevron-right' library='navigation' className={CSS.icon} />
              </span>
            </div>
            { showPayments && deposit.payments.map((payment) => (
              <div className={CSS.line_item} key={payment.id}>
                <span className={classNames(CSS.line_label, CSS.black)}>
                  <Icon size='20px' symbol='Check' className={CSS.line_icon} />
                  { (payment.firstname && payment.lastname) ? `${payment.firstname} ${payment.lastname}` : 'Name unspecified' }
                  <span className={CSS.line_subtext}>{format.date(payment.created_at,'dateShort')}</span>
                </span>
                <span className={classNames(CSS.line_value, CSS.black)}>{format.currency(payment.amount)}</span>
              </div>
            ))}
          </div>
        ) : null }
      </Card>

      <Card>
        { deposit.payments.length ? (
          <div className={CSS.line_item}>
            <span className={CSS.line_label}>Remaining balance</span>
            <span className={CSS.line_value}>{format.currency(deposit.balance)}</span>
          </div>
        ) : null}
        <div className={classNames(CSS.pay_now, CSS.line_item)}>
          <h3 className={classNames(CSS.line_label, CSS.black)}>{ currentAmount ? 'Pay now' : 'Total Due'}</h3>
          <h3 className={classNames(CSS.line_value, CSS.black)}>{ format.currency(currentAmount ? currentAmount : deposit.balance) }</h3>
        </div>
      </Card>
    </div>
  );
};
