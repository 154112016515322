import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { useNotify, format } from '@moved/services';
import { AtomSpinner, Button, Notebox } from '@moved/ui';

import {
  DayPicker,
  DayPickerItem,
  DayTooltips,
  DependentTaskNoteboxes,
  TimePicker,
  TimePickerItem,
  Screen,
} from '../../shared';

import CSS from './styles/Schedule.module.scss';

const showOptionalMinutes = (time) => format.date(moment(time,'HH:mm:ss'), (moment(time,'HH:mm:ss').minutes() > 0) ? 'time' : 'timeShort');

export const Schedule = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  // state
  const [activeDate, setActiveDate] = useState({});
  const [activeSlot, setActiveSlot] = useState({});
  const [submitPending, setSubmitPending] = useState();

  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const taskDetails = taskDefinition.selectors.useTaskable(id);

  const buildingCalendar = taskDefinition.selectors.useBuildingCalendar(id) || {};
  const pendingCalendar = taskDefinition.selectors.useBuildingCalendarPending();
  const availabilityCalendar = buildingCalendar?.availability ?? [];

  const draftRequest = taskDetails?.requests?.at?.(0)?.status === 'draft' ? taskDetails.requests.at(0) : null;
  const existingReservation = taskDetails?.reservation;

  // Load the initial calendar data
  useEffect(() => {
    dispatch(taskDefinition.actions.getCalendar(id))
      .catch((err) => notify.error(format.error(err)));
  },[]); // eslint-disable-line

  useEffect(() => {
    if(!buildingCalendar || isEmpty(availabilityCalendar)) return; // can't select any date if calendar isn't loaded
    setActiveDate(getInitialSelectedDate());
  },[buildingCalendar]); // eslint-disable-line

  // Day picker helpers
  const isMoveOut = activeMoveStep?.type === "move-out";

  const isDayVisible = day => isMoveOut ?
    !moment(taskDetails?.latest_reservation_date).add(1,'d').isBefore(day.date,'day') :
    !moment(taskDetails?.earliest_reservation_date).subtract(1,'d').isAfter(day.date,'day');

  // Days are available before latest date and after earliest date
  const isDayAvailable = day => (
    (!taskDetails?.latest_reservation_date || moment(day.date).isSameOrBefore(taskDetails?.latest_reservation_date,'day')) &&
    (!taskDetails?.earliest_reservation_date || moment(day.date).isSameOrAfter(taskDetails?.earliest_reservation_date,'day'))
  );

  const visibleDays = availabilityCalendar.filter(isDayVisible);
  const availableDays = visibleDays.filter(isDayAvailable);

  const daysList = visibleDays.map(day => ({
      id: day.date,
      isSelected: activeDate.date === day.date,
      isDisabled: !isDayAvailable(day),
      availability: day,
      content: (<>
        <DayTooltips
          date={day.date}
          calendar={buildingCalendar}
          earliestDate={taskDetails?.earliest_reservation_date}
          latestDate={taskDetails?.latest_reservation_date}
          leaseStartDate={taskDetails?.lease.start_date}
          leaseEndDate={taskDetails?.lease.end_date}
          currentAppointmentDate={format.date(taskDetails?.reservation?.start_time,'YYYY-MM-DD')}
        />
        <DayPickerItem day={day} />
      </>),
    }));

  const getInitialSelectedDate = () => {
    let selection;
    // if taskable already has a date selected, reselect it
    if(existingReservation?.start_time)
      selection = availableDays.find(day => day.date === moment(existingReservation?.start_time).format('YYYY-MM-DD'));

    // if no previous selection attempt to use earliest pickup date for move-ins and latest pickup date for move-outs
    if(isMoveOut) {
      if(!selection && taskDetails?.latest_reservation_date)
        selection = availableDays.find(dates => dates.date === taskDetails.latest_reservation_date) ?? availableDays.at(-1);
    } else {
      if(!selection && taskDetails?.earliest_reservation_date)
        selection = availableDays.find(dates => dates.date === taskDetails.earliest_reservation_date) ?? availableDays.at(0);
    }
    // fallback options is to select the first available date
    if(!selection) selection = availableDays[0];
    return selection || {};
  };

  const selectDate = (selected) => {
    if(selected.isDisabled) return;
    if(!activeDate || selected.availability.date !== activeDate.date) {
      setActiveSlot({}); // reset the slot if the date has changed
      setActiveDate(selected.availability); // update date in state
    }
  };

  // Time picker helpers
  const timesList = (activeDate?.timeslots ?? []).map((slot) => ({
    id: slot.start,
    isSelected: slot.start === activeSlot.start,
    isDisabled: !slot.is_available,
    slot: slot,
    content: (
      <TimePickerItem
        time={`${showOptionalMinutes(slot.start)} - ${showOptionalMinutes(slot.end)}`}
        reserved={!slot.is_available}
      />
    ),
  }));

  const selectSlot = slot => {
    if(slot?.is_available) setActiveSlot(slot);
  };

  const isValid = () => {
    if(!isEmpty(activeDate) && !isEmpty(activeSlot) && isDayAvailable(activeDate)) return true;
    return false;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if(submitPending || !isValid()) return null;
    setSubmitPending(true);

    const data = { start_time: `${activeDate.date}T${activeSlot.start}` };

    if(!draftRequest) {
      dispatch(taskDefinition.actions.createRequest(id, data))
        .then(resp => dispatch(taskDefinition.actions.submitRequest(resp.requests[0].id)))
        .then(nextScreen)
        .catch(error => {
          setSubmitPending(false);
          notify.error(format.error(error));
        });
    } else {
      dispatch(taskDefinition.actions.updateRequest(draftRequest.id, data))
        .then(resp => dispatch(taskDefinition.actions.submitRequest(draftRequest.id)))
        .then(nextScreen)
        .catch(error => {
          setSubmitPending(false);
          notify.error(format.error(error));
        });
    }
  };

  const handleCancel = () => {
    return dispatch(taskDefinition.actions.cancelRequest(draftRequest.id))
      .then(nextScreen)
      .catch(error => {
        notify.error(format.error(error));
      });
  };

  const lastDate = availabilityCalendar[availabilityCalendar.length - 1]?.date;

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <>
            <DependentTaskNoteboxes taskDetails={taskDetails} activeMoveStep={activeMoveStep} origin={origin} />
            { existingReservation && (
              <Notebox
                className={CSS.keep_appointment}
                title={`Current appointment: ${format.date(existingReservation.start_time, 'h:mma')}-${format.date(existingReservation.end_time, 'h:mma')}, ${format.date(existingReservation.start_time, 'dddd M/DD')}`}
                body={(
                  <p>If there is no better time available for reschedule, you can keep this appointment.</p>
                )}
                color='blue'
                icon={{ library:'code', symbol:'Info-circle' }}
                actions={(
                  <Button color='secondary' text='Keep this appointment' onClick={handleCancel} />
                )}
              />
            )}
          </>
        )}
      >
        <section className={CSS.picker_section}>
          { pendingCalendar ? (
            <AtomSpinner/>
          ) : (
            (daysList.length >= 1 && !(daysList.length === 1 && daysList[0].isDisabled)) ? (
              <div className={CSS.picker_container}>
                <DayPicker days={daysList} onSelect={selectDate} scrollToLastDate={isMoveOut} />
                <TimePicker times={timesList} onSelect={(time) => selectSlot(time.slot)} />
              </div>
            ) : (
              <Notebox
                title={(lastDate ?
                  `Appointments currently available up until ${format.date(lastDate)}.` :
                  `There is currently no calendar configured for this property.`
                )}
                body='Please check back in a few days to schedule your upcoming appointment.'
                color={lastDate ? 'blue' : 'orange'}
                icon={lastDate ? { symbol:'Info' } : { library:'code', symbol:'Warning-2' }}
              />
            )
          )}
        </section>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Submit'
          size='large'
          disabled={submitPending || !isValid()}
          onClick={handleSubmit}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>

  );
}
