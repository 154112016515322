import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Icon, Button, AtomSpinner } from '@moved/ui';
import { format, useNotify } from '@moved/services';

import { ResidentContentRenderer } from '../../../../common';

import { Screen } from '../../shared';
import {
  getProviders,
  useProviders,
  useProvidersPending,
} from '../actions/getProviders';
import {
  getProviderLink,
  useProviderLinkPending,
} from '../actions/getProviderLink';
import {
  submit,
  useSubmitCableInternetTaskPending,
} from '../actions/submit';

import { AllConnect } from './AllConnect';

import CSS from './styles/Providers.module.scss';

const DEFAULT_CTA_TEXT = 'Sign up now';

export const Providers = ({ screen, nextScreen, taskDetails, taskDefinition, origin }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { id } = useParams();
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);

  const providersPending = useProvidersPending();
  const linkPending = useProviderLinkPending();
  const submitPending = useSubmitCableInternetTaskPending();

  const providers = useProviders(id);

    // convenience variables
  const showAllConnect = taskDetails?.settings?.allconnect_enabled &&
    (providers?.length < 4); // don't show allconnect if we have 4+ integrated providers
  const address = activeMoveStep?.lease?.address;

  useEffect(() => {
    dispatch(getProviders(id))
      .catch((err) => notify.error(format.error(err)));
  },[id, dispatch, notify]);

  // CTA handler function
  const selectProvider = (provider, order) => {
    if(linkPending) return false;
    dispatch(getProviderLink(id, provider?.id, {
      origin: 'direct',
      provider_order: order,
      cta_text: provider.cta_text ?? DEFAULT_CTA_TEXT,
    }))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => notify.error(format.error(err)))
  };

  // Screen complete function
  const handleNext = () => {
    if(submitPending) return;
    dispatch(submit(id))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  }

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>

        { providersPending ? (
          <AtomSpinner />
        ) : providers?.length > 0 && (
          <div className={CSS.section}>
            <div className={CSS.section_label}>Preferred vendors</div>
            { providers.map((provider, providerIndex) => (
              <div key={provider.id} className={CSS.provider}>
                <div className={CSS.column}>
                  <img src={provider.logo_url} alt={provider.name} className={CSS.logo} />
                  <ResidentContentRenderer content={provider.description} className={CSS.content} />
                </div>
                { provider.cta_text !== 'NO_CTA' && (
                  <div className={classNames(CSS.column,CSS.actions)}>
                    <Button
                      text={provider.cta_text ?? DEFAULT_CTA_TEXT}
                      size='small'
                      icon={{library: 'navigation', symbol: 'Up-right'}}
                      iconPosition='right'
                      onClick={() => selectProvider(provider, providerIndex)}
                    />
                  </div>
                )}
                { provider?.building_sales_contacts?.map(contact => (
                  <div className={CSS.contact_row} key={contact.id}>
                    <h4>{contact.name}</h4>
                    { contact.contact_url && (
                      <a href={contact.contact_url} className={classNames('btn-ghost btn--small', CSS.button)} target="_blank" rel="noopener noreferrer">
                        Website
                        <Icon library='home' symbol='Globe' size='22px' className='ml-10'/>
                      </a>
                    )}
                    { contact.phone && (
                      <a href={`tel:${contact.phone}`} className={classNames('btn-ghost btn--small', CSS.button)}>
                        { format.phone(contact.phone) }
                        <Icon library='communication' symbol='Active-call' size='22px' className='ml-10'/>
                      </a>
                    )}
                    { contact.email && (
                      <a href={`mailto:${contact.email}`} className={classNames('btn-ghost btn--small', CSS.button)}>
                        { contact.email }
                        <Icon library='communication' symbol='Mail' size='22px' className='ml-10'/>
                      </a>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        { showAllConnect && (
          <div className={CSS.section}>
            { providers?.length > 0 && (
              <div className={CSS.section_label}>Other vendors</div>
            )}
            <AllConnect address={address} building={building} />
          </div>
        )}
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Complete'
          size='large'
          onClick={handleNext}
          disabled={submitPending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
}
