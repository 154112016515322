import { get, merge } from 'lodash';

import { Reserve } from '../reserve/definition';

import { screens } from './screens';

export class MoveOutInspection extends Reserve {
  constructor() {
    super();
    // override screens
    this.screens = screens;
  }

  static label = 'Move Out Inspection';
  static slug = 'move-out-inspection';
  static icon = 'Search';

  _flowBuilder(taskData) {
    return [
      this.screens['schedule'],
      this.screens['summary'],
    ];
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: (<>
              <p>Unfortunately, the date &amp; time you request is not available for a move-out inspection. Please submit a new request for another time.</p>
              <p className='txt-center italic'>{get(request, 'notes')}</p>
            </>),
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: (<>Property Management has been notified of your requested date &amp; time. You will notified when your request is reviewed.</>),
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: (<>You are confirmed for your requested date &amp; time below for your move-out inspection.</>),
          },
          history: {
            title: 'Current Reservation',
          }
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }


  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
