import * as screenComponents from './components';

export const screens = {

  'document-upload': {
    slug: 'documents',
    label: 'Documents',
    title: 'Review some important documents for your new apartment',
    subtitle: 'Please download and complete the required information for your upcoming lease.',
    component: screenComponents.DocumentUpload,
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest && activeRequest?.status !== 'draft';
    },
    maxWidth: 710,
  },
  'document-complete': {
    slug: 'complete',
    component: screenComponents.Summary,
    label: 'Confirmation',
    backRestricted: true,
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest?.status === 'approved';
    },
  },
};
