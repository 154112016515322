import React from 'react';

import { CompleteCard } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { Screen, CompleteScreenActions } from '../../shared';

export const Complete = ({screen, taskDefinition, origin, moveId, onClose}) => (
  <Screen taskDefinition={taskDefinition} screen={screen}>
    <Screen.Title />
    <Screen.Content>
      <CompleteCard
        status={'approved'}
        flavor={<Snippet tag={`tasks.${taskDefinition.slug}.summary`} />}
      >
        <p>
          I understand that setting up utilities for my apartment is my responsibility ahead of moving in.
        </p>
      </CompleteCard>
    </Screen.Content>
    <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
  </Screen>
);
