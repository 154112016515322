import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Card } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { Screen } from '../../shared';

import CSS from './styles/UhaulSolutions.module.scss';

export const UhaulSolutions = ({ screen, nextScreen, taskDefinition, moveId }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  const pending = taskDefinition.selectors.useCompleteMoverBookingTaskPending();
  const options = taskDefinition.selectors.useUhaulProducts(moveId) ?? [];
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();

  useEffect(() => {
    dispatch(taskDefinition.actions.getUhaulProducts(moveId))
      .catch(err => notify.error(format.error(err)));
  },[taskDefinition, moveId, dispatch, notify]);

  const onClick = (option) => {
    dispatch(taskDefinition.actions.getUhaulAdLink(moveId, option.id, {move_step_id: activeMoveStep.id}))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => notify.error(format.error(err)));
  };

  const submit = () => {
    if(pending) return;
    dispatch(taskDefinition.actions.complete(id))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>

        <div className='grid-3-24'>
          { options.map(option => (
            <Card
              className={classNames('stackVertical gap-12',CSS.card)}
              onClick={() => onClick(option)}
              key={option.label}
            >
              <div className='stackVertical gap-12 width-full'>
                <div className={CSS.image}>
                  <img src={option.background_image_url} alt={option.title} />
                </div>
                <div className='stackVertical'>
                  <div className='headingS contentPrimary'>{option.title}</div>
                  <div className='labelS contentSecondary'>{option.subtitle}</div>
                </div>
              </div>
              <Button
                text={option.cta_text}
                color='custom'
                size='small'
                customColor={option.cta_color}
              />
            </Card>
          ))}
        </div>

      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          onClick={submit}
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
}
