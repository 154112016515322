import React from 'react';
import { get } from 'lodash';

import { Uploader, Collapsible, Icon, Button } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import CSS from './styles/DocumentUpload.module.scss';

// Multi-file document upload screen component
export const FileRequirement = ({ requirement, active, setActive, uploadFile }) => {

  const open = active === requirement.id;
  const {file_url: fileUrl} = requirement;

  let file = {};
  if(fileUrl) {
    const type = fileUrl.split('.').pop();
    const name = fileUrl.split('/').pop();
    file = {
      src: fileUrl,
      name,
      type: (type === 'pdf')
        ? 'application/pdf'
        : 'image/jpg'
    };
  }

  return (
    <div className={CSS.box}>
      <div className={CSS.title} onClick={e => setActive(open ? null : requirement.id)}>
        <div className={CSS.display}>
          <Icon className={CSS.display_icon} symbol={requirement.icon || 'File'} />
          <p className={CSS.display_name}>{requirement.display_name}</p>
        </div>
        <div>
          <a href={requirement.template_url} target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()} download>
            <Button size='small' color='secondary' text='Download template' />
          </a>
        </div>
      </div>
      <Collapsible open={open}>
        {get(requirement,'instructions.blocks') && (
          <ResidentContentRenderer content={requirement.instructions} className={CSS.instructions} />
        )}
        <Uploader
          className={CSS.dropzone}
          onSelect={(files) => uploadFile(files[0], requirement.id)}
          initialFiles={fileUrl && [file]}
          dzOptions={{
            maxFiles: 1,
            multiple: false,
            accept: {
              'application/pdf': ['.pdf'],
              'image/jpg': ['.jpg', '.jpeg'],
            },
          }}
          icon={{ symbol: 'Image', library: 'design', size: '27px' }}
        />
      </Collapsible>
    </div>
  );
};
