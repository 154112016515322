import classNames from 'classnames';

import { format } from '@moved/services';
import { Icon, usePopover } from '@moved/ui';

import { usePersonalTaskList } from '../contexts/PersonalTaskListContextProvider';
import { useOpenTaskDetails } from '../hooks/useOpenTaskDetails';

import CSS from './styles/TimelineDayEventLink.module.scss';

export const TimelineDayEventLink = ({ event, eventIcon, className }) => {
  const { hide } = usePopover();
  const { personal_tasks=[], id } = usePersonalTaskList() ?? {};
  const relatedTask = personal_tasks.find(({id}) => id === event.personal_task_id);

  const openDetails = useOpenTaskDetails(relatedTask?.id, id);

  const openTask = () => {
    if(!relatedTask) return;
    openDetails();
    hide();
  };

  return (
    <div
      className={classNames(
        'stackHorizontal gap-12 items-center',
        { [CSS.clickable]: relatedTask },
        className,
      )}
      onClick={openTask}
    >
      { (eventIcon || relatedTask?.icon) && (
        <Icon
          {...(eventIcon || relatedTask.icon)}
          size='20px'
          className='flex-none'
        />
      )}
      <div className={classNames('labelS contentPrimary textTruncate flex-auto')}>{ format.capitalize(event.title) }</div>
      { relatedTask && (
        <Icon
          library='navigation'
          symbol='Chevron-right'
          size='20px'
          color='inherit'
          className={CSS.arrow}
        />
      )}
    </div>
  );
}
