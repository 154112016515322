import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ASSISTANT_CREATE_FAQ',
  request: (data={}) => request.post(`/residents/faq-questions`, data, { asSuperUser: true })
    .then(r => r?.data?.data?.faq_question),
};

export const {
  reducer,
  action: createFaq,
  usePending: useCreateFaqPending,
} = generate.redux(creator);
