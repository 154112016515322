import { useParams, useHistory } from 'react-router-dom';

import { useActiveBuildingMoveTask } from '../contexts/BuildingMoveTaskContext';
import { getDashboardRoute } from '../../dashboard/helpers';
import { TaskFlowLayout } from './TaskFlowLayout';

export const TaskSummary = () => {
  const { moveId, stepId } = useParams();
  const history = useHistory();
  const {
    activeTaskDefinition,
    activeTaskDetails,
    activeTaskSummary,
    activeTaskBaseRoute
  } = useActiveBuildingMoveTask();

  const screen = activeTaskDefinition.screens['summary'];
  const dashboardRoute = getDashboardRoute(moveId, stepId);

  // Needed for resetting a task (only works in Resident Context)
  const goToScreen = (screen) => {
    if(!screen || !activeTaskDefinition.canAccessScreen(activeTaskDetails,screen.slug,screen.context)) return;
    history.push(`${activeTaskBaseRoute}${activeTaskDefinition.getScreenRoute(screen,activeTaskDetails)}`);
  };
  const close = () => history.push(dashboardRoute);

  return (
    <TaskFlowLayout origin={dashboardRoute} taskDefinition={activeTaskDefinition}>

      <TaskFlowLayout.Sidebar/>

      <TaskFlowLayout.Content previousScreen={close}>

        <screen.component
          screen={screen}
          task={activeTaskDefinition}
          taskDetails={activeTaskDetails}
          taskDefinition={activeTaskDefinition}
          taskBaseRoute={activeTaskBaseRoute}
          taskSummary={activeTaskSummary}
          goToScreen={goToScreen}
          onClose={close}
          origin={dashboardRoute}
          moveId={moveId}
        />

      </TaskFlowLayout.Content>

    </TaskFlowLayout>

  );
};
