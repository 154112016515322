import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ASSISTANT_BLOCK_FAQ_QUESTION',
  request: (id,data={}) => request.post(`/residents/faq-questions/${id}/blocked`, data, { asSuperUser: true }),
};

export const {
  reducer,
  action: blockFaqQuestion,
  usePending: useBlockFaqQuestionPending,
} = generate.redux(creator);
