import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_MOVING_PLAN_SET_UHAUL_ACCOUNT_OPT_IN_STATUS',
  request: (moveId, data={}) => request.post(`/residents/moves/${moveId}/uhaul-account-decisions`,data)
    .then(r => r?.data?.data?.uhaul_account_decision),
};

export const {
  reducer,
  action: setUhaulAccountOptInStatus,
  usePending: useSetUhaulAccountOptInStatusPending,
} = generate.redux(creator);
