import React from 'react';
import { useDispatch } from 'react-redux';

import { useNotify, format } from '@moved/services';
import { CompleteCard, FieldList, Button } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { Snippet } from '../../../../snippets';
import { useActiveBuildingMoveTask } from '../../../contexts/BuildingMoveTaskContext';
import {
  Screen,
  CompleteScreenActions,
  ServiceProviderAd,
} from '../../shared';

export const Confirmation = ({screen, moveId, origin, goToScreen, onClose}) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const {
    activeTaskDefinition: taskDefinition,
    activeTaskDetails: moverBookingTask,
    activeBuildingMoveTask,
  } = useActiveBuildingMoveTask();

  const resetPending = taskDefinition.selectors.useResetMoverBookingTaskPending();

  const reset = () => {
    // Revert moving vendor option
    dispatch(taskDefinition.actions.reset(moverBookingTask.id))
      // refresh the move because reset affects MBT and MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then(r => goToScreen(taskDefinition.getStartOverScreen(r)))
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Screen taskDefinition={taskDefinition} screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className='stackVertical gap-24'>
          <CompleteCard
            status='approved'
            flavor={(
              <Snippet tag={'tasks.book-movers.summary.diy'}>
                Your property does not require any additional information.
              </Snippet>
            )}
          >
            <FieldList list={[
              ...taskDefinition.helpers.getSummaryFields(moverBookingTask),
              {
                value: (
                  <Button
                    size='small'
                    color='secondary'
                    text='Change selection'
                    onClick={reset}
                    disabled={resetPending}
                  />
                )
              },
            ]} />
          </CompleteCard>

          { (moverBookingTask?.service_provider_ads ?? []).map(ad => (
            <ServiceProviderAd
              key={ad.id}
              className="marginBottom-24"
              ad={ad}
              buildingMoveTaskId={activeBuildingMoveTask?.id}
            />
          ))}
        </div>
      </Screen.Content>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </Screen>
  );
};
