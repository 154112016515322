import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import Cookies from 'js-cookie';

import { useUser, useTrack } from '@moved/services';
import { LoaderOverlay, Transitions, Greeting } from '@moved/ui';
import { Footer } from '@moved/product';

import { SplashHeader } from '../../common';
import { useBuilding, useBuildingPending } from '../../common/actions/selectors';
import { useMove } from '../../moves/actions/selectors';

import { useActiveMoveStep } from '../contexts';
import { MoveStepNavigation } from './MoveStepNavigation';
import { LeaseData } from './LeaseData';
import { TaskList } from './TaskList';
import { PersonalTasksLeader } from './PersonalTasksLeader';
import CSS from './styles/Dashboard.module.scss';

const Intro = ({ moveStep, user }) => {
  if(moveStep.is_for_transfer) return <Greeting user={user} className='displayXL displayM--mobile' />;
  return (
    <h1 className='displayXL displayM--mobile'>
      {`${moveStep.type === 'move-in' ? 'Welcome' : 'Goodbye for now'}${get(user,'firstname') ? ', '+user.firstname : ''}!`}
    </h1>
  );
};

export const Dashboard = () => {
  const { user, isAuthenticated } = useUser();
  const Track = useTrack();
  const { moveId, stepId } = useParams();

  const move = useMove(moveId);
  const activeMoveStep = useActiveMoveStep();
  const building = useBuilding(get(activeMoveStep,'building.slug'));
  const buildingPending = useBuildingPending();

  // event tracking
  useEffect(() => {
    if(isAuthenticated) {
      if(!Cookies.get('lastViewedDashboard')) Track.event('Dashboard');
      Cookies.set('lastViewedDashboard', true, {expires: 2/24}); // the expiration of the cookie is set to 2 hours
    }
  },[isAuthenticated]); // eslint-disable-line

  // Map tasks to required and recommended sets
  const tasks = useMemo(
    () => (activeMoveStep?.move_tasks ?? []).reduce((taskList, task) => ({
      required: [
        ...(taskList?.required ?? []),
        task.requirement === 'required' && task,
      ].filter(Boolean),
      recommended: [
        ...(taskList?.recommended ?? []),
        task.requirement !== 'required' && task,
      ].filter(Boolean),
    }), {}),
    [activeMoveStep]
  );

  if(!building) return <LoaderOverlay/>;

  return (
    <div className={CSS.parallaxScroller}>
      <Helmet>
        <title>{`Welcome to ${building ? get(building, 'settings.display_name', '') : 'loading'} : Moved`}</title>
      </Helmet>

      { buildingPending && (<LoaderOverlay/>)}

      <SplashHeader background={building.dashboard_image_url}>
        <div className='stackVertical gap-10'>
          <div className={CSS.buildingLogo}>
            <img src={building.logo_url} alt={building.name} />
          </div>
          <Intro moveStep={activeMoveStep} user={user}/>
        </div>
      </SplashHeader>

      <section className={CSS.dashboard}>

        <MoveStepNavigation />

        <LeaseData activeMoveStep={activeMoveStep} />

        <Transitions pageKey={stepId} transition={activeMoveStep.type === 'move-out' ? 'slideRight' : 'slideLeft'}>
          <div className="container">

            { tasks && tasks.required && (
              <TaskList
                title={(
                  get(activeMoveStep,'building_task_list_content.required_tasks_title') ||
                  `Required ${ get(activeMoveStep,'display_name').toLowerCase() } tasks`
                )}
                subtitle={(
                  get(activeMoveStep,'building_task_list_content.required_tasks_subtitle') ||
                  `Your building requires you to complete all of these tasks before moving ${ activeMoveStep.type === 'move-out' ? 'out' : 'in' }.`
                )}
                message={get(activeMoveStep,'progress_message')}
                tasks={tasks.required}
              />
            )}
            { tasks && tasks.recommended && (
              <TaskList
                title={(
                  get(activeMoveStep,'building_task_list_content.recommended_tasks_title') ||
                  `Recommended ${ get(activeMoveStep,'display_name').toLowerCase() } tasks`
                )}
                subtitle={(
                  get(activeMoveStep,'building_task_list_content.recommended_tasks_subtitle') ||
                  `Optional tasks to help you have a stress free move.`
                )}
                tasks={tasks.recommended}
                progress={false}
              />
            )}

            <PersonalTasksLeader move={move} />

          </div>
        </Transitions>
      </section>

      <Footer />
    </div>
  );
}
