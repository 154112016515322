import classNames from 'classnames';

import { format } from '@moved/services';
import { Icon } from '@moved/ui';

import CSS from './DayTooltips.module.scss';

export const DayTooltips = ({
  date,
  calendar,
  earliestDate,
  latestDate,
  leaseStartDate,
  leaseEndDate,
  currentAppointmentDate,
}) => {
  const isEarliestDate = format.date(earliestDate,'YYYY-MM-DD') === date;
  const isLatestDate = format.date(latestDate,'YYYY-MM-DD') === date;
  const isLeaseStartDate = format.date(leaseStartDate,'YYYY-MM-DD') === date;
  const isLeaseEndDate = format.date(leaseEndDate,'YYYY-MM-DD') === date;
  const isCurrentAppointmentDate = format.date(currentAppointmentDate,'YYYY-MM-DD') === date;

  return (
    <div className={CSS.tooltips_wrapper}>
      { isEarliestDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{calendar?.content?.earliest_date_tooltip ?? 'Your unit is available!'}</span>
        </div>
      )}
      { isLatestDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{calendar?.content?.latest_date_tooltip ?? 'Last day in unit.'}</span>
        </div>
      )}
      { !earliestDate && isLeaseStartDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{calendar?.content?.lease_start_tooltip ?? 'Your lease start date!'}</span>
        </div>
      )}
      { !latestDate && isLeaseEndDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{calendar?.content?.lease_end_tooltip ?? 'Your lease end date.'}</span>
        </div>
      )}
      { isCurrentAppointmentDate && (
        <div className={CSS.tooltip_green}>
          <span className={CSS.tooltip_green_text}>{calendar?.content?.current_reservation_tooltip ?? 'Current reservation'}</span>
        </div>
      )}
    </div>
  );
};
